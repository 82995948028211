import { Box, Button, Flex } from '@chakra-ui/react';
import { useState } from 'react';
import { RiAddLine, RiArrowLeftLine, RiArrowRightLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';

import { useProfileWizard } from '@context/ProfileWizardContext';
import { RelatedProfileManager } from '@features/profile-wizard/';
import useQueryParams from '@hooks/useQueryParams';
import { type IProfile } from '@models/profileTypes';
interface ProfileFormProps {
  orgId: string;
  profile: IProfile;
}

const AddRelatedParty: React.FC<ProfileFormProps> = ({ orgId, profile }) => {
  const navigate = useNavigate();
  const { currentStep, maxSteps } = useProfileWizard();
  const { queryParams, setQueryParams } = useQueryParams();

  const [showForm, setShowForm] = useState<boolean>(false);

  const AddRelatedPartyButton: React.FC = () => (
    <Box display="flex" justifyContent="center" mt={10}>
      <Button
        bg="wizard.addRelatedParty.button.bg"
        color="wizard.addRelatedParty.button.color"
        onClick={() => {
          setShowForm(true);
        }}
        leftIcon={<RiAddLine />}
      >
        Add related party
      </Button>
    </Box>
  );

  return (
    <Box w="100%" p={4}>
      <RelatedProfileManager orgId={orgId} profile={profile} showForm={showForm} setShowForm={setShowForm}>
        <Box width="fit-content" marginX="auto" minH="36" mt="24">
          <Box textAlign="center" mb={4}>
            <Box as="h2" fontSize="xl" fontWeight="bold" mb={2} color="title">
              Add Related Party
            </Box>
            <Box color="description" mb={4}>
              Add related organisations, controlling owners and ultimate beneficials (UBOs). You can skip this step by
              clicking &apos;Next&apos; if there are no related parties to add.
            </Box>
          </Box>
          <AddRelatedPartyButton />
        </Box>
      </RelatedProfileManager>
      {!showForm && (
        <Flex justifyContent="flex-end" gap={4} mt={4}>
          <Button
            bg="button.secondary"
            border="1px solid"
            borderColor="button.secondaryBorder"
            onClick={() => {
              setQueryParams({ ...queryParams, step: currentStep - 1 });
            }}
            px={16}
            leftIcon={<RiArrowLeftLine />}
          >
            Back
          </Button>
          <Button
            bg="button.primary"
            onClick={() => {
              if (currentStep === maxSteps) {
                navigate(`/dashboard/profiles/${profile.id}`);
                return;
              }
              setQueryParams({ ...queryParams, step: currentStep + 1 });
            }}
            px={16}
            rightIcon={<RiArrowRightLine />}
          >
            {currentStep === maxSteps ? 'Finish' : 'Next'}
          </Button>
        </Flex>
      )}
    </Box>
  );
};

export default AddRelatedParty;
