import { Box, Text } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';

const DisplayField: React.FC<{ label: string; value: string | string[] }> = ({ label, value }) => {
  const formattedValue = Array.isArray(value) ? value.join('; ') : value;

  return (
    <Box display="flex" alignItems="center" my="0">
      <Text flexShrink="0" width="210px" fontSize="md">
        {label}:
      </Text>
      <Text flex="1" fontWeight="medium">
        {!isEmpty(formattedValue) ? formattedValue : '\u00A0'}
      </Text>
    </Box>
  );
};

export default DisplayField;
