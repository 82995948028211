import { Box, Heading, Text, useToast, Divider } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { BiRepost } from 'react-icons/bi';

import CadenceDisplay from '@features/wallets/CadenceDisplay.component';

const ScreeningInfoDisplay: React.FC<{
  createdAt: string;
  lastInquiryCreatedAt: string;
  frequency: string;
  walletId: string;
  orgId: string;
  walletName: string;
  walletAddress: string;
  updateWallet?: any;
  updateWalletIsLoading?: boolean;
  newWalletInquiry?: any;
  newWalletInquiryLoading?: boolean;
}> = ({
  createdAt,
  lastInquiryCreatedAt,
  frequency,
  walletId,
  orgId,
  walletName,
  walletAddress,
  updateWallet,
  updateWalletIsLoading,
  newWalletInquiry,
  newWalletInquiryLoading
}) => {
  const toast = useToast();

  return (
    <Box px={5} pt={4} pb={4} bg="white" mt={4} layerStyle="profileOverview.container" border="2px solid #BED903">
      <Heading size="sm" mb={3}>
        Screening Information
      </Heading>

      <Box display="flex" alignItems="center" py={3} justifyContent="space-between">
        <Text fontSize="md" width="200px">
          Date of Initial Screening
        </Text>
        <Text fontSize="md">{dayjs(createdAt).format('MMMM D, YYYY')}</Text>
      </Box>

      <Divider mx={-5} borderColor="#E0E0E0" />

      <Box display="flex" alignItems="center" py={3} justifyContent="space-between">
        <Text fontSize="md" width="200px">
          Date of Most Recent Screening
        </Text>
        <Box display="flex" alignItems="center">
          <Text fontSize="md">{dayjs(lastInquiryCreatedAt).format('MMMM D, YYYY')}</Text>
          {newWalletInquiry !== undefined && (
            <BiRepost
              size={20}
              cursor="pointer"
              style={{ marginLeft: '12px' }}
              onClick={async () => {
                try {
                  await newWalletInquiry({
                    orgId,
                    walletId,
                    walletName,
                    walletAddress
                  }).unwrap();
                } catch (err) {
                  toast({
                    status: 'error',
                    title: 'Error',
                    description: 'Oh no, there was an error!',
                    isClosable: true
                  });
                }
              }}
            />
          )}
        </Box>
      </Box>

      <Divider mx={-5} borderColor="#E0E0E0" />

      <Box display="flex" alignItems="center" py={3} justifyContent="space-between">
        <Text fontSize="md" width="200px">
          Automatic Screening Cadence
        </Text>
        <CadenceDisplay
          frequency={frequency}
          walletId={walletId}
          orgId={orgId}
          updateWallet={updateWallet}
          updateWalletIsLoading={updateWalletIsLoading}
        />
      </Box>
    </Box>
  );
};

export default ScreeningInfoDisplay;
