import { CloseIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Heading,
  Flex,
  Button,
  Tooltip,
  IconButton,
  Text,
  Select,
  useDisclosure,
  useToast,
  Textarea,
  HStack,
  VStack,
  Box
} from '@chakra-ui/react';
import { useState } from 'react';

import AttachmentModal from '@features/profile-wizard/components/common/AddAttachmentModal';
import {
  useDeleteDocumentMutation,
  useUpdateDocumentMutation,
  useGetProfileAttachmentsQuery,
  useGetProfileAttachmentTypesQuery
} from '@services/canaria.services';

import ConfirmationModal from './ConfirmationModal';

interface IAttachment {
  id: string;
  file: string;
  name: string;
  notes: string;
  status: string;
  attachmentType: string;
}

const AttachmentRow: React.FC<{
  attachment: IAttachment;
  attachmentTypes: Array<{ value: string; name: string }>;
  setSelectedAttachment: (attachment: { id: string; name: string }) => void;
  onDeleteOpen: () => void;
  updateDocument: any;
}> = ({ attachment, attachmentTypes, setSelectedAttachment, onDeleteOpen, updateDocument }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(attachment.name);

  const handleNameSubmit = async (): Promise<void> => {
    try {
      await updateDocument({
        name: newName
      });
      setIsEditing(false);
    } catch (error) {
      setNewName(attachment.name);
      setIsEditing(false);
    }
  };

  return (
    <Box px={3} py={2} shadow="md" borderWidth="1px" w="100%" position="relative" mb={2} borderRadius="md">
      <Flex direction="column" gap={2}>
        {/* First row - filename and delete */}
        <Flex justifyContent="space-between" alignItems="center">
          <HStack spacing={2}>
            {isEditing ? (
              <Textarea
                size="xs"
                value={newName}
                ref={(textareaRef) => {
                  if (textareaRef != null) {
                    setTimeout(() => {
                      textareaRef.style.height = `${textareaRef.scrollHeight}px`;
                    }, 0);
                  }
                }}
                onChange={(e) => {
                  setNewName(e.target.value);
                  e.target.style.height = 'auto';
                  e.target.style.height = `${e.target.scrollHeight}px`;
                }}
                onBlur={handleNameSubmit}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    void handleNameSubmit();
                  }
                }}
                whiteSpace="pre-wrap"
                wordBreak="break-word"
                minH="20px"
                resize="none"
                overflow="hidden"
              />
            ) : (
              <Text
                fontSize="sm"
                fontWeight="600"
                cursor="pointer"
                onClick={() => {
                  setIsEditing(true);
                }}
                _hover={{ textDecoration: 'underline' }}
                whiteSpace="pre-wrap"
                wordBreak="break-word"
              >
                {attachment.name}
              </Text>
            )}
            <a href={attachment.file} target="_blank" rel="noopener noreferrer">
              <ExternalLinkIcon color="blue.500" boxSize={3} />
            </a>
          </HStack>
          <Tooltip label="Delete attachment" aria-label="Delete attachment tooltip">
            <IconButton
              aria-label="Delete attachment"
              icon={<CloseIcon />}
              size="xs"
              colorScheme="gray"
              onClick={() => {
                setSelectedAttachment({ id: attachment.id, name: attachment.name });
                onDeleteOpen();
              }}
            />
          </Tooltip>
        </Flex>

        {/* Second row - type and status */}
        <Flex gap={4}>
          <Select
            size="xs"
            borderRadius="lg"
            value={attachment.attachmentType}
            onChange={async (e) => {
              await updateDocument({
                attachmentType: e.target.value
              });
            }}
            iconSize="15px"
            sx={{
              paddingRight: '24px'
            }}
          >
            {attachmentTypes?.map((type) => (
              <option key={type.value} value={type.value}>
                {type.name}
              </option>
            ))}
          </Select>
          <Select
            size="xs"
            borderRadius="lg"
            value={attachment.status}
            onChange={async (e) => {
              await updateDocument({
                status: e.target.value
              });
            }}
            iconSize="15px"
            sx={{
              paddingRight: '24px'
            }}
          >
            <option value="PENDING">Pending</option>
            <option value="APPROVED">Approved</option>
            <option value="REJECTED">Rejected</option>
          </Select>
        </Flex>

        {/* Notes row if exists */}
        {attachment.notes != null && (
          <Text fontSize="xs" color="gray.600">
            {attachment.notes}
          </Text>
        )}
      </Flex>
    </Box>
  );
};

const Attachments: React.FC<{
  orgId: string;
  profileId: string;
}> = ({ orgId, profileId }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { data: attachments = [], refetch } = useGetProfileAttachmentsQuery({ orgId, profileId });
  const { data: attachmentTypes } = useGetProfileAttachmentTypesQuery(null);
  const [deleteDocument] = useDeleteDocumentMutation();
  const [updateDocument] = useUpdateDocumentMutation();

  const [selectedAttachment, setSelectedAttachment] = useState<{ id: string; name: string } | null>(null);

  const handleDelete = async (): Promise<void> => {
    if (selectedAttachment != null) {
      await deleteDocument({
        orgId,
        profileId,
        documentId: selectedAttachment.id
      });
      onDeleteClose();
    }
  };

  return (
    <VStack spacing={4} align="stretch" w="100%" layerStyle="profileOverview.subContainer">
      <Heading as="h1" size="md" textAlign="left" mb={1}>
        Attachments
      </Heading>
      <Flex direction="column">
        <Box
          maxH="300px"
          overflowY="auto"
          sx={{
            '&::-webkit-scrollbar': {
              width: '8px',
              borderRadius: '8px',
              backgroundColor: `rgba(0, 0, 0, 0.05)`
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: `rgba(0, 0, 0, 0.15)`,
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: `rgba(0, 0, 0, 0.25)`
              }
            }
          }}
        >
          {attachments?.map((attachment, index) => (
            <AttachmentRow
              key={index}
              attachment={attachment}
              attachmentTypes={attachmentTypes}
              setSelectedAttachment={setSelectedAttachment}
              onDeleteOpen={onDeleteOpen}
              updateDocument={async ({ attachmentType, status, name }) => {
                try {
                  await updateDocument({
                    orgId,
                    profileId,
                    documentId: attachment.id,
                    attachmentType,
                    status,
                    name
                  }).unwrap();
                } catch (error: any) {
                  const errorMessage = error?.data?.status?.[0] ?? 'An error occurred';
                  await refetch();
                  toast({
                    title: 'Error',
                    description: errorMessage,
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                  });
                }
              }}
            />
          ))}
          {attachments?.length === 0 && <Text fontSize="sm">No attachments found</Text>}
        </Box>
        <Button size="sm" mt="4" maxW="60" color="white" background="gray.800" onClick={onOpen} alignSelf="flex-end">
          Add attachment
        </Button>
      </Flex>
      <AttachmentModal isOpen={isOpen} onClose={onClose} orgId={orgId} profileId={profileId} />
      {isDeleteOpen && (
        <ConfirmationModal
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
          onConfirm={handleDelete}
          title="Delete Attachment"
          body={`Are you sure you want to delete the attachment "${selectedAttachment?.name}"?`}
        />
      )}
    </VStack>
  );
};

export default Attachments;
