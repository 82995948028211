import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { Divider, type UseToastOptions } from '@chakra-ui/react';
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Input,
  FormControl,
  Link,
  Stack,
  Text,
  useToast,
  VStack,
  InputGroup,
  InputRightElement,
  IconButton
} from '@chakra-ui/react';
import { GoogleLogin } from '@react-oauth/google';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AuthAPI from '@features/auth/api';
import { Footer, Loading } from '@features/shared/components';
import { useLoginMutation, useLoginWithGoogleMutation } from '@services/canaria.services';

import { MODE } from '../../env';

const toastInvalidCredentials: UseToastOptions = {
  status: 'error',
  title: 'Invalid Credentials',
  description: 'The account specified does not exist or the password is incorrect.',
  isClosable: true
};

const toastInvalidGoogleCredentials: UseToastOptions = {
  status: 'error',
  title: 'Invalid Credentials',
  description:
    'The Google account specified is not authorized for our system. Please contact info@canariaconsulting.com for assistance.',
  isClosable: true
};

const toastDefaultError: UseToastOptions = {
  status: 'error',
  title: 'Error',
  description: 'Oh no, there was an error!',
  isClosable: true
};

const toastDefaultGoogleError: UseToastOptions = {
  status: 'error',
  title: 'Error',
  description: 'Oh no, there was an error logging in with Google. Please try again.',
  isClosable: true
};

const RightPanel: React.FC = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();
  const [loginWithGoogle, { isLoading: isLoadingGoogle }] = useLoginWithGoogleMutation();

  const [formState, setFormState] = useState({
    username: '',
    password: ''
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleInputChange = ({ target: { name, value } }): void => {
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogin = async (): Promise<void> => {
    try {
      const authApi = new AuthAPI(dispatch);
      authApi.logout();
      const tokens = await login(formState).unwrap();
      authApi.login(tokens.refresh, tokens.access);
      navigate('/dashboard');
    } catch (err: any) {
      if (err.status === 401) {
        toast(toastInvalidCredentials);
      } else {
        toast(toastDefaultError);
      }
    }
  };

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
    if (event.key === 'Enter') {
      await handleLogin();
    }
  };

  const baseUrl =
    MODE === 'development'
      ? 'http://127.0.0.1:8000'
      : MODE === 'staging'
        ? 'https://backend-dev.canariaconsulting.com'
        : 'https://backend.canariaconsulting.com';

  return (
    <Box bg="background" w="100%" px={52} py={10} display="flex" alignItems="center" justifyContent="center">
      <VStack
        spacing={1}
        align="flex-start"
        w="100%"
        bg="container"
        borderRadius="xl"
        padding="24px 40px 24px 40px"
        border="1px solid"
        borderColor="border"
        maxWidth="400px"
        boxShadow="0px 1px 2px 0px #1018280D"
      >
        <Center w="100%" mb={10}>
          <VStack spacing={1} gap={3}>
            <img src="/resources/logo.webp" alt="logo"></img>
            <Text fontSize="2xl">Log In</Text>
            <Text fontSize="sm" color="gray.500">
              Please enter your details.
            </Text>
          </VStack>
        </Center>
        <FormControl>
          <Stack spacing={4}>
            <Input type="email" name="username" onChange={handleInputChange} bg="#F7F7F7" placeholder="Email Address" />
            <InputGroup>
              <Input
                type={showPassword ? 'text' : 'password'}
                name="password"
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                bg="#F7F7F7"
                placeholder="Password"
              />
              <InputRightElement>
                <IconButton
                  aria-label={showPassword ? 'Hide password' : 'Show password'}
                  icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                  variant="ghost"
                  size="sm"
                />
              </InputRightElement>
            </InputGroup>
            <Flex justifyContent="space-between" alignItems="center">
              <Checkbox fontSize="sm">
                <Text fontSize="sm">Remember me</Text>
              </Checkbox>
              <Link
                // href="https://backend.canariaconsulting.com/acct/password-reset/"
                href={`${baseUrl}/acct/password-reset/`}
                fontSize="sm"
                color="blue.500"
                isExternal
                fontWeight="bold"
              >
                Forgot your password?
              </Link>
            </Flex>
            <Button fontSize="sm" bg="button.primary" onClick={handleLogin} isLoading={isLoading}>
              Sign in
            </Button>
            <Center>
              <Link fontSize="sm" color="blue.500" href="mailto:info@canariaconsulting.com" fontWeight="bold">
                Request an Account
              </Link>
            </Center>
            {/* <Divider>
              <Text fontSize="sm" color="gray.500" px={2}>
                or
              </Text>
            </Divider> */}
            <Flex align="center">
              <Divider />
              <Text padding="2" fontSize="sm" color="gray.500">
                or
              </Text>
              <Divider />
            </Flex>
            {isLoadingGoogle ? (
              <Loading message="Signing you in... Please wait." />
            ) : (
              <GoogleLogin
                onSuccess={async (credentialResponse) => {
                  const authApi = new AuthAPI(dispatch);
                  authApi.logout();
                  try {
                    const tokens = await loginWithGoogle(credentialResponse).unwrap();
                    authApi.login(tokens.refresh, tokens.access);

                    navigate('/dashboard/profiles');
                  } catch (err: any) {
                    if (err.status === 401) {
                      toast(toastInvalidGoogleCredentials);
                    } else {
                      toast(toastDefaultError);
                    }
                  }
                }}
                onError={() => {
                  toast(toastDefaultGoogleError);
                }}
                useOneTap
                containerProps={{
                  style: {
                    boxShadow: '0px 1px 2px 0px #1018280D'
                  }
                }}
              />
            )}
          </Stack>
        </FormControl>
      </VStack>
    </Box>
  );
};

const SignInForm: React.FC = () => {
  return (
    <Flex flexDirection="column" minH="100vh">
      <Flex flex="1">
        <RightPanel />
      </Flex>
      <Footer />
    </Flex>
  );
};

export default SignInForm;
