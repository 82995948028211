import { Button, Flex, useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';

import CookiePreferences from './CookiePreferences';
import { COOKIE_NAME, handleAccept, handleReject } from '../utils/cookieConsent';

const CookieConsentBanner: React.FC = () => {
  const [bannerVisible, setBannerVisible] = useState(Cookies.get(COOKIE_NAME) == null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleShowPreferences = (): void => {
    onOpen();
  };

  const onConfirmChoices = (): void => {
    setBannerVisible(false);
  };

  return (
    <>
      <CookiePreferences isOpen={isOpen} onClose={onClose} onConfirmChoices={onConfirmChoices} />
      <CookieConsent
        visible={bannerVisible ? 'show' : 'hidden'}
        location="bottom"
        buttonText="Accept all cookies"
        cookieName={COOKIE_NAME}
        style={{
          background: '#2B373B',
          padding: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        ButtonComponent={Button}
        customButtonProps={{
          colorScheme: 'green'
        }}
        onAccept={() => {
          handleAccept('full');
          setBannerVisible(false);
        }}
        onDecline={() => {
          handleReject();
          setBannerVisible(false);
        }}
        disableButtonStyles
      >
        <Flex flex="1" justifyContent="space-between" alignItems="center" flexWrap="wrap">
          <div style={{ flex: 1, marginRight: '20px', color: '#fff' }}>
            By clicking &quot;Accept all cookies,&quot;, you agree to the storing of cookies on your device to enhance
            site navigation, analyze site usage, and assist in our marketing efforts.
            <br />
            <a
              href="/privacy-policy"
              style={{
                color: '#f1d600',
                textDecoration: 'underline'
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy policy
            </a>
          </div>

          <Flex>
            <Button onClick={handleShowPreferences}>Cookie preferences</Button>
          </Flex>
        </Flex>
      </CookieConsent>
    </>
  );
};

export default CookieConsentBanner;
