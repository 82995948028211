import { IconButton, Flex, Heading, VStack, Button, useDisclosure } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { BiSitemap } from 'react-icons/bi';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { GoPlus } from 'react-icons/go';
import { Link } from 'react-router-dom';

import AddRelatedPartyModal from '@features/profile-wizard/components/common/AddRelatedPartyModal';

import RelatedPartiesSection from '../RelatedPartiesSection';

const MotionVStack = motion.create(VStack as any);

export interface RelatedPartiesRef {
  expand: () => void;
  scrollIntoView: (options?: ScrollIntoViewOptions) => void;
}

interface RelatedPartiesProps {
  profileId: string;
  orgId: string;
  handleSectionHover?: (section: string) => void;
  profileGroupId: string;
}

const RelatedParties = forwardRef<RelatedPartiesRef, RelatedPartiesProps>(
  ({ profileId, orgId, handleSectionHover, profileGroupId }, ref) => {
    const [isOpen, setIsOpen] = useState(true);
    const containerRef = useRef<HTMLDivElement>(null);
    const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();

    useImperativeHandle(ref, () => ({
      expand: () => {
        setIsOpen(true);
      },
      scrollIntoView: (options?: ScrollIntoViewOptions) => {
        containerRef.current?.scrollIntoView(options);
      }
    }));

    return (
      <VStack
        ref={containerRef}
        spacing={4}
        align="stretch"
        m={3}
        p={3}
        boxShadow="md"
        bg="white"
        w="100%"
        layerStyle="profileOverview.container"
        onMouseEnter={() => {
          handleSectionHover?.('Related Parties');
        }}
      >
        <Flex justifyContent="space-between" width="full" alignItems="center">
          <Heading as="h1" size="md" textAlign="left">
            Related Parties
          </Heading>
          <IconButton
            aria-label="Toggle Related Parties"
            icon={isOpen ? <FaChevronUp /> : <FaChevronDown />}
            size="sm"
            variant="ghost"
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          />
        </Flex>

        <AnimatePresence>
          {isOpen && (
            <MotionVStack
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              overflow="hidden"
              spacing={4}
              display="flex"
              flexDirection="column"
              alignItems="stretch"
            >
              <Flex px={0} gap={4} alignItems="center" mb={4}>
                <Button
                  as={Link}
                  to={`/dashboard/relationship-chart/${profileId}`}
                  leftIcon={<BiSitemap />}
                  aria-label="View Relationship Chart"
                  bg="button.secondary"
                  border="1px solid"
                  borderColor="button.secondaryBorder"
                >
                  Go to Relationship Chart
                </Button>
                <Button leftIcon={<GoPlus />} aria-label="Add Related Party" bg="transparent" onClick={onModalOpen}>
                  Add Related Party
                </Button>
              </Flex>
              <RelatedPartiesSection orgId={orgId} profileId={profileId} />
            </MotionVStack>
          )}
        </AnimatePresence>

        <AddRelatedPartyModal
          isOpen={isModalOpen}
          onClose={onModalClose}
          orgId={orgId}
          profileId={profileId}
          profileGroupId={profileGroupId}
        />
      </VStack>
    );
  }
);

RelatedParties.displayName = 'RelatedParties';

export default RelatedParties;
