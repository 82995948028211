import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Skeleton,
  Spacer,
  Text,
  VStack,
  // useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import md5 from 'md5';
import { useState } from 'react';
import { BiKey } from 'react-icons/bi';
import { FaSyncAlt } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import { GoSidebarExpand, GoSidebarCollapse } from 'react-icons/go';
import { MdGroup, MdLogout, MdNotificationsNone } from 'react-icons/md';
import { SlWallet } from 'react-icons/sl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { Footer } from '@features/shared/components';
import { selectActiveOrgID, selectUser, userClearOrgStateAction } from '@features/user-settings/userSlice';
import { useGetMeQuery, useGetOrganizationQuery } from '@services/canaria.services';

import { MODE } from '../env';

interface SidePanelProps {
  children?: React.ReactNode;
}

const UserDisplay: React.FC<{ userName: string; email: string; collapsed: boolean }> = ({
  userName,
  email,
  collapsed
}) => {
  const gravatarUrl = 'https://www.gravatar.com/avatar/' + md5(email);
  const dispatch = useDispatch();

  return (
    <Menu>
      <MenuButton as={Button} variant="unstyled" _focus={{ boxShadow: 'none' }} _hover={{ bg: 'transparent' }}>
        <Flex align="center" justify="center">
          <Avatar size="md" name={userName} src={gravatarUrl} mr={2} />
          {!collapsed && (
            <Flex direction="column" align="flex-start">
              <Text fontWeight="normal" color="sidebar.secondaryText" fontSize="sm">
                Welcome back
              </Text>
              <Text fontWeight="bold">{userName}</Text>
            </Flex>
          )}
        </Flex>
      </MenuButton>
      <MenuList zIndex={2} color="sidebar.userMenu.text" borderRadius="8px">
        <MenuItem _hover={{ background: 'none' }} cursor="default">
          <Avatar size="md" name={userName} src={gravatarUrl} mr={2} />
          <Flex direction="column" align="flex-start">
            <Text fontWeight="bold">{userName}</Text>
            <Text fontWeight="normal" color="link" fontSize="sm">
              {email}
            </Text>
          </Flex>
        </MenuItem>
        <MenuDivider />
        <MenuItem
          onClick={() => {
            dispatch(userClearOrgStateAction());
          }}
        >
          <Icon as={FaSyncAlt} color="sidebar.icon" mr={2} />
          <Text fontWeight="normal">Switch Organization</Text>
        </MenuItem>
        <MenuItem
          onClick={() => {
            const baseUrl =
              MODE === 'development'
                ? 'http://127.0.0.1:8000'
                : MODE === 'staging'
                  ? 'https://backend-dev.canariaconsulting.com'
                  : 'https://backend.canariaconsulting.com';
            window.location.replace(`${baseUrl}/acct/change-password/`);
          }}
        >
          <Icon as={BiKey} mr={2} />
          <Text fontWeight="normal">Change Password</Text>
        </MenuItem>
        <MenuDivider />
        <Link to="/logout">
          <MenuItem>
            <Flex align="center">
              <Icon as={MdLogout} mr={2} />
              <Text>Logout</Text>
            </Flex>
          </MenuItem>
        </Link>
      </MenuList>
    </Menu>
  );
};

const AnimatedMenuItem: React.FC<{
  icon: React.ElementType;
  label: string;
  route: string;
  collapsed: boolean;
}> = ({ icon, label, route, collapsed }) => {
  const location = useLocation();
  const isActive = location.pathname.startsWith(route);

  return (
    <Link to={route} style={{ textDecoration: 'none', width: '100%' }}>
      <Flex
        minH="40px"
        bg={isActive ? 'button.sidebarHover' : 'transparent'}
        _hover={{ bg: 'button.sidebarHover' }}
        borderRadius="md"
        p={2}
        transition="all 0.2s ease-in-out"
        justify={collapsed ? 'center' : 'flex-start'}
        align="center"
        width="100%"
      >
        <Icon as={icon} fontSize="20px" />
        <Text
          ml={2}
          fontSize="md"
          opacity={collapsed ? 0 : 1}
          transition={collapsed ? 'none' : 'opacity 0.2s ease-in-out'}
          position={collapsed ? 'absolute' : 'relative'}
          pointerEvents={collapsed ? 'none' : 'auto'}
        >
          {label}
        </Text>
      </Flex>
    </Link>
  );
};

const SidePanel: React.FC<SidePanelProps> = ({ children }) => {
  const activeOrgID = useSelector(selectActiveOrgID);
  const { isLoading: meIsLoading } = useGetMeQuery(null);
  const { isLoading: orgIsLoading } = useGetOrganizationQuery(activeOrgID as string);
  if (!meIsLoading && !orgIsLoading) {
    // eslint-disable-next-line no-console
    console.log('Loaded Me and Org');
  }
  const [collapsed, setCollapsed] = useState(false);
  const userState = useSelector(selectUser);
  const { onToggle } = useDisclosure();

  const handleToggle = (): void => {
    setCollapsed((prev) => !prev);
    onToggle();
  };

  return (
    <Flex h="100vh" overflow="hidden">
      <Box
        width={collapsed ? '80px' : '220px'}
        minWidth={collapsed ? '80px' : '220px'}
        bg="sidebar.background"
        color="sidebar.text"
        p={4}
        transition="width 0.2s ease-in-out, min-width 0.2s ease-in-out"
        position="relative"
        display="flex"
        flexDirection="column"
      >
        <Box height="100px">
          <Box
            position="absolute"
            transition="left 0.2s ease-in-out"
            left={collapsed ? '60px' : '205px'}
            top="20px"
            zIndex={2}
          >
            <IconButton
              aria-label="Toggle Menu"
              icon={
                collapsed ? (
                  <GoSidebarExpand style={{ color: 'sidebar.icon' }} />
                ) : (
                  <GoSidebarCollapse style={{ color: 'sidebar.icon' }} />
                )
              }
              fontSize="20px"
              onClick={handleToggle}
              size="md"
              bg="button.secondary"
            />
          </Box>
          <Flex direction="row" alignItems="center">
            {isEmpty(userState.orgImageUrl) ? (
              <Skeleton height="50px" width="50px" mr={2} />
            ) : (
              <Image src={userState.orgImageUrl} alt={userState.orgName} boxSize="50px" mr={2} />
            )}
            {!collapsed && (
              <>
                <Text>{userState.orgName}</Text>
                <Spacer />
              </>
            )}
          </Flex>
        </Box>

        <VStack align="stretch" spacing={4}>
          <VStack spacing={2} align="flex-start">
            <AnimatedMenuItem icon={MdGroup} label="Profiles" route="/dashboard/profiles" collapsed={collapsed} />
            <AnimatedMenuItem icon={SlWallet} label="Wallets" route="/dashboard/wallets" collapsed={collapsed} />
            <AnimatedMenuItem icon={FiSearch} label="Screening" route="/dashboard/screening" collapsed={collapsed} />
            <AnimatedMenuItem
              icon={MdNotificationsNone}
              label="Alerts"
              route="/dashboard/alerts"
              collapsed={collapsed}
            />
          </VStack>
        </VStack>
        <Box marginTop="auto">
          <Divider marginY={2} />
          <UserDisplay userName={userState.firstName} email={userState.email} collapsed={collapsed} />
        </Box>
      </Box>

      <Divider orientation="vertical" />

      {/* <Box flex="1" bg={bgValue} display="flex" flexDirection="column" overflowY="auto"> */}
      <Box flex="1" bg="background" display="flex" flexDirection="column" overflowY="auto">
        <Box>
          <Box mt={5}>{children}</Box>
        </Box>
        <Footer />
      </Box>
    </Flex>
  );
};

export default SidePanel;
