import {
  Box,
  Button,
  Flex,
  Switch,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';

import { handleAccept, handleReject, loadCookiePreferences } from '../utils/cookieConsent';

interface CookiePreferencesProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirmChoices?: () => void;
}

const CookiePreferences: React.FC<CookiePreferencesProps> = ({ isOpen, onClose, onConfirmChoices }) => {
  const [cookiePreferences, setCookiePreferences] = useState({
    functionalCookies: false,
    performanceCookies: false,
    targetingCookies: false
  });

  const setPreference = (key: keyof typeof cookiePreferences, value: boolean): void => {
    setCookiePreferences((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  useEffect(() => {
    const cookiePreferences = loadCookiePreferences();
    setCookiePreferences({
      functionalCookies: cookiePreferences?.consentProps?.functionalCookies ?? false,
      performanceCookies: cookiePreferences?.consentProps?.performanceCookies ?? false,
      targetingCookies: cookiePreferences?.consentProps?.targetingCookies ?? false
    });
  }, [isOpen]);

  const handleConfirmChoices = (preferences: any): void => {
    handleAccept('granular', preferences);
    onConfirmChoices?.();
    onClose();
  };

  const handleRejectAll = (): void => {
    setCookiePreferences({
      functionalCookies: false,
      performanceCookies: false,
      targetingCookies: false
    });
    handleReject();
    onConfirmChoices?.();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="800px">
        <ModalHeader>Cookie Preferences</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box bg="white" p="20px" borderRadius="md" boxShadow="lg">
            <Text fontSize="lg" mb="20px">
              When you visit any website, it may store or retrieve information on your browser, mostly in the form of
              cookies. This information might be about you, your preferences or your device and is mostly used to make
              the site work as you expect it to.
            </Text>
            <Text fontSize="md" mb="20px">
              Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the
              different category headings to find out more and change our default settings.
            </Text>
            <Button
              bg="green.500"
              color="white"
              mb="20px"
              onClick={() => {
                const newPreferences = {
                  functionalCookies: true,
                  performanceCookies: true,
                  targetingCookies: true
                };
                setCookiePreferences(newPreferences);
                handleConfirmChoices(newPreferences);
              }}
            >
              Allow all
            </Button>

            <Accordion allowToggle>
              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Strictly necessary cookies
                  </Box>
                  <Flex justify="space-between" align="center">
                    <Text fontSize="sm">Always active</Text>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Text mb="10px">
                    These cookies are crucial for enabling users to navigate our website and utilize its features, such
                    as accessing secure sections. They are essential for the proper functioning of the site and cannot
                    be disabled within our systems
                  </Text>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Functional cookies
                  </Box>
                  <Switch
                    colorScheme="green"
                    isChecked={cookiePreferences.functionalCookies}
                    onChange={(e) => {
                      setPreference('functionalCookies', e.target.checked);
                    }}
                    mr={2}
                  />
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Text mb="10px">
                    These cookies allow the website to offer improved functionality and personalized experiences. They
                    may be set by us or by third-party providers whose tools we’ve integrated into our site. Disabling
                    these cookies may cause some features or services to function improperly.
                  </Text>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Performance cookies
                  </Box>
                  <Switch
                    colorScheme="green"
                    isChecked={cookiePreferences.performanceCookies}
                    onChange={(e) => {
                      setPreference('performanceCookies', e.target.checked);
                    }}
                    mr={2}
                  />
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Text mb="10px">
                    These cookies help us track visits and traffic sources, enabling us to analyze and enhance the
                    performance of our website. They provide insights into which pages are most and least visited and
                    how users navigate through the site. The data collected by these cookies is entirely anonymous. If
                    you choose to disable these cookies, we will be unable to determine when you visited our site or
                    monitor its overall performance effectively.
                  </Text>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Targeting cookies
                  </Box>
                  <Switch
                    colorScheme="green"
                    isChecked={cookiePreferences.targetingCookies}
                    onChange={(e) => {
                      setPreference('targetingCookies', e.target.checked);
                    }}
                    mr={2}
                  />
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Text mb="10px">
                    These cookies monitor users’ online behavior to assist advertisers in delivering more relevant ads
                    or limiting the frequency of ad displays. They may be placed on our site by advertising partners and
                    used to present you with tailored advertisements on other websites. If you disable these cookies,
                    you may see less personalized advertising.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>

            <Flex justify="space-between" mt="30px">
              <Button variant="outline" onClick={handleRejectAll}>
                Reject all
              </Button>
              <Button
                bg="green.500"
                color="white"
                onClick={() => {
                  handleConfirmChoices(cookiePreferences);
                }}
              >
                Confirm my choices
              </Button>
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CookiePreferences;
