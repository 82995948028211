import {
  useDisclosure,
  Heading,
  Container,
  Center,
  Input,
  InputGroup,
  Box,
  Button,
  Spinner,
  Text,
  Link
} from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import { useState, useEffect } from 'react';
import { FiSearch, FiPlus } from 'react-icons/fi';
import { RiErrorWarningFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { selectActiveOrgID } from '@features/user-settings/userSlice';
import RegisterWalletModal from '@features/wallets/RegisterWalletModal.component';
import WalletCard from '@features/wallets/WalletCard.component';
import { useSearchWalletsQuery, useGetWalletByAddressQuery } from '@services/canaria.services';

const ScreeningPanel: React.FC = () => {
  const [formState, setFormState] = useState({ q: '' });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const activeOrgID = useSelector(selectActiveOrgID);
  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }
  const searchParameters = { orgId: activeOrgID, walletQuery: formState.q };
  const [skipWalletSearch, setSkipWalletSearch] = useState<boolean>(true);
  const {
    data: searchData,
    error: searchError,
    isLoading: searchIsLoading,
    isUninitialized: searchIsUninitialized
  } = useSearchWalletsQuery(searchParameters, { skip: skipWalletSearch });

  const {
    data: getByAddressData,
    error: getByAddressError,
    isLoading: getByAddressIsLoading,
    isUninitialized: getByAddressIsUninitialized
  } = useGetWalletByAddressQuery(searchParameters, { skip: skipWalletSearch });

  const debouncedSearch = debounce((query: string) => {
    if (query.length > 0) {
      setSkipWalletSearch(false);
    } else {
      setSkipWalletSearch(true);
    }
  }, 300);

  useEffect(() => {
    debouncedSearch(formState.q);
    return () => {
      debouncedSearch.cancel();
    };
  }, [formState.q]);

  const handleChange = ({ target: { name, value } }): void => {
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  const handleKeyPress = (e): void => {
    if (e.key === 'Enter') {
      const searchWallet = document.getElementsByClassName('searchWallet')?.[0];
      (searchWallet as HTMLElement)?.click();
    }
  };

  const triggerSearch = (): void => {
    if (formState.q.length > 0) {
      setSkipWalletSearch(false);
    }
  };

  let searchResult = <></>;

  if (!searchIsUninitialized) {
    if (searchIsLoading) {
      searchResult = <Spinner size="xl" />;
    } else if (searchData != null) {
      searchResult = searchData.results.map((wallet) => <WalletCard key={wallet.id} wallet={wallet} />);
    } else if (searchError != null) {
      searchResult = <h1>Got errors :-(</h1>;
    }
  }

  let exactWalletResult = <></>;

  if (!getByAddressIsUninitialized) {
    if (getByAddressIsLoading) {
      exactWalletResult = <Spinner size="xl" />;
    } else if (getByAddressData != null) {
      if (getByAddressData.count === 1) {
        exactWalletResult = (
          <Text pt={3}>
            Wallet address already registered in system{' '}
            <Link as={RouterLink} color="teal.500" to={`/dashboard/wallets/${getByAddressData.results[0].id}`}>
              (Open wallet)
            </Link>
          </Text>
        );
      } else {
        exactWalletResult = (
          <Center pt={3}>
            <RegisterWalletModal isOpen={isOpen} onClose={onClose} address={formState.q} orgId={activeOrgID} />
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              gap={4}
              p={4}
              borderLeftWidth="4px"
              borderColor="#F34213"
              bg="#FEEBCB"
            >
              <Box color="#F34213">
                <RiErrorWarningFill size="1.5em" />
              </Box>
              <Box flex={1}>
                <Text fontWeight="medium">Exact address for wallet not found.</Text>
                <Text>Register wallet address?</Text>
              </Box>
              <Button
                className="searchWallet"
                size="sm"
                onClick={onOpen}
                bg="transparent"
                border="1px solid #F34213"
                color="#F34213"
                leftIcon={<FiPlus />}
              >
                Register
              </Button>
            </Box>
          </Center>
        );
      }
    } else if (getByAddressError != null) {
      exactWalletResult = <h1>Got errors :-(</h1>;
    }
  }
  return (
    <Container maxW="8xl">
      <Box pb="20px">
        <Heading size="lg" ml={10} fontWeight="normal">
          Screening
        </Heading>
      </Box>
      <InputGroup bg="white" rounded="lg" layerStyle="profileOverview.container">
        <Input
          placeholder="Search blockchain address, internal notes or previous inquiry results"
          onChange={handleChange}
          name="q"
          onKeyUp={handleKeyPress}
          pr="4rem"
        />
        <Button
          position="absolute"
          right="0"
          height="60%"
          width="40px"
          bg="#BED903"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRightRadius="lg"
          marginRight="10px"
          onClick={triggerSearch}
          _hover={{ bg: '#a8c103' }}
        >
          <FiSearch size="1.5em" />
        </Button>
      </InputGroup>
      {searchData != null && (
        <Box textAlign="right" mr="10px" mt="2">
          <Text fontSize="sm" color="gray.600">
            Results {searchData.count}
          </Text>
        </Box>
      )}
      {exactWalletResult}
      {searchResult}
    </Container>
  );
};

export default ScreeningPanel;
