import { IconButton, Flex, Heading, VStack, Button, Box } from '@chakra-ui/react';
import { ThemeProvider } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { BiExport } from 'react-icons/bi';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { GoPlus } from 'react-icons/go';

import WalletsPanel, { type WalletsPanelRef } from '@features/panels/walletsPanel/WalletsPanel.component';
import { defaultMaterialTheme } from '@utils/consts';

const MotionVStack = motion.create(VStack as any);

export interface WalletsRef {
  expand: () => void;
  scrollIntoView: (options?: ScrollIntoViewOptions) => void;
}

interface WalletsProps {
  profileId: string;
  handleSectionHover?: (section: string) => void;
}

const Wallets = forwardRef<WalletsRef, WalletsProps>(({ profileId, handleSectionHover }, ref) => {
  const [isOpen, setIsOpen] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const walletsPanelRef = useRef<WalletsPanelRef>(null);

  useImperativeHandle(ref, () => ({
    expand: () => {
      setIsOpen(true);
    },
    scrollIntoView: (options?: ScrollIntoViewOptions) => {
      containerRef.current?.scrollIntoView(options);
    }
  }));

  return (
    <VStack
      ref={containerRef}
      spacing={4}
      align="stretch"
      m={3}
      p={3}
      boxShadow="md"
      bg="white"
      w="100%"
      layerStyle="profileOverview.container"
      onMouseEnter={() => {
        handleSectionHover?.('Wallets');
      }}
    >
      <Flex justifyContent="space-between" width="full" alignItems="center">
        <Heading as="h1" size="md" textAlign="left">
          Wallets
        </Heading>
        <IconButton
          aria-label="Toggle Wallets"
          icon={isOpen ? <FaChevronUp /> : <FaChevronDown />}
          size="sm"
          variant="ghost"
          onClick={() => {
            setIsOpen((prev) => !prev);
          }}
        />
      </Flex>

      <AnimatePresence>
        {isOpen && (
          <MotionVStack
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            overflow="hidden"
            spacing={4}
            display="flex"
            flexDirection="column"
            alignItems="stretch"
          >
            <Flex px={0} gap={4} alignItems="center" mb={4}>
              <Button
                leftIcon={<BiExport />}
                aria-label="Export Selected Rows"
                bg="button.secondary"
                border="1px solid"
                borderColor="button.secondaryBorder"
                onClick={() => walletsPanelRef.current?.exportSelectedRows()}
              >
                Export Selected Rows
              </Button>
              <Button
                leftIcon={<GoPlus />}
                aria-label="Register New Wallet"
                bg="transparent"
                onClick={() => walletsPanelRef.current?.openWalletModal()}
              >
                Register New Wallet
              </Button>
            </Flex>
            <ThemeProvider theme={defaultMaterialTheme}>
              <Box overflowX="auto" width="100%">
                <WalletsPanel ref={walletsPanelRef} profileId={profileId} />
              </Box>
            </ThemeProvider>
          </MotionVStack>
        )}
      </AnimatePresence>
    </VStack>
  );
});

Wallets.displayName = 'Wallets';

export default Wallets;
