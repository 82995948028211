import { Button, Flex } from '@chakra-ui/react';
import { motion, LayoutGroup } from 'framer-motion';
import { type FC, useEffect, useRef } from 'react';

interface NavigationTabsProps {
  activeSection: string;
  setActiveSection: (section: string) => void;
  relatedPartiesEnabled: boolean | null;
  walletsEnabled: boolean | null;
  expectedTransactionActivityEnabled: boolean | null;
  businessRegulatoryComplianceEnabled: boolean | null;
  onSectionChange: (section: string) => void;
}

const UNDERLINE_TRANSITION = {
  type: 'tween',
  duration: 0.15,
  ease: 'easeInOut'
};

const NavigationTabs: FC<NavigationTabsProps> = ({
  activeSection,
  setActiveSection,
  relatedPartiesEnabled,
  walletsEnabled,
  expectedTransactionActivityEnabled,
  businessRegulatoryComplianceEnabled,
  onSectionChange
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleSectionChange = (section: string): void => {
    setActiveSection(section);
    onSectionChange(section);
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer == null) return;

    const activeButton = scrollContainer.querySelector(`[data-section="${activeSection}"]`);
    if (activeButton == null) return;

    const containerWidth = scrollContainer.offsetWidth;
    const buttonLeft = (activeButton as HTMLElement).offsetLeft;
    const buttonWidth = (activeButton as HTMLElement).offsetWidth;
    const scrollLeft = buttonLeft - containerWidth / 2 + buttonWidth / 2;

    scrollContainer.scrollTo({
      left: scrollLeft,
      behavior: 'smooth'
    });
  }, [activeSection]);

  return (
    <LayoutGroup>
      <Flex mb={4} width="100%" overflow="hidden">
        <Flex
          ref={scrollContainerRef}
          borderBottom="1px solid"
          borderColor="gray.200"
          overflowX="auto"
          width="100%"
          css={{
            '&::-webkit-scrollbar': {
              display: 'none'
            },
            msOverflowStyle: 'none',
            scrollbarWidth: 'none'
          }}
        >
          <Flex minWidth="max-content">
            <Button
              data-section="Overview"
              variant="unstyled"
              px={4}
              py={2}
              position="relative"
              color={activeSection === 'Overview' ? 'primary.500' : 'gray.600'}
              borderRadius="0"
              _hover={{ color: 'primary.400' }}
              onClick={() => {
                handleSectionChange('Overview');
              }}
              whiteSpace="nowrap"
            >
              Overview
              {activeSection === 'Overview' && (
                <motion.div
                  layoutId="underline"
                  transition={UNDERLINE_TRANSITION}
                  style={{
                    position: 'absolute',
                    bottom: -1,
                    left: 0,
                    right: 0,
                    height: '3px',
                    backgroundColor: '#BED903'
                  }}
                />
              )}
            </Button>
            <Button
              data-section="Primary party information"
              variant="unstyled"
              px={4}
              py={2}
              position="relative"
              color={activeSection === 'Primary party information' ? 'primary.500' : 'gray.600'}
              borderRadius="0"
              _hover={{ color: 'primary.400' }}
              onClick={() => {
                handleSectionChange('Primary party information');
              }}
              whiteSpace="nowrap"
            >
              Primary party information
              {activeSection === 'Primary party information' && (
                <motion.div
                  layoutId="underline"
                  transition={UNDERLINE_TRANSITION}
                  style={{
                    position: 'absolute',
                    bottom: -1,
                    left: 0,
                    right: 0,
                    height: '3px',
                    backgroundColor: '#BED903'
                  }}
                />
              )}
            </Button>
            {expectedTransactionActivityEnabled === true && (
              <Button
                data-section="Expected Transaction Activity"
                variant="unstyled"
                px={4}
                py={2}
                position="relative"
                color={activeSection === 'Expected Transaction Activity' ? 'primary.500' : 'gray.600'}
                borderRadius="0"
                _hover={{ color: 'primary.400' }}
                onClick={() => {
                  handleSectionChange('Expected Transaction Activity');
                }}
                whiteSpace="nowrap"
              >
                Expected Transaction Activity
                {activeSection === 'Expected Transaction Activity' && (
                  <motion.div
                    layoutId="underline"
                    transition={UNDERLINE_TRANSITION}
                    style={{
                      position: 'absolute',
                      bottom: -1,
                      left: 0,
                      right: 0,
                      height: '3px',
                      backgroundColor: '#BED903'
                    }}
                  />
                )}
              </Button>
            )}
            <Button
              data-section="PEP Information"
              variant="unstyled"
              px={4}
              py={2}
              position="relative"
              color={activeSection === 'PEP Information' ? 'primary.500' : 'gray.600'}
              borderRadius="0"
              _hover={{ color: 'primary.400' }}
              onClick={() => {
                handleSectionChange('PEP Information');
              }}
              whiteSpace="nowrap"
            >
              PEP Information
              {activeSection === 'PEP Information' && (
                <motion.div
                  layoutId="underline"
                  transition={UNDERLINE_TRANSITION}
                  style={{
                    position: 'absolute',
                    bottom: -1,
                    left: 0,
                    right: 0,
                    height: '3px',
                    backgroundColor: '#BED903'
                  }}
                />
              )}
            </Button>
            {businessRegulatoryComplianceEnabled === true && (
              <Button
                data-section="Business Regulatory Compliance"
                variant="unstyled"
                px={4}
                py={2}
                position="relative"
                color={activeSection === 'Business Regulatory Compliance' ? 'primary.500' : 'gray.600'}
                borderRadius="0"
                _hover={{ color: 'primary.400' }}
                onClick={() => {
                  handleSectionChange('Business Regulatory Compliance');
                }}
                whiteSpace="nowrap"
              >
                Business Regulatory Compliance
                {activeSection === 'Business Regulatory Compliance' && (
                  <motion.div
                    layoutId="underline"
                    transition={UNDERLINE_TRANSITION}
                    style={{
                      position: 'absolute',
                      bottom: -1,
                      left: 0,
                      right: 0,
                      height: '3px',
                      backgroundColor: '#BED903'
                    }}
                  />
                )}
              </Button>
            )}
            {relatedPartiesEnabled != null && (
              <Button
                data-section="Related Parties"
                variant="unstyled"
                px={4}
                py={2}
                position="relative"
                color={activeSection === 'Related Parties' ? 'primary.500' : 'gray.600'}
                borderRadius="0"
                _hover={{ color: 'primary.400' }}
                onClick={() => {
                  handleSectionChange('Related Parties');
                }}
                whiteSpace="nowrap"
              >
                Related Parties
                {activeSection === 'Related Parties' && (
                  <motion.div
                    layoutId="underline"
                    transition={UNDERLINE_TRANSITION}
                    style={{
                      position: 'absolute',
                      bottom: -1,
                      left: 0,
                      right: 0,
                      height: '3px',
                      backgroundColor: '#BED903'
                    }}
                  />
                )}
              </Button>
            )}
            {walletsEnabled != null && (
              <Button
                data-section="Wallets"
                variant="unstyled"
                px={4}
                py={2}
                position="relative"
                color={activeSection === 'Wallets' ? 'primary.500' : 'gray.600'}
                borderRadius="0"
                _hover={{ color: 'primary.400' }}
                onClick={() => {
                  handleSectionChange('Wallets');
                }}
                whiteSpace="nowrap"
              >
                Wallets
                {activeSection === 'Wallets' && (
                  <motion.div
                    layoutId="underline"
                    transition={UNDERLINE_TRANSITION}
                    style={{
                      position: 'absolute',
                      bottom: -1,
                      left: 0,
                      right: 0,
                      height: '3px',
                      backgroundColor: '#BED903'
                    }}
                  />
                )}
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </LayoutGroup>
  );
};

export default NavigationTabs;
