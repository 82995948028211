import { useState, createContext, useContext, useMemo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

interface ProfileWizardContextProps {
  currentStep: number;
  setCurrentStep: (step: number) => void;
  maxSteps: number;
  setMaxSteps: (step: number) => void;
  stepTitles: string[];
  setStepTitles: (titles: string[]) => void;
}

interface ProfileWizardProviderProps {
  children: React.ReactNode;
}

const ProfileWizardContext = createContext<ProfileWizardContextProps>({
  currentStep: 1,
  setCurrentStep: () => {},
  maxSteps: 6,
  setMaxSteps: () => {},
  stepTitles: [],
  setStepTitles: () => {}
});

export const useProfileWizard = (): ProfileWizardContextProps => {
  return useContext(ProfileWizardContext);
};

export const ProfileWizardProvider: React.FC<ProfileWizardProviderProps> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const initialStep = Number(searchParams.get('step') ?? 1);

  const [currentStep, setCurrentStep] = useState(initialStep);
  const [maxSteps, setMaxSteps] = useState(2);
  const [stepTitles, setStepTitles] = useState<string[]>([]);

  useEffect(() => {
    setCurrentStep(initialStep);
  }, [initialStep]);

  const value = useMemo(
    () => ({
      currentStep,
      setCurrentStep,
      maxSteps,
      setMaxSteps,
      stepTitles,
      setStepTitles
    }),
    [currentStep, maxSteps, stepTitles]
  );

  return <ProfileWizardContext.Provider value={value}>{children}</ProfileWizardContext.Provider>;
};
