import { InfoIcon } from '@chakra-ui/icons';
import { Box, Flex, Text, Heading, Tooltip as ChakraTooltip } from '@chakra-ui/react';

import AddressIdentificationSection from '@features/wallets/AddressIdentificationSection.component';
import ExposureData from '@features/wallets/ExposureData.component';
import NameDisplay from '@features/wallets/NameDisplay.component';
import NotesDisplay from '@features/wallets/NotesDisplay.component';
import OverviewSection from '@features/wallets/OverviewSection.component';
import ScreeningInfoDisplay from '@features/wallets/ScreeningInfoDisplay.component';
import TriggersSection from '@features/wallets/TriggersSection.component';
import { getExposureInformation } from '@features/wallets/Wallet.component';
import { getTriggerDifferences } from '@utils/alerts';
import { RISK_BG_COLORS, RISK_BORDER_COLORS } from '@utils/consts';

import AlertDetailScreeningHits from './AlertDetailScreeningHits';

const AlertBody = ({
  alert,
  onResolveMatch
}: {
  alert: any;
  onResolveMatch: (id: string, resolution: 'TRUE_MATCH' | 'FALSE_POSITIVE', notes: string) => Promise<void>;
}): JSX.Element => {
  const { sum, exposureRisk, directRisks } = getExposureInformation(
    alert.currentInquiryResult?.result?.exposures ?? []
  );

  const chartData = {
    labels: ['Severe', 'High', 'Medium', 'Low'],
    datasets: [
      {
        label: '% of Total Exposure',
        data: [directRisks.severe, directRisks.high, directRisks.medium, directRisks.low],
        backgroundColor: [RISK_BG_COLORS.SEVERE, RISK_BG_COLORS.HIGH, RISK_BG_COLORS.MEDIUM, RISK_BG_COLORS.LOW],
        borderColor: [
          RISK_BORDER_COLORS.SEVERE,
          RISK_BORDER_COLORS.HIGH,
          RISK_BORDER_COLORS.MEDIUM,
          RISK_BORDER_COLORS.LOW
        ],
        borderWidth: 0
      }
    ]
  };

  if (alert.resourcetype === 'WatchlistAlert') {
    return <AlertDetailScreeningHits alert={alert} onResolveMatch={onResolveMatch} />;
  }

  return (
    <Box p={5} bg="white" layerStyle="profileOverview.container" mb={4} overflowY="auto" maxHeight="600px">
      <Text fontWeight={800} mb={4}>
        Wallet Details:{' '}
      </Text>
      <Flex flexDirection="row" pt={2} flexWrap="wrap" gap={3}>
        <Box flexGrow="1" flexBasis={180}>
          <NameDisplay
            name={alert.wallet?.name}
            address={alert.wallet?.address}
            walletId={alert.wallet?.id}
            orgId={alert.orgId}
          />
          <ScreeningInfoDisplay
            createdAt={alert.wallet?.createdAt}
            lastInquiryCreatedAt={alert.wallet?.lastInquiry?.createdAt}
            frequency={alert.wallet?.schedule?.frequency}
            walletId={alert.wallet?.id}
            orgId={alert.orgId}
            walletName={alert.wallet?.name}
            walletAddress={alert.wallet?.address}
          />
        </Box>
        <Box flexGrow="2">
          <OverviewSection
            address={alert.wallet?.address}
            risk={alert?.currentInquiryResult?.result?.risk}
            cluster={alert?.currentInquiryResult?.result?.cluster}
          />
        </Box>
      </Flex>
      <Box layerStyle="profileOverview.container" mt={4} pl={5} pr={5} pt={4} pb={4} mb={4} bg="white">
        <Heading size="sm">
          Exposure
          <ChakraTooltip label="Exposure refers to the relationship between the screened address and other entities that is created by transfers to/from the address. Exposure is determined by the last identified source of funds and the first identified destination of funds for all transfers that have been received to, or sent from, the screened address.">
            <InfoIcon />
          </ChakraTooltip>
        </Heading>
        <ExposureData
          exposures={[...(alert.currentInquiryResult?.result?.exposures ?? [])]}
          sum={sum}
          exposureRisk={exposureRisk}
          chartData={chartData}
        />
      </Box>
      <NotesDisplay notes={alert.wallet?.notes} walletId={alert.wallet?.id} orgId={alert.orgId} />
      <AddressIdentificationSection
        addressIdentifications={alert?.currentInquiryResult?.result?.addressIdentifications}
      />
      <TriggersSection
        triggers={
          getTriggerDifferences(
            alert?.prevInquiryResult?.result?.triggers,
            alert?.currentInquiryResult?.result?.triggers
          ).changedTriggers
        }
      />
    </Box>
  );
};

export default AlertBody;
