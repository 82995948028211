import { IconButton, Flex, Heading, VStack } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import EditableField from '@features/profile-overview/components/EditableField/';
import { type IProfile } from '@models/profileTypes';

const MotionVStack = motion.create(VStack as any);

export interface PepInformationRef {
  expand: () => void;
  scrollIntoView: (options?: ScrollIntoViewOptions) => void;
}

interface PepInformationProps {
  profile: IProfile;
  handleFieldChange: (field: string) => (value: string | number | Date | Array<string | number>) => void;
  mappedCountries: any[];
  handleSectionHover?: (section: string) => void;
}

const PepInformation = forwardRef<PepInformationRef, PepInformationProps>(
  ({ profile, handleFieldChange, mappedCountries, handleSectionHover }, ref) => {
    const [isOpen, setIsOpen] = useState(true);
    const containerRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      expand: () => {
        setIsOpen(true);
      },
      scrollIntoView: (options?: ScrollIntoViewOptions) => {
        containerRef.current?.scrollIntoView(options);
      }
    }));

    return (
      <VStack
        ref={containerRef}
        spacing={4}
        align="stretch"
        m={3}
        p={3}
        boxShadow="md"
        bg="white"
        w="100%"
        layerStyle="profileOverview.container"
        onMouseEnter={() => {
          handleSectionHover?.('PEP Information');
        }}
      >
        <Flex justifyContent="space-between" width="full" alignItems="center">
          <Heading as="h1" size="md" textAlign="left">
            Pep Information
          </Heading>
          <IconButton
            aria-label="Toggle PEP Information"
            icon={isOpen ? <FaChevronUp /> : <FaChevronDown />}
            size="sm"
            variant="ghost"
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          />
        </Flex>

        <AnimatePresence>
          {isOpen && (
            <MotionVStack
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              spacing={4}
              display="flex"
              flexDirection="column"
              alignItems="stretch"
            >
              <EditableField
                label="PEP Declaration"
                value={profile?.pepDeclaration?.toString()}
                type="select"
                options={[
                  { value: 'true', name: 'Yes' },
                  { value: 'false', name: 'No' }
                ]}
                onConfirmChange={handleFieldChange('pepDeclaration')}
              />
              <EditableField
                label="PEP Name"
                value={profile?.pepName}
                onConfirmChange={handleFieldChange('pepName')}
                type="text"
              />
              <EditableField
                label="PEP Position"
                value={profile?.pepPosition}
                onConfirmChange={handleFieldChange('pepPosition')}
                type="text"
              />
              <EditableField
                label="PEP Country"
                value={profile?.pepCountry?.id}
                onConfirmChange={handleFieldChange('pepCountry')}
                type="select"
                options={mappedCountries}
              />
            </MotionVStack>
          )}
        </AnimatePresence>
      </VStack>
    );
  }
);

PepInformation.displayName = 'PepInformation';

export default PepInformation;
