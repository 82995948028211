import { Box, Button, Input, VStack, Textarea, Flex, useToast, Divider } from '@chakra-ui/react';
import pick from 'lodash/pick';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import { StyledSelect } from '@components/common/StyledSelect';
import { FormField } from '@components/FormField';

import { useProfileWizard } from '@context/ProfileWizardContext';
import useQueryParams from '@hooks/useQueryParams';
import { type IProfile } from '@models/profileTypes';
import { useUpdateProfileMutation } from '@services/canaria.services';
import { convertObjectKeysToCamelCase } from '@services/utils';

const AddExpectedTransactionActivityForm: React.FC<{ profile: IProfile; orgId: string }> = ({ orgId, profile }) => {
  const navigate = useNavigate();
  const { currentStep, maxSteps } = useProfileWizard();
  const toast = useToast();
  const { queryParams, setQueryParams } = useQueryParams();
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();

  const parseValue = (value: any): any => (value === undefined ? '' : value);

  const onSubmit = async (values): Promise<object | undefined> => {
    try {
      await updateProfile({
        orgId,
        profileId: profile.id,
        ...values
      }).unwrap();
    } catch (error) {
      toast({
        title: 'Error',
        description: 'An error occurred while updating the profile',
        status: 'error',
        isClosable: true
      });
      return convertObjectKeysToCamelCase((error as { data: object }).data);
    }
    if (currentStep === maxSteps) {
      navigate(`/dashboard/profiles/${profile.id}`);
      return;
    }
    setQueryParams({ ...queryParams, step: currentStep + 1 });
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={pick(profile, [
        'purposeForEstablishingAccount',
        'expectedNatureOfActivity',
        'sourceOfFunds',
        'flowOfFunds',
        'activityType',
        'averageMonthlyTxn',
        'averageMonthlyValueTxn',
        'expectedTransactionActivityComments'
      ])}
      render={({ handleSubmit }) => (
        <Box as="form" onSubmit={handleSubmit} w="100%" mx="auto">
          <VStack spacing={4} align="stretch" p={4} w="100%">
            <FormField
              name="purposeForEstablishingAccount"
              label="Purpose for Establishing Account"
              validate={['required']}
            >
              <Textarea placeholder="Enter purpose" />
            </FormField>

            <Divider />

            <FormField name="expectedNatureOfActivity" label="Expected Nature of Activity" validate={['required']}>
              <Textarea placeholder="Describe the nature of activity" />
            </FormField>

            <Divider />

            <FormField name="sourceOfFunds" label="Source of Funds" validate={['required']}>
              <Textarea placeholder="Specify the source of funds" />
            </FormField>
            <FormField name="flowOfFunds" label="Flow of Funds Description" validate={['required']}>
              <Textarea placeholder="Describe the flow of funds" />
            </FormField>

            <Divider />

            <FormField name="activityType" label="One-time or Ongoing Activity?" validate={['required']}>
              <StyledSelect
                placeholder="Select activity type"
                options={[
                  { label: 'One-time', value: 'one-time' },
                  { label: 'Ongoing', value: 'ongoing' }
                ]}
              />
            </FormField>
            <FormField name="averageMonthlyTxn" label="Average Monthly # Txn" validate={['number']} parse={parseValue}>
              <Input placeholder="0.00" type="number" />
            </FormField>
            <FormField
              name="averageMonthlyValueTxn"
              label="Average Monthly $ Value Txns"
              validate={['number']}
              parse={parseValue}
            >
              <Input placeholder="0.00" type="number" />
            </FormField>
            <FormField name="expectedTransactionActivityComments" label="Additional Comments">
              <Textarea placeholder="Enter additional comments" />
            </FormField>

            <Flex justifyContent="flex-end" gap={4} mt={8} mb={6} px={4}>
              <Button
                bg="button.secondary"
                border="1px solid"
                borderColor="button.secondaryBorder"
                px={16}
                onClick={() => {
                  setQueryParams({ ...queryParams, step: currentStep - 1 });
                }}
              >
                Back
              </Button>
              <Button type="submit" bg="button.primary" isLoading={isLoading} px={16}>
                {currentStep === maxSteps ? 'Finish' : 'Next'}
              </Button>
            </Flex>
          </VStack>
        </Box>
      )}
    />
  );
};

export default AddExpectedTransactionActivityForm;
