import { type ValidatorType } from '@components/FormField';

import EditableDate from './EditableDate';
import EditableSelect from './EditableSelect';
import EditableText from './EditableText';

interface EditableFieldProps {
  label: string;
  value: string | number | Date | Array<string | number>;
  type: 'text' | 'select' | 'date' | 'multipleSelect';
  options?: Array<{ name: string; value: string | number }>;
  onConfirmChange: (value: string | number | Date | Array<string | number>) => void;
  isDisabled?: boolean;
  allowNull?: boolean;
  validate?: ValidatorType | ValidatorType[];
}

const EditableField: React.FC<EditableFieldProps> = ({
  label,
  value,
  type,
  options = [],
  onConfirmChange,
  isDisabled,
  allowNull,
  validate
}) => {
  switch (type) {
    case 'text':
      return (
        <EditableText
          label={label}
          value={value as string}
          onConfirmChange={onConfirmChange}
          isDisabled={isDisabled}
          validate={validate}
        />
      );
    case 'select':
      return (
        <EditableSelect
          label={label}
          value={value as string}
          options={options}
          onConfirmChange={onConfirmChange}
          isDisabled={isDisabled}
          allowNull={allowNull}
        />
      );
    case 'date':
      return (
        <EditableDate label={label} value={value as Date} onConfirmChange={onConfirmChange} isDisabled={isDisabled} />
      );
    case 'multipleSelect':
      return (
        <EditableSelect
          label={label}
          value={value as string}
          options={options}
          onConfirmChange={onConfirmChange}
          isDisabled={isDisabled}
          allowNull={allowNull}
          isMulti
        />
      );
    default:
      return null;
  }
};

export default EditableField;
