import { Box, Button, ButtonGroup, Flex, Grid, GridItem, VStack, useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { RiAddLine, RiArrowLeftLine, RiArrowRightLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';

import { WalletConfirmationModal } from '@components/WalletConfirmationModal';
import { WalletFormFields } from '@components/WalletFormFields';

import { useProfileWizard } from '@context/ProfileWizardContext';
import { Loading } from '@features/shared/components';
import useQueryParams from '@hooks/useQueryParams';
import { useWalletForm } from '@hooks/useWalletForm';
import { type IProfile } from '@models/profileTypes';
import { type IWallet } from '@models/walletTypes';
import { useGetWalletsQuery } from '@services/canaria.services';

interface WalletListProps {
  wallets: IWallet[];
}

const WalletList: React.FC<WalletListProps> = ({ wallets }) => {
  return (
    <VStack spacing={4} w="full" mb={4}>
      <Grid templateColumns="repeat(3, 1fr) auto" gap={4} p={5} fontWeight="bold" w="full">
        <GridItem>Asset/Blockchain</GridItem>
        <GridItem>Wallet Address</GridItem>
        <GridItem>Name</GridItem>
        <GridItem></GridItem> {/* This is for the button group alignment */}
      </Grid>
      {wallets.map((wallet, index) => (
        <Grid key={index} templateColumns="repeat(3, 1fr) auto" gap={4} p={2} alignContent="center" w="full" bg="white">
          <GridItem justifyContent="start">{wallet.blockchain}</GridItem>
          <GridItem justifyContent="start" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            {wallet.address}
          </GridItem>
          <GridItem justifyContent="start">{wallet.name}</GridItem>

          <GridItem>
            <ButtonGroup spacing="4" justifyContent="flex-end">
              {/* <Button colorScheme="blue" variant="outline">
                Edit
              </Button> */}
              {/* TODO: Implement remove wallet */}
              {/* <Button colorScheme="red" variant="outline">
                Remove
              </Button>  */}
            </ButtonGroup>
          </GridItem>
        </Grid>
      ))}
    </VStack>
  );
};

const AddWalletForm: React.FC<{ orgId: string; profileId: string; onSuccess: () => void; onCancel: () => void }> = ({
  orgId,
  profileId,
  onSuccess,
  onCancel
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formValues, setFormValues] = useState<any>(null);

  const {
    validationMessage,
    messageColor,
    canAddWallet,
    isLoading,
    isValidationLoading,
    isBlockchainNetworksLoading,
    networksByLayer,
    handleValidateAddress,
    handleSubmit,
    handleFieldChange
  } = useWalletForm(orgId, profileId, onOpen, onSuccess);

  const handleConfirmLink = async (): Promise<void> => {
    if (formValues != null) {
      await handleSubmit(formValues, true);
      onClose();
      onSuccess();
    }
  };

  return (
    <>
      <Form
        onSubmit={async (values) => {
          const processedValues = { ...values, blockchain: values.blockchain?.value };
          setFormValues(processedValues);
          const result = await handleSubmit(processedValues);
          return result;
        }}
        subscription={{ values: true }}
        render={({ handleSubmit, values }) => {
          const addWalletButtonEnabled =
            canAddWallet && values.name != null && values.blockchain != null && values.address != null;

          return (
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="stretch" w="100%">
                <WalletFormFields
                  layout="horizontal"
                  networksByLayer={networksByLayer}
                  validationMessage={validationMessage}
                  messageColor={messageColor}
                  isValidationLoading={isValidationLoading}
                  isBlockchainNetworksLoading={isBlockchainNetworksLoading}
                  handleFieldChange={handleFieldChange}
                  handleValidateAddress={handleValidateAddress}
                  values={values}
                />

                <Flex justifyContent="flex-end" gap={4} mt={4}>
                  <Button
                    bg="button.secondary"
                    border="1px solid"
                    borderColor="button.secondaryBorder"
                    px={16}
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    isLoading={isLoading}
                    isDisabled={!addWalletButtonEnabled}
                    bg="button.primary"
                    px={16}
                  >
                    Add Wallet
                  </Button>
                </Flex>
              </VStack>
            </form>
          );
        }}
      />
      <WalletConfirmationModal isOpen={isOpen} onClose={onClose} onConfirm={handleConfirmLink} />
    </>
  );
};

const AddWalletsPanel: React.FC<{ profile: IProfile; orgId: string }> = ({ orgId, profile }) => {
  const navigate = useNavigate();
  const { currentStep, maxSteps } = useProfileWizard();
  const { queryParams, setQueryParams } = useQueryParams();
  const [showForm, setShowForm] = useState<boolean>(false);
  const { data, isLoading } = useGetWalletsQuery({
    orgId,
    query: {
      page_size: 10,
      page: 1,
      ordering: '-created_at',
      profiles: profile.id
    }
  });

  const wallets = data?.results ?? [];

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box w="100%">
      <Box p={4}>
        {wallets.length === 0 ? (
          <Box width="100%" marginX="auto" minH="36" mt={!showForm ? '24' : '0'}>
            {!showForm ? (
              <>
                <Box textAlign="center" mb={4}>
                  <Box as="h2" fontSize="xl" fontWeight="bold" mb={2} color="title">
                    Add Wallets
                  </Box>
                  <Box color="description" mb={4}>
                    You can skip this step by clicking &apos;Next&apos; if you no wallets to be added.
                  </Box>
                </Box>
                <Box display="flex" justifyContent="center" mt={10}>
                  <Button
                    bg="wizard.addRelatedParty.button.bg"
                    color="wizard.addRelatedParty.button.color"
                    onClick={() => {
                      setShowForm(true);
                    }}
                    leftIcon={<RiAddLine />}
                  >
                    Add wallet
                  </Button>
                </Box>
              </>
            ) : (
              <AddWalletForm
                orgId={orgId}
                profileId={profile.id}
                onSuccess={() => {
                  setShowForm(false);
                }}
                onCancel={() => {
                  setShowForm(false);
                }}
              />
            )}
          </Box>
        ) : (
          <>
            {!showForm ? (
              <>
                <WalletList wallets={wallets} />
                <Flex justifyContent="flex-end">
                  <Button
                    bg="wizard.addRelatedParty.button.bg"
                    color="wizard.addRelatedParty.button.color"
                    onClick={() => {
                      setShowForm(true);
                    }}
                    leftIcon={<RiAddLine />}
                  >
                    Add wallet
                  </Button>
                </Flex>
              </>
            ) : (
              <AddWalletForm
                orgId={orgId}
                profileId={profile.id}
                onSuccess={() => {
                  setShowForm(false);
                }}
                onCancel={() => {
                  setShowForm(false);
                }}
              />
            )}
          </>
        )}
        {!showForm && (
          <Flex justifyContent="flex-end" gap={4} mt={4}>
            <Button
              bg="button.secondary"
              border="1px solid"
              borderColor="button.secondaryBorder"
              onClick={() => {
                setQueryParams({ ...queryParams, step: currentStep - 1 });
              }}
              px={16}
              leftIcon={<RiArrowLeftLine />}
            >
              Back
            </Button>
            <Button
              bg="button.primary"
              onClick={() => {
                if (currentStep === maxSteps) {
                  navigate(`/dashboard/profiles/${profile.id}`);
                  return;
                }
                setQueryParams({ ...queryParams, step: currentStep + 1 });
              }}
              px={16}
              rightIcon={<RiArrowRightLine />}
            >
              {currentStep === maxSteps ? 'Finish' : 'Next'}
            </Button>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default AddWalletsPanel;
