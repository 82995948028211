import { Box, Button, Flex, VStack, useDisclosure, Divider, useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { RiArrowLeftLine, RiArrowRightLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';

import { StyledSelect } from '@components/common/StyledSelect';
import { FormField } from '@components/FormField';

import { useProfileWizard } from '@context/ProfileWizardContext';
import AttachmentModal from '@features/profile-wizard/components/common/AddAttachmentModal';
import { useProfileForm } from '@hooks/useProfileForm';
import useQueryParams from '@hooks/useQueryParams';
import { PROFILE_TYPE, type IProfile } from '@models/profileTypes';
import { useGetProfileAttachmentsQuery } from '@services/canaria.services';
import { convertObjectKeysToCamelCase } from '@services/utils';
import { RELATION_TYPE_OPTIONS } from '@utils/consts';

import { IndividualPrimaryPartyForm, EntityPrimaryPartyForm } from './forms/primaryParty';

interface IProfileWithRelationType extends IProfile {
  relationTypes?: string[];
}

interface ProfileFormProps {
  profile?: IProfileWithRelationType;
  onSubmit: (values: any) => Promise<void>;
  isRelatedParty?: boolean;
  relationTypes?: string[];
}

export const AddPrimaryPartyForm: React.FC<ProfileFormProps> = ({
  profile,
  onSubmit,
  isRelatedParty = false,
  relationTypes = []
}) => {
  const [currentProfileType, setCurrentProfileType] = useState<PROFILE_TYPE | undefined>(
    profile?.resourcetype ?? PROFILE_TYPE.INDIVIDUAL
  );
  const toast = useToast();

  const handleSubmit = async (values): Promise<object | undefined> => {
    try {
      await onSubmit(values);
    } catch (error: unknown) {
      toast({
        title: 'Failed to save',
        description: 'Please try again.',
        status: 'error'
      });
      return convertObjectKeysToCamelCase((error as { data: object }).data);
    }
  };

  // We set the form id so we can submit the form externally
  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        ...profile,
        relationTypes,
        profileType: currentProfileType,
        // Individual Profile
        citizenship: profile?.citizenship?.id,
        countryOfResidence: profile?.countryOfResidence?.id,
        idIssuer: profile?.idIssuer?.id,
        placeOfBirth: profile?.placeOfBirth?.id,
        gender: profile?.gender?.value,
        idType: profile?.idType?.value,
        // Entity Profile
        placeOfEstablishment: profile?.placeOfEstablishment?.id
      }}
      keepDirtyOnReinitialize
      render={({ handleSubmit, form }) => (
        <Box id="IndividualPrimaryParty" as="form" onSubmit={handleSubmit} w="100%">
          <VStack spacing={4} align="stretch" p={5}>
            {isRelatedParty && (
              <VStack spacing={4} align="stretch">
                <FormField name="profileType" label="Profile Type">
                  {({ input }) => {
                    const options = [
                      { value: PROFILE_TYPE.INDIVIDUAL, label: 'Individual' },
                      { value: PROFILE_TYPE.ENTITY, label: 'Entity' }
                    ];

                    return (
                      <StyledSelect
                        {...input}
                        placeholder="Select profile type"
                        options={options}
                        isDisabled={profile?.resourcetype != null}
                        onChange={(newValue: unknown) => {
                          const value = newValue as { value: PROFILE_TYPE };
                          input.onChange(value);
                          setCurrentProfileType(value.value);
                        }}
                      />
                    );
                  }}
                </FormField>
                <FormField name="relationTypes" label="Relation Types">
                  <StyledSelect
                    options={Object.entries(RELATION_TYPE_OPTIONS).map(([value, label]) => ({
                      value,
                      label
                    }))}
                    placeholder="Select relation types"
                    isMulti
                  />
                </FormField>
                <Divider />
              </VStack>
            )}
            {currentProfileType === PROFILE_TYPE.INDIVIDUAL ? (
              <IndividualPrimaryPartyForm form={form} />
            ) : (
              <EntityPrimaryPartyForm />
            )}
          </VStack>
        </Box>
      )}
    />
  );
};

interface ProfilePageProps {
  orgId: string;
  profile: IProfile;
}

const AddPrimaryPartyPage: React.FC<ProfilePageProps> = ({ orgId, profile }) => {
  const navigate = useNavigate();
  const { currentStep, maxSteps } = useProfileWizard();
  const { queryParams, setQueryParams } = useQueryParams();
  const { data: attachments } = useGetProfileAttachmentsQuery({ orgId, profileId: profile.id });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const identificationAttachment = attachments?.find((attachment) => attachment.attachmentType === 'IDENTIFICATION');

  const { handleSubmit, isLoading } = useProfileForm({
    orgId,
    onSuccess: async () => {
      if (currentStep === maxSteps) {
        navigate(`/dashboard/profiles/${profile.id}`);
        return;
      }
      setQueryParams({ ...queryParams, step: currentStep + 1 });
    }
  });

  const onSubmit = async (values): Promise<void> => {
    await handleSubmit(values, profile.id);
  };

  return (
    <Box w="100%">
      {profile.resourcetype === PROFILE_TYPE.INDIVIDUAL && (
        <Flex justifyContent="flex-end" mb="4" px={4}>
          {identificationAttachment == null ? (
            <Button bg="button.secondary" border="1px solid" borderColor="button.secondaryBorder" onClick={onOpen}>
              Scan ID
            </Button>
          ) : (
            <Button
              bg="black"
              color="white"
              onClick={() => {
                window.open(identificationAttachment.file);
              }}
            >
              Attachment uploaded
            </Button>
          )}
        </Flex>
      )}
      <AddPrimaryPartyForm profile={profile} onSubmit={onSubmit} />
      {/* 
        Conditionally render modal despite having Chakra's useDisclosure hook.
        The hook only handles visibility while conditional rendering ensures the component fully unmounts,
        resetting its internal state on close.
      */}
      {isOpen && (
        <AttachmentModal
          isOpen={isOpen}
          onClose={onClose}
          orgId={orgId}
          profileId={profile.id}
          fixedAttachmentType="IDENTIFICATION"
        />
      )}
      <Flex justifyContent="flex-end" gap={4} mt={8} mb={6} px={4}>
        <Button
          bg="button.secondary"
          border="1px solid"
          borderColor="button.secondaryBorder"
          onClick={() => {
            setQueryParams({ ...queryParams, step: currentStep - 1 });
          }}
          px={16}
          leftIcon={<RiArrowLeftLine />}
        >
          Back
        </Button>
        <Button
          type="submit"
          form="IndividualPrimaryParty"
          bg="button.primary"
          isLoading={isLoading}
          px={16}
          rightIcon={<RiArrowRightLine />}
        >
          {currentStep === maxSteps ? 'Finish' : 'Next'}
        </Button>
      </Flex>
    </Box>
  );
};

export default AddPrimaryPartyPage;
