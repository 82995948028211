import { Box, Grid, GridItem, Heading, Text, Textarea } from '@chakra-ui/react';

const AddressIdentificationSection: React.FC<{
  addressIdentifications: any[];
}> = ({ addressIdentifications }) => {
  return (
    <Box pl={5} pr={5} pt={4} pb={4} bg="white" layerStyle="profileOverview.container">
      <Heading size="sm">Address Identification</Heading>
      {addressIdentifications != null ? (
        addressIdentifications.length === 0 ? (
          <Box textAlign="center" py={8}>
            <Heading size="sm" color="gray.600" mb={3}>
              No address identification found
            </Heading>
            <Text color="gray.500" fontSize="sm" maxW="600px" mx="auto" textAlign="center">
              No blockchain address identifications have been associated with this entity. Address identifications help
              track and monitor blockchain activities for enhanced compliance monitoring.
            </Text>
          </Box>
        ) : (
          addressIdentifications.map((ident, idx) => (
            <Grid key={idx} templateColumns="150px auto" pt={8}>
              <GridItem borderBottom="1px" borderColor="gray.300">
                <Text fontWeight="medium">Name</Text>
              </GridItem>
              <GridItem borderBottom="1px" borderColor="gray.300">
                <Text pl="1ch">{ident.name}</Text>
              </GridItem>
              <GridItem borderBottom="1px" borderColor="gray.300">
                <Text fontWeight="medium">Category</Text>
              </GridItem>
              <GridItem borderBottom="1px" borderColor="gray.300">
                <Text pl="1ch">{ident.category}</Text>
              </GridItem>
              <GridItem>
                <Text fontWeight="medium">Description</Text>
              </GridItem>
              <GridItem>
                <Textarea isReadOnly pl="1ch" value={ident.description.replaceAll('; ', '\n')} />
              </GridItem>
            </Grid>
          ))
        )
      ) : (
        <></>
      )}
    </Box>
  );
};

export default AddressIdentificationSection;
