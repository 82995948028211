import { CloseIcon } from '@chakra-ui/icons';
import {
  Divider,
  type UseToastOptions,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  FormControl,
  Input,
  Textarea,
  Text,
  Box,
  Select,
  useToast,
  Image
} from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { useRef, useState, useCallback } from 'react';
import { BiSolidCloudDownload, BiScan } from 'react-icons/bi';
import { FaFilePdf, FaFile } from 'react-icons/fa';

import {
  useGetSignedUrlMutation,
  useGetProfileAttachmentTypesQuery,
  useUploadDocumentMutation
} from '@services/canaria.services';
import { uploadFileToSignedUrl } from '@services/utils';

interface AttachmentModalProps {
  isOpen: boolean;
  onClose: () => void;
  orgId: string;
  profileId: string;
  fixedAttachmentType?: string;
}

const toastNoFileSelected: UseToastOptions = {
  title: 'No file selected.',
  description: 'Please select a file before uploading.',
  status: 'error',
  duration: 5000,
  isClosable: true
};

const toastFileLoaded: UseToastOptions = {
  title: 'File loaded.',
  description: 'Your file has been successfully loaded.',
  status: 'success',
  duration: 5000,
  isClosable: true
};

const toastFileUploaded: UseToastOptions = {
  title: 'File uploaded.',
  description: 'Your file has been successfully uploaded.',
  status: 'success',
  duration: 5000,
  isClosable: true
};

const toastUploadFailed: UseToastOptions = {
  title: 'Upload failed.',
  description: 'There was an error uploading your file.',
  status: 'error',
  duration: 5000,
  isClosable: true
};

const scanAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(140px);
  }
`;

const ScanningOverlay: React.FC = () => (
  <Box position="absolute" inset={0} zIndex={3} overflow="hidden">
    <Box
      position="absolute"
      width="100%"
      height="10px"
      bg="rgba(124, 126, 126, 0.5)"
      boxShadow="0px 0px 30px rgba(124, 126, 126, 0.3)"
      animation={`${scanAnimation} 1s alternate-reverse infinite`}
      zIndex={4}
    />
    <Box position="absolute" inset={0} bg="whiteAlpha.300" />
  </Box>
);

const AttachmentModal: React.FC<AttachmentModalProps> = ({
  isOpen,
  onClose,
  orgId,
  profileId,
  fixedAttachmentType
}) => {
  const toast = useToast();
  const { data: attachmentTypes } = useGetProfileAttachmentTypesQuery(null);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [attachmentName, setAttachmentName] = useState<string>('');
  const [attachmentNotes, setAttachmentNotes] = useState<string>('');
  const [attachmentType, setAttachmentType] = useState<string>(fixedAttachmentType ?? '');
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [isScanning, setIsScanning] = useState(false);

  const [uploadDocument, { isLoading }] = useUploadDocumentMutation();
  const [getSignedUrl] = useGetSignedUrlMutation();
  const handleFileDrop = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    updateFileAndPreview(e.dataTransfer.files[0]);
    toast(toastFileLoaded);
  };

  const handleSelectFilesClick = (): void => {
    if (fileInputRef.current != null) {
      fileInputRef.current.click();
    }
  };

  const handleClose = useCallback((): void => {
    if (previewUrl != null) {
      URL.revokeObjectURL(previewUrl);
      setPreviewUrl(null);
    }
    setFile(null);
    setAttachmentName('');
    setAttachmentNotes('');
    setAttachmentType(fixedAttachmentType ?? '');
    setFileUploading(false);
    setIsScanning(false);
    onClose();
  }, [onClose, previewUrl, fixedAttachmentType]);

  const handleUpload = async (): Promise<void> => {
    if (file == null) {
      toast(toastNoFileSelected);
      return;
    }

    try {
      setIsScanning(true);
      const signedUrlResponse = await getSignedUrl({
        orgId,
        profileId,
        filename: file.name,
        mimeType: file.type,
        fileSize: file.size
      }).unwrap();
      setFileUploading(true);
      if (file != null) {
        await uploadFileToSignedUrl(signedUrlResponse.signedUrl, file);
        await uploadDocument({
          orgId,
          profileId,
          key: signedUrlResponse.key,
          attachmentType,
          name: attachmentName,
          notes: attachmentNotes
        }).unwrap();
        toast(toastFileUploaded);
      }
    } catch (error: any) {
      if (error.data?.error != null) {
        toast({
          title: 'Upload failed.',
          description: error.data.error,
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      } else {
        toast(toastUploadFailed);
      }
    } finally {
      setIsScanning(false);
      handleClose();
      setFileUploading(false);
      setFile(null);
      setAttachmentType('');
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const files = e.target.files;
    if (files != null && files.length > 0) {
      updateFileAndPreview(files[0]);
      toast({
        title: 'File selected.',
        description: `${files.length} file(s) selected.`,
        status: 'info',
        duration: 5000,
        isClosable: true
      });
    }
  };

  const updateFileAndPreview = (newFile: File | null): void => {
    if (newFile != null) {
      const url = URL.createObjectURL(newFile);
      setPreviewUrl(url);
      setFile(newFile);
    }
  };

  const removeFile = (): void => {
    setFile(null);
  };

  return (
    <Modal key={isOpen ? 'open' : 'closed'} isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader mt={2}>Upload Document</ModalHeader>
        <ModalCloseButton mt={2} />
        <Divider borderColor="divider" />
        <ModalBody>
          <VStack spacing={4} align="stretch" mt={4}>
            {file != null ? (
              <>
                {fixedAttachmentType === 'IDENTIFICATION' && file.type.startsWith('image/') ? (
                  <Box
                    borderWidth="0px"
                    borderRadius="md"
                    overflow="hidden"
                    position="relative"
                    width="50%"
                    minHeight="150px"
                    mx="auto"
                    sx={{
                      '&::before, &::after': {
                        display: 'block',
                        content: '""',
                        width: '20px',
                        height: '20px',
                        position: 'absolute',
                        top: '0',
                        zIndex: 1
                      },
                      '&::before': {
                        left: '0',
                        borderTop: '2px solid var(--chakra-colors-gray-300)',
                        borderLeft: '2px solid var(--chakra-colors-gray-300)',
                        borderRadius: '4px 0 0 0'
                      },
                      '&::after': {
                        right: '0',
                        borderTop: '2px solid var(--chakra-colors-gray-300)',
                        borderRight: '2px solid var(--chakra-colors-gray-300)',
                        borderRadius: '0 4px 0 0'
                      },
                      '& > span::before, & > span::after': {
                        display: 'block',
                        content: '""',
                        width: '20px',
                        height: '20px',
                        position: 'absolute',
                        bottom: '0',
                        zIndex: 1
                      },
                      '& > span::before': {
                        left: '0',
                        borderBottom: '2px solid var(--chakra-colors-gray-300)',
                        borderLeft: '2px solid var(--chakra-colors-gray-300)',
                        borderRadius: '0 0 0 4px'
                      },
                      '& > span::after': {
                        right: '0',
                        borderBottom: '2px solid var(--chakra-colors-gray-300)',
                        borderRight: '2px solid var(--chakra-colors-gray-300)',
                        borderRadius: '0 0 4px 0'
                      }
                    }}
                  >
                    <Box as="span" position="absolute" inset={0} />
                    <Image
                      src={previewUrl ?? ''}
                      alt="ID Preview"
                      width="100%"
                      height="150px"
                      objectFit="contain"
                      p={6}
                      opacity={isScanning ? 0.7 : 1}
                      transition="opacity 0.2s"
                    />
                    {isScanning && <ScanningOverlay />}
                    <Button
                      position="absolute"
                      top={2}
                      right={2}
                      variant="ghost"
                      colorScheme="red"
                      size="sm"
                      onClick={removeFile}
                      zIndex={2}
                    >
                      <CloseIcon />
                    </Button>
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    borderWidth="0px"
                    borderRadius="md"
                    p={6}
                    bg="transparent"
                    width={fixedAttachmentType === 'IDENTIFICATION' ? '50%' : 'full'}
                    mx={fixedAttachmentType === 'IDENTIFICATION' ? 'auto' : undefined}
                    position="relative"
                    height={fixedAttachmentType === 'IDENTIFICATION' ? '150px' : undefined}
                    sx={
                      fixedAttachmentType === 'IDENTIFICATION'
                        ? {
                            '&::before, &::after': {
                              display: 'block',
                              content: '""',
                              width: '20px',
                              height: '20px',
                              position: 'absolute',
                              top: '0'
                            },
                            '&::before': {
                              left: '0',
                              borderTop: '2px solid var(--chakra-colors-gray-300)',
                              borderLeft: '2px solid var(--chakra-colors-gray-300)',
                              borderRadius: '4px 0 0 0'
                            },
                            '&::after': {
                              right: '0',
                              borderTop: '2px solid var(--chakra-colors-gray-300)',
                              borderRight: '2px solid var(--chakra-colors-gray-300)',
                              borderRadius: '0 4px 0 0'
                            },
                            '& > span::before, & > span::after': {
                              display: 'block',
                              content: '""',
                              width: '20px',
                              height: '20px',
                              position: 'absolute',
                              bottom: '0'
                            },
                            '& > span::before': {
                              left: '0',
                              borderBottom: '2px solid var(--chakra-colors-gray-300)',
                              borderLeft: '2px solid var(--chakra-colors-gray-300)',
                              borderRadius: '0 0 0 4px'
                            },
                            '& > span::after': {
                              right: '0',
                              borderBottom: '2px solid var(--chakra-colors-gray-300)',
                              borderRight: '2px solid var(--chakra-colors-gray-300)',
                              borderRadius: '0 0 4px 0'
                            }
                          }
                        : undefined
                    }
                  >
                    <Box as="span" position="absolute" inset={0} />
                    <Box
                      display="flex"
                      alignItems="center"
                      maxWidth="calc(100% - 40px)"
                      zIndex={2}
                      bg="gray.100"
                      p={3}
                      borderRadius="md"
                      width="100%"
                    >
                      <Icon
                        as={file.type === 'application/pdf' ? FaFilePdf : FaFile}
                        boxSize={6}
                        mr={3}
                        flexShrink={0}
                      />
                      <Text noOfLines={1} isTruncated>
                        {file.name}
                      </Text>
                    </Box>
                    <Button
                      variant="ghost"
                      colorScheme="red"
                      size="sm"
                      onClick={removeFile}
                      zIndex={2}
                      position="absolute"
                      right={6}
                    >
                      <CloseIcon />
                    </Button>
                  </Box>
                )}
                {fixedAttachmentType === 'IDENTIFICATION' && (
                  <VStack spacing={2} px={4}>
                    <Text fontSize="sm" color="modal.scanText" textAlign="center">
                      Upload an identification document to automatically extract relevant information. The document will
                      be scanned to populate profile data and securely stored as a profile attachment.
                    </Text>
                  </VStack>
                )}
              </>
            ) : (
              <>
                <Box
                  borderWidth={fixedAttachmentType === 'IDENTIFICATION' ? '0px' : '2px'}
                  borderStyle={fixedAttachmentType === 'IDENTIFICATION' ? 'solid' : 'dashed'}
                  borderColor="gray.300"
                  borderRadius="md"
                  bg="transparent"
                  p={6}
                  textAlign="center"
                  width={fixedAttachmentType === 'IDENTIFICATION' ? '40%' : '100%'}
                  minHeight={fixedAttachmentType === 'IDENTIFICATION' ? '200px' : undefined}
                  mx="auto"
                  onDrop={handleFileDrop}
                  onDragOver={(e) => {
                    e.preventDefault();
                  }}
                  onClick={handleSelectFilesClick}
                  cursor="pointer"
                  position="relative"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={
                    fixedAttachmentType === 'IDENTIFICATION'
                      ? {
                          '&::before, &::after': {
                            display: 'block',
                            content: '""',
                            width: '20px',
                            height: '20px',
                            position: 'absolute',
                            top: '0'
                          },
                          '&::before': {
                            left: '0',
                            borderTop: '2px solid var(--chakra-colors-gray-300)',
                            borderLeft: '2px solid var(--chakra-colors-gray-300)',
                            borderRadius: '4px 0 0 0'
                          },
                          '&::after': {
                            right: '0',
                            borderTop: '2px solid var(--chakra-colors-gray-300)',
                            borderRight: '2px solid var(--chakra-colors-gray-300)',
                            borderRadius: '0 4px 0 0'
                          },
                          '& > span::before, & > span::after': {
                            display: 'block',
                            content: '""',
                            width: '20px',
                            height: '20px',
                            position: 'absolute',
                            bottom: '0'
                          },
                          '& > span::before': {
                            left: '0',
                            borderBottom: '2px solid var(--chakra-colors-gray-300)',
                            borderLeft: '2px solid var(--chakra-colors-gray-300)',
                            borderRadius: '0 0 0 4px'
                          },
                          '& > span::after': {
                            right: '0',
                            borderBottom: '2px solid var(--chakra-colors-gray-300)',
                            borderRight: '2px solid var(--chakra-colors-gray-300)',
                            borderRadius: '0 0 4px 0'
                          }
                        }
                      : undefined
                  }
                >
                  <Box as="span" position="absolute" inset={0} />
                  <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                    {fixedAttachmentType === 'IDENTIFICATION' ? (
                      <BiScan size={46} color="#2081C3" />
                    ) : (
                      <BiSolidCloudDownload size={32} color="#2081C3" />
                    )}
                  </Box>
                  {fixedAttachmentType !== 'IDENTIFICATION' && (
                    <>
                      <Text fontSize="lg">Drag and drop files</Text>
                      <Box display="flex" alignItems="center" gap={4}>
                        <Divider flex={1} borderColor="gray.300" />
                        <Text fontSize="lg" color="gray.500">
                          or
                        </Text>
                        <Divider flex={1} borderColor="gray.300" />
                      </Box>
                      <Button bg="button.secondary" border="1px solid" borderColor="button.secondaryBorder">
                        Select file
                      </Button>
                    </>
                  )}
                </Box>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  accept={fixedAttachmentType === 'IDENTIFICATION' ? 'image/*,.pdf' : undefined}
                />
                {fixedAttachmentType === 'IDENTIFICATION' && (
                  <VStack spacing={2} px={4} mt={4}>
                    <Text fontSize="sm" color="modal.scanText" textAlign="center">
                      Upload an identification document to automatically extract relevant information. The document will
                      be scanned to populate profile data and securely stored as a profile attachment.
                    </Text>
                  </VStack>
                )}
              </>
            )}

            {fixedAttachmentType !== 'IDENTIFICATION' && (
              <>
                <FormControl isRequired>
                  <Select
                    placeholder="Select attachment type"
                    onChange={(e) => {
                      setAttachmentType(e.target.value);
                    }}
                    disabled={fixedAttachmentType != null}
                    value={attachmentType}
                  >
                    {attachmentTypes?.map((type) => (
                      <option key={type.value} value={type.value}>
                        {type.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl isRequired>
                  <Input
                    placeholder="Use a descriptive name"
                    onChange={(e) => {
                      setAttachmentName(e.target.value);
                    }}
                  />
                </FormControl>

                <FormControl>
                  <Textarea
                    placeholder="Notes"
                    onChange={(e) => {
                      setAttachmentNotes(e.target.value);
                    }}
                  />
                </FormControl>
              </>
            )}
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button
            bg="button.secondary"
            border="1px solid"
            borderColor="button.secondaryBorder"
            mr={3}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            bg="button.primary"
            onClick={handleUpload}
            isLoading={isLoading}
            isDisabled={
              fileUploading || file == null || (fixedAttachmentType !== 'IDENTIFICATION' && attachmentName === '')
            }
          >
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AttachmentModal;
