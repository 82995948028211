import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import { useState } from 'react';
import { Form } from 'react-final-form';

import { StyledSelect } from '@components/common/StyledSelect';
import { FormField, type ValidatorType } from '@components/FormField';

interface EditableSelectProps {
  label: string;
  value: string | number | Array<string | number>;
  options: Array<{ name: string; value: string | number }>;
  onConfirmChange: (value: string | number | Array<string | number>) => void;
  isDisabled?: boolean;
  allowNull?: boolean;
  validate?: ValidatorType | ValidatorType[];
  isMulti?: boolean;
}

const EditableSelect: React.FC<EditableSelectProps> = ({
  label,
  value,
  options,
  onConfirmChange,
  isDisabled = false,
  allowNull = true,
  validate,
  isMulti = false
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = (): void => {
    setIsEditing(true);
  };

  const handleCancel = (): void => {
    setIsEditing(false);
  };

  const handleSubmit = (values: {
    field: { value: string | number; label: string } | Array<{ value: string | number; label: string }>;
  }): void => {
    if (isMulti) {
      const sortedNewValues = [...(values.field as Array<{ value: string | number }>)].sort((a, b) =>
        JSON.stringify(a.value) > JSON.stringify(b.value) ? 1 : -1
      );
      const sortedOldValues = [...(value as Array<string | number>)].sort((a, b) =>
        JSON.stringify(a) > JSON.stringify(b) ? 1 : -1
      );
      if (JSON.stringify(sortedNewValues.map((v) => v.value)) !== JSON.stringify(sortedOldValues)) {
        onConfirmChange(sortedNewValues.map((v) => v.value));
      }
    } else if (!Array.isArray(values.field) && values.field?.value !== value) {
      onConfirmChange(values.field.value);
    }
    setIsEditing(false);
  };

  const selectOptions = options.map((option) => ({
    value: option.value,
    label: option.name
  }));

  return (
    <Form
      onSubmit={handleSubmit}
      keepDirtyOnReinitialize
      initialValues={{
        field: isMulti
          ? (value as Array<string | number>).map((v) => ({
              value: v,
              label: options.find((o) => o.value === v)?.name
            }))
          : value
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Flex align="center" width="100%">
            <FormField
              name="field"
              label={label}
              direction="horizontal"
              isDisabled={isDisabled || !isEditing}
              maxW={null}
              disableOptionalText
            >
              <StyledSelect
                options={selectOptions}
                isClearable={allowNull}
                placeholder={allowNull ? 'Select an option' : undefined}
                isMulti={isMulti}
              />
            </FormField>
            {isEditing ? (
              <>
                <IconButton ml="1" size="sm" icon={<CheckIcon />} type="submit" aria-label="confirm" />
                <IconButton ml="1" size="sm" icon={<CloseIcon />} onClick={handleCancel} aria-label="cancel" />
              </>
            ) : (
              <Box w={!isDisabled ? 'auto' : '32px'}>
                {!isDisabled && (
                  <IconButton ml="1" size="sm" icon={<EditIcon />} onClick={handleEdit} aria-label="edit" />
                )}
              </Box>
            )}
          </Flex>
        </form>
      )}
    />
  );
};

export default EditableSelect;
