import { Box, Button, Flex, Heading, Icon } from '@chakra-ui/react';
import { ThemeProvider } from '@mui/material/styles';
import { useRef } from 'react';
import { BiExport, BiWallet } from 'react-icons/bi';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import WalletsPanel, { type WalletsPanelRef } from '@features/panels/walletsPanel/WalletsPanel.component';
import { defaultMaterialTheme } from '@utils/consts';

const WalletsPage: React.FC = () => {
  const navigate = useNavigate();
  const walletsPanelRef = useRef<WalletsPanelRef>(null);

  return (
    <>
      <Flex ml={10} mr={10} gap={4} alignItems="center" justifyContent="space-between">
        <Flex gap={4} alignItems="center">
          <Button
            bg="button.secondary"
            border="1px solid"
            borderColor="button.secondaryBorder"
            px={0}
            onClick={() => {
              navigate(-1);
            }}
          >
            <Icon as={IoArrowBack} />
          </Button>
          <Heading as="h3" size="md">
            Wallets
          </Heading>
        </Flex>

        <Flex gap={4} alignItems="center">
          <Button
            leftIcon={<BiExport />}
            aria-label="Export Selected Rows"
            bg="button.secondary"
            border="1px solid"
            borderColor="button.secondaryBorder"
            onClick={() => walletsPanelRef.current?.exportSelectedRows()}
          >
            Export Selected Rows
          </Button>
          <Button
            leftIcon={<BiWallet />}
            aria-label="Register New Wallet"
            bg="button.primary"
            onClick={() => walletsPanelRef.current?.openWalletModal()}
          >
            Register New Wallet
          </Button>
        </Flex>
      </Flex>

      <Box m={4} p={5} boxShadow="md" bg="white" layerStyle="profileOverview.container">
        <ThemeProvider theme={defaultMaterialTheme}>
          <Box overflowX="auto" width="100%">
            <WalletsPanel ref={walletsPanelRef} />
          </Box>
        </ThemeProvider>
      </Box>
    </>
  );
};

export default WalletsPage;
