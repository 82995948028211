import { Box, Flex, Text, Select } from '@chakra-ui/react';
import { ThemeProvider } from '@mui/material';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { useState, useMemo } from 'react';

import { mapScreeningHit } from '@features/panels/alertsPanel/utils/screeningHit';
import { useCustomMaterialTable } from '@features/shared/hooks/useCustomMaterialTable';
import { type WatchlistAlert, type ScreeningHit } from '@models/alert';
import { defaultMaterialTheme } from '@utils/consts';

import MatchingScreeningHit from './MatchingScreeningHit.component';

const TableContent = ({
  entities,
  onResolveMatch
}: {
  entities: ScreeningHit[];
  onResolveMatch: (id: string, resolution: 'TRUE_MATCH' | 'FALSE_POSITIVE', notes: string) => Promise<void>;
}): JSX.Element => {
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const paginatedData = useMemo(() => {
    const start = pagination.pageIndex * pagination.pageSize;
    const end = start + pagination.pageSize;
    return entities.slice(start, end);
  }, [entities, pagination.pageIndex, pagination.pageSize]);

  const columns: Array<MRT_ColumnDef<any>> = [
    {
      accessorFn: (row) => mapScreeningHit(row).entityName ?? '',
      header: 'Entity Name',
      Cell: ({ cell }) => (
        <Box
          sx={{
            maxWidth: '200px',
            whiteSpace: 'normal',
            overflow: 'visible'
          }}
          title={cell.getValue<string>()}
        >
          {cell.getValue<string>()}
        </Box>
      )
    },
    {
      accessorFn: (row) => mapScreeningHit(row).sourceList ?? '',
      header: 'Source List',
      Cell: ({ cell }) => (
        <Box
          sx={{
            maxWidth: '150px',
            whiteSpace: 'normal',
            overflow: 'visible'
          }}
          title={cell.getValue<string>()}
        >
          {cell.getValue<string>()}
        </Box>
      )
    },
    {
      accessorFn: (row) => mapScreeningHit(row).category ?? '',
      header: 'Category'
    },
    {
      header: 'Score',
      Cell: ({ row }) => <Box textAlign="center">{mapScreeningHit(row.original).score ?? 0}</Box>
    }
  ];

  const { table } = useCustomMaterialTable({
    columns,
    data: paginatedData,
    rowCount: entities.length,
    isLoading: false,
    isFetching: false,
    pagination,
    setPagination,
    enableExpanding: true,
    getRowId: (row) => row.id,
    renderDetailPanel: ({ row }) => {
      return (
        <MatchingScreeningHit
          screeningHit={row.original}
          onResolve={async (id, resolution, notes) => {
            try {
              await onResolveMatch(id as unknown as string, resolution as 'TRUE_MATCH' | 'FALSE_POSITIVE', notes);
              row.toggleExpanded();
            } catch (error) {
              console.error(error);
            }
          }}
        />
      );
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor:
          row.getIsExpanded() === true
            ? '#F7F7F7'
            : row.original.resolution === 'TRUE_MATCH'
              ? 'rgba(255, 0, 0, 0.1)'
              : row.original.resolution === 'FALSE_POSITIVE'
                ? 'rgba(190, 217, 3, 0.1)'
                : 'inherit',
        transition: 'all 0.2s ease-in-out',
        '& td': { transition: 'all 0.2s ease-in-out' }
      }
    }),
    state: { pagination },
    enablePagination: true
  });

  return <MaterialReactTable table={table} />;
};

const AlertDetailScreeningHits = ({
  alert,
  onResolveMatch
}: {
  alert: WatchlistAlert;
  onResolveMatch: (id: string, resolution: 'TRUE_MATCH' | 'FALSE_POSITIVE', notes: string) => Promise<void>;
}): JSX.Element => {
  const [filter, setFilter] = useState<'all' | 'unresolved' | 'resolved'>('all');

  const filteredEntities = useMemo(() => {
    return (alert.profileInquiries ?? []).filter((inquiry) => {
      if (filter === 'all') return true;
      if (filter === 'unresolved') return inquiry.resolution === 'NO_RESOLUTION';
      if (filter === 'resolved') return inquiry.resolution !== 'NO_RESOLUTION';
      return true;
    });
  }, [alert.profileInquiries, filter]);

  return (
    <Box flexDirection="column" bg="white" mb={4} px={0} layerStyle="profileOverview.container">
      <Flex justifyContent="space-between" alignItems="center" mb={2} px={5}>
        <Text fontWeight={800}>Matching Entities</Text>
        <Select
          width="200px"
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value as any);
          }}
        >
          <option value="all">All Hits</option>
          <option value="unresolved">Unresolved Hits</option>
          <option value="resolved">Resolved Hits</option>
        </Select>
      </Flex>
      <ThemeProvider theme={defaultMaterialTheme}>
        <TableContent entities={filteredEntities} onResolveMatch={onResolveMatch} />
      </ThemeProvider>
    </Box>
  );
};

export default AlertDetailScreeningHits;
