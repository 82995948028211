import { Box, FormErrorMessage as ChakraFormErrorMessage } from '@chakra-ui/react';
import isNil from 'lodash/isNil';

export const RequiredIndicator: React.FC = () => (
  <Box as="span" color="red.500">
    *
  </Box>
);

export const isEmptyOrNil = (value: any): boolean => {
  return isNil(value) || value === '';
};

export const fieldIsInvalid = (meta: any): boolean => {
  return meta.error != null && meta.touched;
};

export const FormErrorMessage: React.FC<{ meta: any }> = ({ meta }) => {
  return <>{meta.touched === true && <ChakraFormErrorMessage>{meta.error}</ChakraFormErrorMessage>}</>;
};
