import { Box, Heading, Button, useToast, Flex, Divider } from '@chakra-ui/react';
import * as Sentry from '@sentry/browser';
import { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const NotesDisplay: React.FC<{
  notes: string;
  walletId: string;
  orgId: string;
  updateWallet?: any;
  updateWalletIsLoading?: boolean;
}> = ({ notes, walletId, orgId, updateWallet, updateWalletIsLoading }) => {
  const [quillValue, setQuillValue] = useState(notes);
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const toast = useToast();

  useEffect(() => {
    setQuillValue(notes);
  }, [notes]);

  const handleClear = async (): Promise<void> => {
    try {
      await updateWallet({
        orgId,
        walletId,
        notes: ''
      }).unwrap();
    } catch (err) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Oh no, there was an error!',
        isClosable: true
      });
      Sentry.captureException(err);
    }
  };

  const handleSave = async (): Promise<void> => {
    try {
      await updateWallet({
        orgId,
        walletId,
        notes: quillValue
      }).unwrap();
      setIsEditingNotes(false);
    } catch (err) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Oh no, there was an error!',
        isClosable: true
      });
      Sentry.captureException(err);
    }
  };

  return (
    <Box pl={5} pr={5} pt={4} pb={4} bg="white" layerStyle="profileOverview.container">
      <Heading size="sm" mb={2}>
        Notes
      </Heading>
      <Box
        className="quill-container"
        width="100%"
        sx={{
          '.ql-container, .ql-editor': {
            minHeight: '100px',
            border: 'none !important'
          },
          '.quill': {
            width: '100%'
          },
          '.ql-container': {
            backgroundColor: '#F7F7F7',
            borderRadius: isEditingNotes ? '0 0 md md' : 'md'
          },
          '.ql-toolbar': {
            display: isEditingNotes ? 'block' : 'none',
            border: 'none !important',
            backgroundColor: '#F7F7F7',
            borderRadius: 'md md 0 0'
          }
        }}
      >
        {!isEditingNotes ? (
          <ReactQuill
            key={`read-${isEditingNotes}`}
            theme="snow"
            readOnly={true}
            placeholder="No notes added"
            modules={{ toolbar: false }}
            value={notes ?? ''}
          />
        ) : (
          <ReactQuill
            key={`edit-${isEditingNotes}`}
            theme="snow"
            value={quillValue}
            onChange={setQuillValue}
            placeholder="Enter notes here"
          />
        )}
      </Box>
      <Divider mt={4} mb={4} />
      <Flex justifyContent="stretch" width="100%" gap={2}>
        {typeof updateWallet === 'function' && (
          <>
            {!isEditingNotes ? (
              <>
                <Button
                  size="sm"
                  onClick={handleClear}
                  isDisabled={updateWalletIsLoading ?? notes === ''}
                  flex={1}
                  bg="button.secondary"
                  border="1px solid"
                  borderColor="button.secondaryBorder"
                >
                  Clear
                </Button>
                <Button
                  size="sm"
                  onClick={() => {
                    setIsEditingNotes(true);
                  }}
                  flex={1}
                  bg="button.primary"
                >
                  {notes != null ? 'Edit Notes' : 'Add Notes'}
                </Button>
              </>
            ) : (
              <>
                <Button
                  size="sm"
                  onClick={() => {
                    setIsEditingNotes(false);
                    setQuillValue(notes);
                  }}
                  flex={1}
                  bg="button.secondary"
                  border="1px solid"
                  borderColor="button.secondaryBorder"
                >
                  Cancel
                </Button>
                <Button size="sm" isLoading={updateWalletIsLoading} onClick={handleSave} flex={1} bg="button.primary">
                  Save
                </Button>
              </>
            )}
          </>
        )}
      </Flex>
    </Box>
  );
};

export default NotesDisplay;
