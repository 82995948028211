import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import { Box, Flex, IconButton, Input } from '@chakra-ui/react';
import { parseISO } from 'date-fns';
import dayjs from 'dayjs';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Form } from 'react-final-form';

import { FormField, type ValidatorType } from '@components/FormField';

interface EditableDateProps {
  label: string;
  value: Date | string | null;
  onConfirmChange: (value: string) => void;
  isDisabled?: boolean;
  validate?: ValidatorType | ValidatorType[];
}

const EditableDate: React.FC<EditableDateProps> = ({ label, value, onConfirmChange, isDisabled = false, validate }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = (): void => {
    setIsEditing(true);
  };

  const handleCancel = (): void => {
    setIsEditing(false);
  };

  const handleSubmit = (values: { field: string }): void => {
    if (values.field !== value) {
      onConfirmChange(values.field);
    }
    setIsEditing(false);
  };

  const initialDate = value === null ? null : value instanceof Date ? value : parseISO(value);

  return (
    <Form
      key={isEditing ? 'editing' : 'not-editing'}
      onSubmit={handleSubmit}
      initialValues={{ field: initialDate }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Flex align="center">
            <FormField
              name="field"
              label={label}
              direction="horizontal"
              isDisabled={isDisabled || !isEditing}
              maxW={null}
              disableOptionalText
            >
              {({ input }) => (
                <DatePicker
                  {...input}
                  selected={input.value != null && input.value !== '' ? dayjs(input.value).toDate() : null}
                  onChange={(date) => {
                    input.onChange(date != null ? dayjs(date).format('YYYY-MM-DD') : null);
                  }}
                  dateFormat="yyyy-MM-dd"
                  autoComplete="off"
                  customInput={<Input size="sm" opacity="1 !important" />}
                />
              )}
            </FormField>
            {isEditing ? (
              <>
                <IconButton ml="1" size="sm" icon={<CheckIcon />} type="submit" aria-label="confirm" />
                <IconButton ml="1" size="sm" icon={<CloseIcon />} onClick={handleCancel} aria-label="cancel" />
              </>
            ) : (
              <Box w={!isDisabled ? 'auto' : '32px'}>
                {!isDisabled && (
                  <IconButton ml="1" size="sm" icon={<EditIcon />} onClick={handleEdit} aria-label="edit" />
                )}
              </Box>
            )}
          </Flex>
        </form>
      )}
    />
  );
};

export default EditableDate;
