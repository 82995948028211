import { Box, Button, Divider, Flex, Heading, Icon, VStack, useToast } from '@chakra-ui/react';
import { ThemeProvider } from '@mui/material';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { useState } from 'react';
import { IoArrowBack } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import MatchingScreeningHit from '@features/panels/alertsPanel/components/MatchingScreeningHit.component';
import { mapScreeningHit } from '@features/panels/alertsPanel/utils/screeningHit';
import { Loading } from '@features/shared/components';
import { useCustomMaterialTable } from '@features/shared/hooks/useCustomMaterialTable';
import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { PROFILE_TYPE, type IProfile } from '@models/profileTypes';
import {
  useGetProfileByIdQuery,
  useGetProfileInquiriesQuery,
  useUpdateProfileInquiryMutation
} from '@services/canaria.services';
import { defaultMaterialTheme } from '@utils/consts';

import DisplayField from './DisplayField';

export const ScreenedDataContainer: React.FC = () => {
  const { profileId } = useParams();
  const orgId = useSelector(selectActiveOrgID);

  if (orgId == null) {
    throw new Error('activeOrgID is null');
  }

  if (profileId == null) {
    throw new Error('profileId is undefined');
  }

  const { data: profileData, isLoading: isProfileLoading } = useGetProfileByIdQuery({
    orgId,
    profileId
  });

  if (isProfileLoading || profileData == null) {
    return <Loading />;
  }

  return <ScreenedData profile={profileData} />;
};

const UNRESOLVED_STATUS = 'NO_RESOLUTION';

const ScreenedData: React.FC<{ profile: IProfile }> = ({ profile }) => {
  return (
    <VStack spacing={4} align="stretch" m={4} p={10} boxShadow="md" bg="white" layerStyle="profileOverview.container">
      <Heading as="h1" size="md" textAlign="left" mb={5}>
        Screened Data
      </Heading>
      <Box w="50%">
        <DisplayField label="Profile Name" value={profile.fullLegalName} />
        <Divider my={2} />
        {profile.resourcetype === PROFILE_TYPE.INDIVIDUAL ? (
          <>
            <DisplayField label="Gender" value={profile.gender?.name} />
            <Divider my={2} />
            <DisplayField label="Date of Birth" value={profile.dateOfBirth} />
            <Divider my={2} />
            <DisplayField label="Citizenship" value={profile.citizenship?.englishName} />
            <Divider my={2} />
            <DisplayField label="Place of Birth" value={profile.placeOfBirth?.englishName} />
            <Divider my={2} />
            <DisplayField label="Id Number" value={profile.idNumber} />
            <Divider my={2} />
            <DisplayField label="Id Type" value={profile.idType?.name} />
            <Divider my={2} />
            <DisplayField label="Id Issuer" value={profile.idIssuer?.englishName} />
          </>
        ) : (
          <>
            <DisplayField label="DBA" value={profile.dbaTradeName} />
            <Divider my={2} />
            <DisplayField label="Country of Incorporation" value={profile.placeOfEstablishment?.englishName} />
            <Divider my={2} />
            <DisplayField label="Date of Incorporation" value={profile.dateOfEstablishment} />
            <Divider my={2} />
            <DisplayField label="Business Address" value={profile.registeredBusinessAddress} />
            <Divider my={2} />
            <DisplayField label="Physical Address" value={profile.physicalAddress} />
            <Divider my={2} />
            <DisplayField label="Mailing Address" value={profile.mailingAddress} />
          </>
        )}
      </Box>
    </VStack>
  );
};

const TableContent = ({
  entities,
  onResolveMatch
}: {
  entities: any[];
  onResolveMatch: (id: string, resolution: 'TRUE_MATCH' | 'FALSE_POSITIVE', notes: string) => Promise<void>;
}): JSX.Element => {
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const toast = useToast();

  const columns: Array<MRT_ColumnDef<any>> = [
    {
      accessorFn: (row) => mapScreeningHit(row).entityName ?? '',
      header: 'Entity Name',
      Cell: ({ cell }) => (
        <Box
          sx={{
            maxWidth: '200px',
            whiteSpace: 'normal',
            overflow: 'visible'
          }}
          title={cell.getValue<string>()}
        >
          {cell.getValue<string>()}
        </Box>
      )
    },
    {
      accessorFn: (row) => mapScreeningHit(row).sourceList ?? '',
      header: 'Source List',
      Cell: ({ cell }) => (
        <Box
          sx={{
            maxWidth: '150px',
            whiteSpace: 'normal',
            overflow: 'visible'
          }}
          title={cell.getValue<string>()}
        >
          {cell.getValue<string>()}
        </Box>
      )
    },
    {
      accessorFn: (row) => mapScreeningHit(row).category ?? '',
      header: 'Category'
    },
    {
      header: 'Score',
      Cell: ({ row }) => <Box textAlign="center">{mapScreeningHit(row.original).score ?? 0}</Box>
    }
  ];

  const { table } = useCustomMaterialTable({
    columns,
    data: entities,
    rowCount: entities.length,
    isLoading: false,
    isFetching: false,
    enableExpanding: true,
    getRowId: (row) => row.id,
    renderDetailPanel: ({ row }) => (
      <MatchingScreeningHit
        screeningHit={row.original}
        onResolve={async (id: number, resolution: string, notes: string) => {
          try {
            await onResolveMatch(id.toString(), resolution as 'TRUE_MATCH' | 'FALSE_POSITIVE', notes);
            row.toggleExpanded();
            toast({
              status: 'success',
              title: 'Success',
              description: `Match resolved as ${resolution === 'TRUE_MATCH' ? 'True Match' : 'False Positive'}`,
              isClosable: true
            });
          } catch (error) {
            toast({
              status: 'error',
              title: 'Error',
              description: 'Failed to resolve match',
              isClosable: true
            });
          }
        }}
      />
    ),
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor:
          row.getIsExpanded() === true
            ? '#F7F7F7'
            : row.original.resolution === 'TRUE_MATCH'
              ? 'rgba(255, 0, 0, 0.1)'
              : row.original.resolution === 'FALSE_POSITIVE'
                ? 'rgba(190, 217, 3, 0.1)'
                : 'inherit',
        transition: 'all 0.2s ease-in-out',
        '& td': { transition: 'all 0.2s ease-in-out' }
      }
    }),
    state: { pagination },
    enablePagination: true,
    pagination,
    setPagination
  });

  return <MaterialReactTable table={table} />;
};

const InquiriesList: React.FC<{
  profileId: string;
  title: string;
  inquiries: any[];
}> = ({ profileId, title, inquiries }) => {
  const activeOrgID = useSelector(selectActiveOrgID);
  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }

  const toast = useToast();
  const [updateProfileInquiry] = useUpdateProfileInquiryMutation();

  const onResolveHit = async (
    inquiryId: string,
    resolution: 'TRUE_MATCH' | 'FALSE_POSITIVE',
    notes: string
  ): Promise<void> => {
    try {
      await updateProfileInquiry({
        orgId: activeOrgID,
        profileId,
        inquiryId,
        resolution,
        notes
      }).unwrap();
      toast({
        status: 'success',
        title: 'Success',
        description: `Match resolved as ${resolution === 'TRUE_MATCH' ? 'True Match' : 'False Positive'}`,
        isClosable: true
      });
    } catch (err) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Failed to resolve match',
        isClosable: true
      });
    }
  };

  return (
    <VStack align="stretch" m={4} bg="white" layerStyle="profileOverview.container" py={6}>
      <Heading as="h1" size="md" textAlign="left" mb={5}>
        {title}
      </Heading>
      <ThemeProvider theme={defaultMaterialTheme}>
        <TableContent entities={inquiries} onResolveMatch={onResolveHit} />
      </ThemeProvider>
    </VStack>
  );
};

const WatchlistScreening: React.FC = () => {
  const navigate = useNavigate();
  const { profileId } = useParams();
  const orgId = useSelector(selectActiveOrgID);

  if (orgId == null) {
    throw new Error('activeOrgID is null');
  }

  if (profileId == null) {
    throw new Error('profileId is undefined');
  }

  const { data: inquiriesData, isLoading: isInquieriesLoading } = useGetProfileInquiriesQuery({
    orgId,
    profileId
  });

  const inquiries = inquiriesData?.results ?? [];

  if (isInquieriesLoading) {
    return <Loading message="Loading profile inquiries..." />;
  }

  return (
    <>
      <Flex ml={10} gap={4} alignItems="center">
        <Button
          bg="button.secondary"
          border="1px solid"
          borderColor="button.secondaryBorder"
          px={0}
          onClick={() => {
            navigate(-1);
          }}
        >
          <Icon as={IoArrowBack} />
        </Button>
        <Heading as="h3" size="md">
          Watchlist Screening
        </Heading>
      </Flex>

      <Flex gap={4}>
        <Box flex={1}>
          <ScreenedDataContainer />
        </Box>
        <Box flex={2}>
          <InquiriesList
            profileId={profileId}
            title="New Hits Needing Attention"
            inquiries={inquiries?.filter((inquiry) => inquiry.resolution === UNRESOLVED_STATUS)}
          />
        </Box>
      </Flex>

      <InquiriesList
        profileId={profileId}
        title="Previously Resolved Hits"
        inquiries={inquiries?.filter((inquiry) => inquiry.resolution !== UNRESOLVED_STATUS)}
      />
    </>
  );
};

export default WatchlistScreening;
