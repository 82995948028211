import {
  Box,
  Flex,
  Text,
  VStack,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Button,
  Textarea,
  useToast,
  ChakraProvider
} from '@chakra-ui/react';
import { useState } from 'react';

import { mapScreeningHit } from '@features/panels/alertsPanel/utils/screeningHit';
import { type ScreeningHit } from '@models/alert';
import { chakraTheme } from '@utils/consts';

const LineField: React.FC<{
  label: string;
  value: string | undefined | null;
  hideIfEmpty?: boolean;
  isLast?: boolean;
}> = ({ label, value, hideIfEmpty = false, isLast = false }): JSX.Element | null => {
  if (value === null || value === undefined) {
    return null;
  }
  if (hideIfEmpty && value === '') {
    return null;
  }
  return (
    <Box flex="1 0 21%" borderRight={!isLast ? '1px solid' : 'none'} borderColor="gray.200" px={4}>
      <Flex direction="column" justify="flex-start" height="100%">
        <Text fontSize="xs" color="gray.500" mb={1}>
          {label}
        </Text>
        <Text pb={1} minHeight="3em" whiteSpace="normal" wordBreak="break-word">
          {value}
        </Text>
      </Flex>
    </Box>
  );
};

const ListField: React.FC<{
  label: string;
  items: Array<string | undefined>;
  hideIfEmpty?: boolean;
  isHorizontal?: boolean;
}> = ({ label, items, hideIfEmpty = false, isHorizontal = false }): JSX.Element | null => {
  if (hideIfEmpty && items.length === 0) {
    return null;
  }

  if (isHorizontal) {
    const validItems = items.filter((item): item is string => item !== undefined);
    return (
      <Box width="100%">
        <Flex direction="row" justify="space-between" width="100%" gap={4}>
          <Text color="black" fontSize="md" flex="0 0 auto">
            {label}:
          </Text>
          <Text flex="1" whiteSpace="normal" wordBreak="break-word" textAlign="right" maxWidth="50%">
            {validItems.join(', ')}
          </Text>
        </Flex>
      </Box>
    );
  }

  return (
    <Box width="auto">
      {items.map(
        (item, index) =>
          item !== undefined && (
            <Flex key={index} direction="row" justify="flex-start" gap={2}>
              {index === 0 && (
                <Text fontSize="xs" color="gray.500" flex="0 0 auto" width="100px">
                  {label}:
                </Text>
              )}
              <Text flex="1" whiteSpace="normal" wordBreak="break-word">
                {item}
              </Text>
            </Flex>
          )
      )}
    </Box>
  );
};

const MatchingScreeningHit = ({
  screeningHit,
  onResolve
}: {
  screeningHit: ScreeningHit;
  onResolve: (id: number, resolution: string, notes: string) => void;
}): JSX.Element => {
  const mappedHit = mapScreeningHit(screeningHit);
  const toast = useToast();
  const [resolution, setResolution] = useState<'TRUE_MATCH' | 'FALSE_POSITIVE' | 'NO_RESOLUTION'>(
    screeningHit.resolution as 'TRUE_MATCH' | 'FALSE_POSITIVE' | 'NO_RESOLUTION'
  );
  const [notes, setNotes] = useState(screeningHit.notes);
  const isResolved = screeningHit.resolution !== 'NO_RESOLUTION';

  return (
    <ChakraProvider theme={chakraTheme}>
      <Flex p={4} bg="transparent">
        <VStack align="stretch" flex={1} spacing={4}>
          <VStack align="stretch" spacing={4} width="100%" layerStyle="profileOverview.container" bg="white">
            <Grid templateColumns="repeat(3, 1fr)" gap={0} width="100%">
              <LineField label="First Name" value={mappedHit.additionalFields.firstName} />
              <LineField label="Middle Name" value={mappedHit.additionalFields.middleName} hideIfEmpty={true} />
              <LineField label="Last Name" value={mappedHit.additionalFields.lastName} isLast />
            </Grid>
            <Box borderBottom="1px solid" borderColor="gray.200" width="100%" />
            <Grid
              templateColumns="repeat(3, 1fr)"
              gap={0}
              width="100%"
              sx={{
                '& > *': {
                  borderRight: '1px solid',
                  borderColor: 'gray.200',
                  px: 4,
                  '&:last-child': {
                    borderRight: 'none'
                  }
                }
              }}
            >
              <LineField label="Occupation" value={screeningHit.result?.listEntry?.catchAll?.Occupation?.[0]} />
              <LineField label="Entity Type" value={mappedHit.entityType} />
              <LineField
                label="List Names"
                value={screeningHit.result?.listEntry?.listSource?.lists?.map((list) => list.name).join('; ')}
              />
            </Grid>
          </VStack>

          <VStack align="stretch" spacing={4} width="100%" layerStyle="profileOverview.container" bg="white">
            <VStack align="stretch" spacing={4} divider={<Box borderBottom="1px solid" borderColor="gray.200" />}>
              <ListField label="Aliases" items={mappedHit.additionalFields.aliases} isHorizontal />
              <ListField label="Country" items={mappedHit.additionalFields.country} hideIfEmpty={true} isHorizontal />
              <ListField label="Citizenships" items={mappedHit.additionalFields.citizenships} isHorizontal />
              <ListField
                label="Addresses"
                items={mappedHit.additionalFields.addresses}
                hideIfEmpty={true}
                isHorizontal
              />
              <ListField label="Dates of Birth" items={mappedHit.additionalFields.birthDates} isHorizontal />
              <ListField label="IDs" items={mappedHit.additionalFields.ids} isHorizontal />
              <ListField
                label="Registration Number"
                items={mappedHit.additionalFields.registrationNumber}
                isHorizontal
              />
              <ListField label="Gender" items={mappedHit.additionalFields.gender} hideIfEmpty={true} isHorizontal />
              {Object.entries(screeningHit.result?.listEntry?.catchAll ?? {})
                .filter(([key]) => key !== 'Occupation')
                .map(([key, value]: [string, any]) => (
                  <ListField
                    key={key}
                    label={key}
                    items={[
                      Array.isArray(value)
                        ? value?.join('; ')
                        : value !== null && value !== undefined
                          ? String(value)
                          : undefined
                    ]}
                    isHorizontal
                  />
                ))}
            </VStack>
          </VStack>

          <VStack align="stretch" spacing={4} width="100%" layerStyle="profileOverview.container" bg="white">
            <VStack align="stretch" spacing={4}>
              <Text>Resolution Comments</Text>
              <Textarea
                value={notes}
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
                placeholder="Enter notes here"
                size="sm"
                isDisabled={isResolved}
                minHeight="100px"
                borderRadius="xl"
                p={4}
              />
              <RadioGroup
                onChange={(value) => {
                  setResolution(value as 'TRUE_MATCH' | 'FALSE_POSITIVE');
                }}
                value={resolution}
                isDisabled={isResolved}
              >
                <Stack direction="row" divider={<Box borderLeft="1px solid" borderColor="gray.200" mx={4} />}>
                  <Radio value="TRUE_MATCH">True Match</Radio>
                  <Radio value="FALSE_POSITIVE">False Positive</Radio>
                </Stack>
              </RadioGroup>

              <Button
                mt={2}
                size="md"
                borderRadius="xl"
                py={2}
                onClick={() => {
                  if (resolution === 'NO_RESOLUTION' || notes.trim() === '') {
                    toast({
                      status: 'error',
                      title: 'Error',
                      description: 'Please select a resolution and add notes to resolve the match!',
                      isClosable: true
                    });
                    return;
                  }
                  onResolve(screeningHit.id, resolution, notes);
                }}
                isDisabled={isResolved || resolution === 'NO_RESOLUTION' || notes.trim() === ''}
                bg="button.primary"
                fontWeight={600}
              >
                Resolve Match
              </Button>
              {isResolved && (
                <Text fontSize="sm" color="gray.500" mt={2}>
                  This match has been resolved
                </Text>
              )}
            </VStack>
          </VStack>
        </VStack>
      </Flex>
    </ChakraProvider>
  );
};

export default MatchingScreeningHit;
