import { Box, Flex, Heading, Text } from '@chakra-ui/react';

import Header from '@features/header/Header.component';
import { Footer } from '@features/shared/components';

const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <Header />
      <Flex justify="center" py={4} px={72}>
        <Box p={4}>
          <Heading as="h1" size="xl" mb={4}>
            Privacy Policy
          </Heading>
          <Text fontSize="sm" color="gray.500" mb={4}>
            Last updated: October 15, 2024
          </Text>
          <Text mb={4}>
            Canaria Consulting, LLC (“Canaria,” “we,” “our,” or “us”) is committed to protecting the confidentiality and
            privacy of the personal information we obtain through our compliance products and related services,
            including our websites, web-based applications, and other activities (collectively, “Activities”) that link
            to, post, or otherwise refer to this Privacy Policy. This policy explains our practices regarding the
            collection, use, disclosure, and protection of personal information. By engaging in such Activities, you
            acknowledge our personal information practices.
          </Text>
          <Heading as="h2" size="md" mb={2}>
            1. WHAT WE COLLECT
          </Heading>
          <Text mb={4}>
            1.1 <strong>Personal Information You Provide</strong>
            <br />
            We collect personal information you voluntarily provide when engaging in our Activities, such as your name,
            email address, phone number, digital asset wallet address, and other relevant contact or demographic
            information. This information is necessary to provide our services and ensure compliance with applicable
            regulations.
          </Text>
          <Text mb={4}>
            1.1.1 <u>Sensitive Personal Information</u>
            <br />
            We only collect sensitive personal information when you voluntarily provide it. This may include data
            regarding race, ethnicity, political beliefs, trade union membership, religious beliefs, physical or mental
            health, biometrics, precise geolocation, sexual orientation, or criminal records. Please provide such
            information only if you consent to its use for our business purposes.
          </Text>
          <Text mb={4}>
            1.2 <strong>Information We Gather Automatically</strong>
            <br />
            We and our third-party providers may collect personal information through tracking technologies such as
            cookies, web beacons, and blockchain tracking tools when you engage in our Activities. This includes
            information like IP addresses, browser types, device information, and interaction data.
          </Text>
          <Text mb={4}>
            1.3 <strong>Children</strong>
            <br />
            Our Activities are not intended for children under 18, and we do not knowingly collect personal information
            from individuals under 18.
          </Text>

          <Heading as="h2" size="md" mb={2}>
            2. HOW WE USE PERSONAL INFORMATION
          </Heading>
          <Text mb={4}>
            We use your personal information to:
            <br />
            - Provide and improve our compliance products and services
            <br />
            - Customize and enhance your online experience
            <br />
            - Advertise and market our Activities
            <br />
            - Analyze user engagement and measure the effectiveness of our Activities
            <br />- Comply with legal and regulatory obligations, including GDPR and other data protection laws
          </Text>

          <Heading as="h2" size="md" mb={2}>
            3. DISCLOSURE OF PERSONAL INFORMATION
          </Heading>
          <Text mb={4}>
            3.1 <strong>Business Purposes</strong>
            <br />
            We may disclose personal information to comply with legal obligations, protect our rights, enforce our
            terms, or in connection with a merger or acquisition. Our legitimate interests include improving our
            services, securing prompt payments, and preventing fraud.
          </Text>
          <Text mb={4}>
            3.2 <strong>Sale or Sharing of Personal Information</strong>
            <br />
            We do not sell your personal information to third parties. We may share information with service providers
            who perform functions on our behalf, ensuring they safeguard your data.
          </Text>
          <Text mb={4}>
            3.3 <strong>Legal Reasons</strong>
            <br />
            We may disclose personal information to comply with legal processes, protect our rights, or prevent fraud
            and other illegal activities.
          </Text>
          <Text mb={4}>
            3.4 <strong>Corporate Transactions</strong>
            <br />
            In events like mergers or acquisitions, personal information may be transferred to the acquiring entity
            under similar privacy protections.
          </Text>
          <Text mb={4}>
            3.5 <strong>Social Media</strong>
            <br />
            Be cautious when sharing personal information on social media. We are not responsible for content on
            third-party platforms.
          </Text>
          <Text mb={4}>
            3.6 <strong>Cross-Border Collection and Transfer</strong>
            <br />
            Personal information may be transferred to and stored in jurisdictions outside your home country, including
            the EU, under appropriate safeguards to comply with data protection laws like GDPR.
          </Text>

          <Heading as="h2" size="md" mb={2}>
            4. CHOICES
          </Heading>
          <Text mb={4}>
            You can manage your personal information by:
            <br />
            - Opting in or out of specific data uses
            <br />
            - Requesting access, correction, or deletion of your personal information
            <br />
            - Modifying your consent preferences
            <br />
            To make a data privacy request, contact us at support@canariaconsulting.com.
          </Text>

          <Heading as="h2" size="md" mb={2}>
            5. DATA SECURITY
          </Heading>
          <Text mb={4}>
            We implement technical, administrative, and physical measures to protect your personal information from
            unauthorized access, use, or disclosure. While we strive to secure your data, no security measures are
            foolproof.
          </Text>

          <Heading as="h2" size="md" mb={2}>
            6. DATA PROTECTION RIGHTS
          </Heading>
          <Text mb={4}>
            6.1 <strong>GDPR Compliance</strong>
            <br />
            If you are located in the European Economic Area (EEA), you have the following rights under the GDPR: (see
            full rights list in the document)
          </Text>
          <Text mb={4}>
            6.1.1 <u>Lawful Bases for Processing</u>
            <br />
            We process your personal data based on the following lawful bases:
            <br />
            - Consent
            <br />
            - Contractual Necessity
            <br />
            - Legal Obligation
            <br />- Legitimate Interests
          </Text>

          <Text mb={4}>
            6.2 <strong>California Residents</strong>
            <br />
            The California Consumer Privacy Act, as amended, and its regulations, (“CCPA”), grants certain rights to
            California residents with regard to their personal information. (see full rights list in the document)
          </Text>

          <Text mb={4}>
            6.3 <strong>Non-U.S. Residents</strong>
            <br />
            Canaria provides our Activities primarily in the U.S. and does not intentionally direct such activities to
            individuals located outside of the U.S. (see full details in the document)
          </Text>

          <Heading as="h2" size="md" mb={2}>
            7. LINKS TO OTHER SITES
          </Heading>
          <Text mb={4}>
            Our Activities may contain links to other websites or services. We are not responsible for the privacy
            practices of these external sites and encourage you to review their privacy policies.
          </Text>

          <Heading as="h2" size="md" mb={2}>
            8. COOKIE POLICY
          </Heading>
          <Text mb={4}>
            We use cookies to enhance your experience on our websites and applications. By using our Activities, you
            consent to our use of cookies as described in this policy.
          </Text>

          <Heading as="h2" size="md" mb={2}>
            9. CHANGES TO THIS PRIVACY STATEMENT
          </Heading>
          <Text mb={4}>
            We may update this Privacy Policy periodically. The &quot;Last update&quot; date at the top will reflect any
            changes. Continued use of our Activities signifies your acceptance of the updated terms.
          </Text>

          <Heading as="h2" size="md" mb={2}>
            10. QUESTIONS AND COMMENTS
          </Heading>
          <Text mb={4}>
            For any questions or concerns about this Privacy Policy or our data practices, please contact Canaria’s
            Legal Department at support@canariaconsulting.com.
          </Text>
        </Box>
      </Flex>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
