import { Box, Paper, ThemeProvider } from '@mui/material';
import dayjs from 'dayjs';
import { MaterialReactTable, type MRT_ColumnDef, type MRT_RowData } from 'material-react-table';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { useCustomMaterialTable } from '@features/shared/hooks/useCustomMaterialTable';
import { PROFILE_STATUS_DESCRIPTION, PROFILE_TYPE } from '@models/profileTypes';
import { useGetProfilesQuery } from '@services/canaria.services';
import { defaultMaterialTheme, statusStyles } from '@utils/consts';

interface ProfileTableRow extends MRT_RowData {
  id: string;
  name: string;
  profileType: string;
  profileGroup: {
    name: string;
  };
  resourcetype: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

export const StatusBadge: React.FC<{ status: string }> = ({ status }) => {
  const style = statusStyles[status] ?? {
    color: '#7C7E7E',
    background: '#7C7E7E33',
    borderColor: '#7C7E7E'
  };

  return (
    <Box
      sx={{
        display: 'inline-block',
        padding: '2px 4px',
        borderRadius: '4px',
        border: '1px solid',
        fontSize: '0.875rem',
        ...style
      }}
    >
      {PROFILE_STATUS_DESCRIPTION[status] ?? status}
    </Box>
  );
};

const columns: Array<MRT_ColumnDef<ProfileTableRow>> = [
  {
    id: 'name',
    accessorKey: 'name',
    header: 'Name',
    Cell: ({ row }) => {
      return <Link to={`/dashboard/profiles/${row.original.id}`}>{row.original.name}</Link>;
    }
  },
  {
    id: 'profile_type',
    accessorKey: 'profileType',
    header: 'Type',
    Cell: ({ row }) => {
      return row.original.resourcetype === PROFILE_TYPE.INDIVIDUAL ? 'Individual' : 'Organization';
    }
  },
  {
    id: 'profile_group',
    accessorKey: 'profileGroupName',
    header: 'Group'
  },
  {
    id: 'created_at',
    accessorKey: 'createdAt',
    header: 'Created',
    Cell: ({ row }) => {
      return dayjs(row.original.createdAt).format('YYYY-MM-DD hh:mm:ss A');
    }
  },
  {
    id: 'updated_at',
    accessorKey: 'updatedAt',
    header: 'Last Modified',
    Cell: ({ row }) => {
      return dayjs(row.original.updatedAt).format('YYYY-MM-DD hh:mm:ss A');
    }
  },
  {
    id: 'status',
    accessorKey: 'status',
    header: 'Status',
    Cell: ({ row }) => {
      return <StatusBadge status={row.original.status} />;
    }
  }
];

const TableContent: React.FC<{ orgId: string }> = ({ orgId }) => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<Array<{ id: string; desc: boolean }>>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100
  });

  const { data, isLoading, isFetching } = useGetProfilesQuery({
    orgId,
    query: {
      page_size: pagination.pageSize,
      page: pagination.pageIndex + 1,
      ordering: sorting
        .map((s) => `${s.desc ? '-' : ''}${s.id}`)
        .join(',')
        .replaceAll('.', '__'),
      search: globalFilter,
      is_primary: true
    }
  });

  const { table } = useCustomMaterialTable<ProfileTableRow>({
    columns,
    data: data?.results ?? [],
    rowCount: data?.count ?? 0,
    isLoading,
    isFetching,
    pagination,
    setPagination,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    paginationSizes: [100, 500]
  });

  return <MaterialReactTable table={table} />;
};

const ProfileTable: React.FC<{ orgId: string }> = ({ orgId }) => {
  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <Paper
        elevation={2}
        style={{
          maxWidth: '100%',
          overflowX: 'auto',
          marginLeft: '1em',
          marginRight: '1em',
          padding: '1em',
          borderRadius: '12px'
        }}
      >
        <TableContent orgId={orgId} />
      </Paper>
    </ThemeProvider>
  );
};

export default ProfileTable;
