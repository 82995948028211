import { Box, Divider, Flex, Grid, GridItem, Heading, Tag, Text, useToast } from '@chakra-ui/react';
import { BiCopy } from 'react-icons/bi';

const OverviewSection: React.FC<{
  address: string;
  risk: string;
  cluster: {
    name: string;
    category: string;
  };
}> = ({ address, risk, cluster }) => {
  const toast = useToast();

  return (
    <Box height="100%" p={5} bg="white" layerStyle="profileOverview.container" border="2px solid #BED903">
      <Heading size="sm">Overview</Heading>
      <Flex pt={3} columnGap="8" flexWrap="wrap" pb={2}>
        <Text as="i" fontSize="sm" color="text.secondary">
          Address
        </Text>
      </Flex>
      <Flex alignItems="center">
        <Text fontSize="md">{address}</Text>
        <Box
          as="button"
          ml={2}
          cursor="pointer"
          onClick={async () => {
            await navigator.clipboard.writeText(address);
            toast({
              description: 'Copied to clipboard',
              status: 'success',
              duration: 1500,
              isClosable: true,
              position: 'top'
            });
          }}
        >
          <BiCopy />
        </Box>
      </Flex>
      <Grid templateColumns="auto auto" pt={6} columnGap={5}>
        <GridItem>
          <Text fontWeight="medium">Risk</Text>
        </GridItem>
        <GridItem>
          <Text>-</Text>
        </GridItem>
        <Divider gridColumn="1 / -1" borderColor="gray.300" my={2} />
        <GridItem>
          <Text fontWeight="medium">Risk Level</Text>
        </GridItem>
        <GridItem>
          <Tag bg={`risk.${risk.toUpperCase()}`} color="white">
            {risk}
          </Tag>
        </GridItem>
        <Divider gridColumn="1 / -1" borderColor="gray.300" my={2} />
        <GridItem>
          <Text fontWeight="medium">Last Risk Change</Text>
        </GridItem>
        <GridItem>
          <Text>Unknown</Text>
        </GridItem>
        <Divider gridColumn="1 / -1" borderColor="gray.300" my={2} />
        <GridItem>
          <Text fontWeight="medium">Cluster</Text>
        </GridItem>
        <GridItem>
          <Text>-</Text>
        </GridItem>
        <Divider gridColumn="1 / -1" borderColor="gray.300" my={2} />
        <GridItem>
          <Text fontWeight="medium">Cluster Name</Text>
        </GridItem>
        <GridItem>
          <Text>{cluster?.name ?? 'None'}</Text>
        </GridItem>
        <Divider gridColumn="1 / -1" borderColor="gray.300" my={2} />
        <GridItem>
          <Text fontWeight="medium">Cluster Category</Text>
        </GridItem>
        <GridItem>
          <Text>{cluster?.category ?? 'None'}</Text>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default OverviewSection;
