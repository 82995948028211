import { Box, Button, Flex, Heading, Icon, VStack, Tabs, TabList, TabPanels, TabPanel, Tab } from '@chakra-ui/react';
import { useRef, useState, useEffect } from 'react';
import { FaMagic } from 'react-icons/fa';
import { MdOutlinePlaylistAddCheck } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { Attachments } from '@features/profile-overview/';
import AuditLogModal from '@features/profile-overview/components/AuditLogModal.component';
import { Loading } from '@features/shared/components';
import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { PROFILE_TYPE } from '@models/profileTypes';
import {
  useGetCountriesQuery,
  useGetProfileByIdQuery,
  useGetProfileGroupsQuery,
  useGetProfileRiskRatingsQuery,
  useUpdateProfileMutation
} from '@services/canaria.services';

import BusinessRegulatoryCompliance, {
  type BusinessRegulatoryComplianceRef
} from './components/BusinessRegulatoryCompliance';
import ExpectedTransactionActivity, {
  type ExpectedTransactionActivityRef
} from './components/ExpectedTransactionActivity';
import NavigationTabs from './components/NavigationTabs';
import Overview from './components/Overview';
import PepInformation, { type PepInformationRef } from './components/PepInformation';
import PrimaryPartyInformation, { type PrimaryPartyInformationRef } from './components/PrimaryPartyInformation';
import RelatedParties, { type RelatedPartiesRef } from './components/RelatedParties';
import Wallets, { type WalletsRef } from './components/Wallets';
import ProfileNotes from './ProfileNotes';

interface SectionRef {
  scrollIntoView: (options?: ScrollIntoViewOptions) => void;
  expand?: () => void;
}

const NotesAndAttachmentsPanel: React.FC<{ profileId: string; orgId: string }> = ({ profileId, orgId }) => {
  return (
    <Tabs variant="soft-rounded" colorScheme="gray" w="100%">
      <TabList mb="1em" w="100%" h="32px">
        <Tab
          flex={1}
          h="32px"
          bg="#F7F7F7"
          color="#7C7E7E"
          _selected={{
            color: 'black',
            bg: 'white',
            border: '1px solid',
            borderColor: '#BED903'
          }}
          _focus={{ boxShadow: 'none' }}
        >
          Notes
        </Tab>
        <Tab
          flex={1}
          h="32px"
          bg="#F7F7F7"
          color="#7C7E7E"
          _selected={{
            color: 'black',
            bg: 'white',
            border: '1px solid',
            borderColor: '#BED903'
          }}
          _focus={{ boxShadow: 'none' }}
        >
          Attachments
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel p={0}>
          <ProfileNotes profileId={profileId} />
        </TabPanel>
        <TabPanel p={0}>
          <Attachments profileId={profileId} orgId={orgId} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

const ProfileOverview: React.FC = () => {
  const { profileId } = useParams();
  const [updateProfile] = useUpdateProfileMutation();
  const [isAuditLogOpen, setIsAuditLogOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('Overview');

  const primaryPartyRef = useRef<PrimaryPartyInformationRef>(null);
  const expectedTransactionActivityRef = useRef<ExpectedTransactionActivityRef>(null);
  const businessRegulatoryComplianceRef = useRef<BusinessRegulatoryComplianceRef>(null);
  const relatedPartiesRef = useRef<RelatedPartiesRef>(null);
  const walletsRef = useRef<WalletsRef>(null);
  const pepInformationRef = useRef<PepInformationRef>(null);
  const rightContainerRef = useRef<HTMLDivElement>(null);
  const [rightContainerHeight, setRightContainerHeight] = useState<number | null>(null);

  useEffect(() => {
    const updateHeight = (): void => {
      if (rightContainerRef.current != null) {
        setRightContainerHeight(rightContainerRef.current.offsetHeight);
      }
    };

    updateHeight();

    const resizeObserver = new ResizeObserver(updateHeight);
    if (rightContainerRef.current != null) {
      resizeObserver.observe(rightContainerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleSectionChange = (section: string): void => {
    const sectionRefs: Record<string, SectionRef | null> = {
      'Primary party information': {
        scrollIntoView: () => primaryPartyRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' }),
        expand: () => primaryPartyRef.current?.expand()
      },
      'Expected Transaction Activity': {
        scrollIntoView: () =>
          expectedTransactionActivityRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' }),
        expand: () => expectedTransactionActivityRef.current?.expand()
      },
      'PEP Information': {
        scrollIntoView: () => pepInformationRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' }),
        expand: () => pepInformationRef.current?.expand()
      },
      'Business Regulatory Compliance': {
        scrollIntoView: () =>
          businessRegulatoryComplianceRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' }),
        expand: () => businessRegulatoryComplianceRef.current?.expand()
      },
      'Related Parties': relatedPartiesRef.current,
      Wallets: walletsRef.current
    };

    const targetRef = sectionRefs[section];
    if (targetRef?.scrollIntoView != null) {
      targetRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
      targetRef.expand?.();
    }
  };

  if (profileId == null) {
    throw new Error('profileId is undefined');
  }

  const activeOrgID = useSelector(selectActiveOrgID);
  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }
  const { data: profile, isLoading } = useGetProfileByIdQuery({
    orgId: activeOrgID,
    profileId
  });

  const { data: profileGroups = [] } = useGetProfileGroupsQuery({
    orgId: activeOrgID
  });

  const { data: countries = [] } = useGetCountriesQuery({});
  const { data: profileRiskRatings = [] } = useGetProfileRiskRatingsQuery({});

  if (isLoading || profile == null) {
    return <Loading message="Loading Profile details..." />;
  }
  const mappedCountries = countries.map((country) => ({
    value: country.id,
    name: country.englishName
  }));

  const handleFieldChange = (name: string) => async (value) => {
    await updateProfile({
      orgId: activeOrgID,
      profileId,
      [name]: value
    }).unwrap();
  };
  const relatedPartiesStep = profile.profileGroup.stepsProfileForm.steps.find(({ step }) => step === 'Related Parties');
  const relatedPartiesEnabled =
    profile.resourcetype === PROFILE_TYPE.INDIVIDUAL ? relatedPartiesStep?.individual : relatedPartiesStep?.entity;

  const walletsStep = profile.profileGroup.stepsProfileForm.steps.find(({ step }) => step === 'Add Wallets');
  const walletsEnabled =
    profile.resourcetype === PROFILE_TYPE.INDIVIDUAL ? walletsStep?.individual : walletsStep?.entity;

  const expectedTransactionActivityStep = profile.profileGroup.stepsProfileForm.steps.find(
    ({ step }) => step === 'Expected Transaction Activity'
  );
  const expectedTransactionActivityEnabled =
    profile.resourcetype === PROFILE_TYPE.INDIVIDUAL
      ? expectedTransactionActivityStep?.individual
      : expectedTransactionActivityStep?.entity;

  const businessRegulatoryComplianceStep = profile.profileGroup.stepsProfileForm.steps.find(
    ({ step }) => step === 'Business Regulatory Compliance'
  );
  const businessRegulatoryComplianceEnabled =
    profile.resourcetype === PROFILE_TYPE.ENTITY && businessRegulatoryComplianceStep?.entity;

  const handleSectionHover = (section: string): void => {
    setActiveSection(section);
  };

  return (
    <>
      <Flex justifyContent="center" alignItems="center" width="full" paddingX={7} mb={8}>
        <Heading
          as="h2"
          size="lg"
          textAlign="left"
          mb={5}
          maxW="300px"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {profile.name ?? ''}
        </Heading>
        <Box flex={1} />
        <Box display="flex" flex={1} justifyContent="end">
          <Button
            ml={4}
            leftIcon={<Icon as={MdOutlinePlaylistAddCheck} boxSize={5} />}
            onClick={() => {
              setIsAuditLogOpen(true);
            }}
            bg="button.secondary"
            border="1px solid"
            borderColor="button.secondaryBorder"
          >
            Audit Log
          </Button>
          <Link to={`/dashboard/profile-wizard?profileId=${profileId}&step=1&from=profile`}>
            <Button ml={4} leftIcon={<Icon as={FaMagic} />} bg="button.primary">
              Go to Wizard
            </Button>
          </Link>
        </Box>
      </Flex>

      <NavigationTabs
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        relatedPartiesEnabled={relatedPartiesEnabled ?? false}
        walletsEnabled={walletsEnabled ?? false}
        expectedTransactionActivityEnabled={expectedTransactionActivityEnabled ?? false}
        businessRegulatoryComplianceEnabled={businessRegulatoryComplianceEnabled ?? false}
        onSectionChange={handleSectionChange}
      />

      <Flex justifyContent="space-between" width="100%" gap={1} px={4}>
        <Box
          flex="2"
          minW="0"
          maxH={rightContainerHeight != null ? `${rightContainerHeight}px` : 'calc(100vh - 180px)'}
          overflowY="auto"
          overflowX="hidden"
          position="sticky"
          top="20px"
          pr={4}
          sx={{
            '&::-webkit-scrollbar': {
              width: '6px',
              backgroundColor: 'transparent'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px',
              backgroundColor: 'transparent'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              borderRadius: '24px',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.3)'
              }
            },
            '&:hover::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0, 0, 0, 0.2)'
            },
            '&::-webkit-scrollbar-thumb:vertical': {
              display: 'none'
            },
            '&:hover::-webkit-scrollbar-thumb:vertical': {
              display: 'block'
            }
          }}
        >
          <PrimaryPartyInformation
            ref={primaryPartyRef}
            profileId={profileId}
            profile={profile}
            handleSectionHover={handleSectionHover}
            handleFieldChange={handleFieldChange}
            mappedCountries={mappedCountries}
          />
          {expectedTransactionActivityEnabled === true && (
            <ExpectedTransactionActivity
              ref={expectedTransactionActivityRef}
              profile={profile}
              handleFieldChange={handleFieldChange}
              handleSectionHover={handleSectionHover}
            />
          )}
          <PepInformation
            ref={pepInformationRef}
            profile={profile}
            handleFieldChange={handleFieldChange}
            mappedCountries={mappedCountries}
            handleSectionHover={handleSectionHover}
          />
          {businessRegulatoryComplianceEnabled === true && (
            <BusinessRegulatoryCompliance
              ref={businessRegulatoryComplianceRef}
              profile={profile}
              handleFieldChange={handleFieldChange as any}
              mappedCountries={mappedCountries}
              handleSectionHover={handleSectionHover}
            />
          )}
          {relatedPartiesEnabled === true && (
            <RelatedParties
              ref={relatedPartiesRef}
              profileId={profileId}
              orgId={activeOrgID}
              handleSectionHover={handleSectionHover}
              profileGroupId={profile.profileGroup.id}
            />
          )}
          {walletsEnabled === true && (
            <Wallets ref={walletsRef} profileId={profileId} handleSectionHover={handleSectionHover} />
          )}
        </Box>
        <Box ref={rightContainerRef} flex="1" minW="0" py={3}>
          <VStack spacing={4} align="stretch" w="100%" bg="white" boxShadow="md" layerStyle="profileOverview.container">
            <Overview
              profile={profile}
              profileGroups={profileGroups}
              profileRiskRatings={profileRiskRatings}
              handleSectionHover={handleSectionHover}
              handleFieldChange={handleFieldChange}
            />
            <NotesAndAttachmentsPanel profileId={profileId} orgId={activeOrgID} />
          </VStack>
        </Box>
      </Flex>
      <AuditLogModal
        isOpen={isAuditLogOpen}
        onClose={() => {
          setIsAuditLogOpen(false);
        }}
        profileId={profileId}
      />
    </>
  );
};

export default ProfileOverview;
