import mixpanel from 'mixpanel-browser';

import { loadCookiePreferences } from '@features/shared/utils/cookieConsent';

import { USE_SENTRY } from '../../env';

const MIXPANEL_KEY = '1a29aa55448ee2a3e3b1d953a314dff9';
let instance: Analytics | null = null;
class Analytics {
  initialized: boolean = false;
  constructor() {
    if (instance != null) throw new Error('New instance cannot be created!!');
    this.initializeMixpanel();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    instance = this;
  }

  initializeMixpanel(): void {
    const cookiePreferences = loadCookiePreferences();

    if (USE_SENTRY && cookiePreferences?.consentProps?.performanceCookies === true) {
      mixpanel.init(MIXPANEL_KEY);
      this.initialized = true;
    }
  }

  trackLoggedIn(): void {
    if (!this.initialized) return;
    mixpanel.track('Logged In');
  }

  trackLoggedOut(): void {
    if (!this.initialized) return;
    mixpanel.track('Logged Out');
    mixpanel.reset();
  }

  trackIdentify(email: string, firstName: string, lastName: string, created): void {
    if (!this.initialized) return;
    mixpanel.identify(email);
    mixpanel.register({
      Email: 'user',
      'First Name': firstName,
      'Last Name': lastName
    });
    mixpanel.people.set({
      $email: email,
      $first_name: firstName,
      $last_name: lastName,
      $distinct_id: email,
      $created: created
    });
  }

  trackOrganization(orgId: string | undefined, orgName: string): void {
    if (!this.initialized) return;
    mixpanel.register({
      'Organization ID': orgId,
      'Organization Name': orgName
    });
  }

  trackWalletInquiry(walletAddress: string, walletName: string): void {
    if (!this.initialized) return;
    mixpanel.track('Wallet Inquiry', {
      'Wallet Address': walletAddress,
      'Wallet Name': walletName
    });
  }

  trackAlertStatusChange(params: { alertId: string; fromStatus: string; toStatus: string }): void {
    if (!this.initialized) return;
    mixpanel.track('Alert Status Change', {
      'Alert ID': params.alertId,
      'From Status': params.fromStatus,
      'To Status': params.toStatus
    });
  }

  trackWebVital(metric: { name: string; value: number; rating: string }): void {
    if (!this.initialized) return;
    mixpanel.track(metric.name, {
      value: metric.value,
      rating: metric.rating
    });
  }

  trackSurveyOpened(surveyId: string): void {
    if (!this.initialized) return;
    mixpanel.track('Survey Opened', {
      'Survey ID': surveyId
    });
  }

  trackSurveyPageChange(surveyId: string, oldPage: string | undefined, newPage: string | undefined): void {
    if (!this.initialized) return;
    mixpanel.track('Survey Page Change', {
      'Survey ID': surveyId,
      'Old Page': oldPage,
      'New Page': newPage
    });
  }

  trackSurveyQuestionAnswered(surveyId: string, questionName: string): void {
    if (!this.initialized) return;
    mixpanel.track('Survey Question Answered', {
      'Survey ID': surveyId,
      'Question Name': questionName
    });
  }

  trackSurveySubmitted(surveyId: string): void {
    if (!this.initialized) return;
    mixpanel.track('Survey Submitted', {
      'Survey ID': surveyId
    });
  }
}

const analyticsInstance = new Analytics();

export default analyticsInstance;
