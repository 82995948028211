import { Box, Button, HStack } from '@chakra-ui/react';
import { useState } from 'react';

import { Loading } from '@features/shared/components';
import { useProfileForm } from '@hooks/useProfileForm';
import { type IProfile } from '@models/profileTypes';
import {
  useCreateRelatedProfileMutation,
  useUpdateRelatedProfileMutation,
  useGetNestedRelatedProfilesQuery
} from '@services/canaria.services';

import RelatedProfileList from './RelatedProfileList';
import { AddPrimaryPartyForm } from '../AddPrimaryParty';

interface RelatedProfilesFormProps {
  orgId: string;
  profile: IProfile;
  showForm: boolean;
  setShowForm: (value: boolean) => void;
  compact?: boolean;
  children?: React.ReactNode;
}

const RelatedProfileManager: React.FC<RelatedProfilesFormProps> = ({
  orgId,
  profile,
  showForm,
  setShowForm,
  compact = true,
  children
}) => {
  const [createRelatedProfile] = useCreateRelatedProfileMutation();
  const [updateRelatedProfile] = useUpdateRelatedProfileMutation();

  const { data: dataNestedRelatedProfiles, isLoading: isLoadingNestedRelatedProfiles } =
    useGetNestedRelatedProfilesQuery({
      orgId,
      profileId: profile.id
    });

  const [currentRelatedProfile, setCurrentRelatedProfile] = useState<{
    id: string;
    relationTypes: Array<{
      value: string;
      name: string;
    }>;
    profile: IProfile;
  } | null>(null);

  const { handleSubmit } = useProfileForm({
    orgId,
    onBefore: async (values) => ({
      ...values,
      profileGroup: profile.profileGroup.id,
      isPrimary: false
    }),
    onSuccess: async (createdProfileId, values) => {
      if (currentRelatedProfile == null) {
        await createRelatedProfile({
          orgId,
          profileId: profile.id,
          toProfile: createdProfileId,
          relationTypes: values.relationTypes.map((relationType) => relationType.value)
        }).unwrap();
      } else {
        await updateRelatedProfile({
          orgId,
          profileId: profile.id,
          relatedProfileId: currentRelatedProfile.id,
          relationTypes: values.relationTypes
        }).unwrap();
      }
      setCurrentRelatedProfile(null);
      setShowForm(false);
    }
  });

  if (isLoadingNestedRelatedProfiles) {
    return <Loading />;
  }

  const relatedProfiles = dataNestedRelatedProfiles?.results ?? [];

  const handleAddRelatedProfile = async (values): Promise<void> => {
    await handleSubmit(values, currentRelatedProfile?.profile?.id);
  };

  if (showForm) {
    return (
      <>
        <AddPrimaryPartyForm
          profile={currentRelatedProfile?.profile}
          onSubmit={handleAddRelatedProfile}
          isRelatedParty={true}
          relationTypes={currentRelatedProfile?.relationTypes?.map((relationType) => relationType.value)}
        />
        <Box width="100%" mt={4}>
          <HStack spacing={4} justifyContent="flex-end">
            <Button
              bg="button.secondary"
              border="1px solid"
              borderColor="button.secondaryBorder"
              px={16}
              onClick={() => {
                setShowForm(false);
                setCurrentRelatedProfile(null);
              }}
            >
              Cancel
            </Button>
            <Button type="submit" bg="button.primary" form="IndividualPrimaryParty" px={16}>
              {currentRelatedProfile != null ? 'Update' : 'Add Related Party'}
            </Button>
          </HStack>
        </Box>
      </>
    );
  }

  return (
    <>
      {relatedProfiles?.length === 0 ? (
        children
      ) : (
        <RelatedProfileList
          isLoadingRelatedProfiles={isLoadingNestedRelatedProfiles}
          relatedProfiles={relatedProfiles}
          compact={compact}
          onClickEdit={(relatedProfile) => {
            setShowForm(true);
            setCurrentRelatedProfile(relatedProfile);
          }}
          onClickAdd={() => {
            setShowForm(true);
          }}
        />
      )}
    </>
  );
};

export default RelatedProfileManager;
