import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import { Box, Flex, IconButton, Input } from '@chakra-ui/react';
import { useState } from 'react';
import { Form } from 'react-final-form';

import { FormField, type ValidatorType } from '@components/FormField';

interface EditableTextProps {
  label: string;
  value: string;
  onConfirmChange: (value: string) => void;
  isDisabled?: boolean;
  validate?: ValidatorType | ValidatorType[];
}

const EditableText: React.FC<EditableTextProps> = ({ label, value, onConfirmChange, isDisabled = false, validate }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = (): void => {
    setIsEditing(true);
  };

  const handleCancel = (): void => {
    setIsEditing(false);
  };

  const handleSubmit = (values: { field: string }): void => {
    if (values.field !== value) {
      onConfirmChange(values.field);
    }
    setIsEditing(false);
  };

  return (
    <Form
      key={isEditing ? 'editing' : 'not-editing'}
      onSubmit={handleSubmit}
      initialValues={{ field: value }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Flex align="center">
            <FormField
              name="field"
              label={label}
              direction="horizontal"
              isDisabled={isDisabled || !isEditing}
              maxW={null}
              disableOptionalText
            >
              <Input size="sm" opacity="1 !important" />
            </FormField>
            {isEditing ? (
              <>
                <IconButton ml="1" size="sm" icon={<CheckIcon />} type="submit" aria-label="confirm" />
                <IconButton ml="1" size="sm" icon={<CloseIcon />} onClick={handleCancel} aria-label="cancel" />
              </>
            ) : (
              <Box w={!isDisabled ? 'auto' : '32px'}>
                {!isDisabled && (
                  <IconButton ml="1" size="sm" icon={<EditIcon />} onClick={handleEdit} aria-label="edit" />
                )}
              </Box>
            )}
          </Flex>
        </form>
      )}
    />
  );
};

export default EditableText;
