import { EditIcon } from '@chakra-ui/icons';
import {
  Box,
  IconButton,
  TagCloseButton,
  TagLabel,
  Tag,
  WrapItem,
  Wrap,
  Flex,
  Stack,
  useToast
} from '@chakra-ui/react';
import { Button as MaterialButton, Select as MaterialSelect, MenuItem, Paper, ThemeProvider } from '@mui/material';
import { MaterialReactTable, type MRT_ColumnDef, type MRT_RowData } from 'material-react-table';
import { useMemo, useState, useEffect } from 'react';
import { BiSolidUser, BiSolidBusiness } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';

import { StatusBadge } from '@features/profile/components/ProfileTable';
import { useCustomMaterialTable } from '@features/shared/hooks/useCustomMaterialTable';
import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { PROFILE_TYPE } from '@models/profileTypes';
import { useUpdateRelatedProfileMutation, useGetProfileByIdQuery } from '@services/canaria.services';
import { defaultMaterialTheme, RELATION_TYPE_OPTIONS } from '@utils/consts';

interface RelatedProfile extends MRT_RowData {
  id: string;
  profile: {
    id: string;
    url: string;
    relatedProfiles?: RelatedProfile[];
  };
  relationTypes: Array<{ name: string; value: string }>;
}

interface RelatedProfileListProps {
  isLoadingRelatedProfiles: boolean;
  relatedProfiles: RelatedProfile[];
  onClickEdit: (party: any) => void;
  onClickAdd: () => void;
  compact: boolean;
}

const RelatedProfileTable: React.FC<{
  relatedProfiles: RelatedProfile[];
  isLoadingRelatedProfiles: boolean;
  onClickAdd: () => void;
  compact: boolean;
}> = ({ relatedProfiles, isLoadingRelatedProfiles, onClickAdd, compact }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25
  });

  const getProfileId = (): string | null => {
    const pathProfileId = params.profileId ?? params.id;
    if (pathProfileId == null) {
      const searchParams = new URLSearchParams(location.search);
      return searchParams.get('profileId');
    }
    return pathProfileId;
  };

  const columns = useMemo<Array<MRT_ColumnDef<RelatedProfile>>>(
    () => [
      ...(!compact
        ? [
            {
              id: 'id',
              header: 'ID',
              accessorKey: 'profile.id',
              size: 40,
              grow: 0,
              Cell: ({ row }) => (
                <Box pl={!compact ? `${row.depth * 30}px` : 0} display="flex" alignItems="center">
                  {row.depth > 0 && (
                    <Box
                      borderLeft="1px solid #E0E0E0"
                      height="24px"
                      position="absolute"
                      left={`${row.depth * 24 - 12}px`}
                    />
                  )}
                  {row.original.profile.id}
                </Box>
              )
            }
          ]
        : []),
      {
        id: 'name',
        header: 'Name',
        accessorKey: 'profile.id',
        Cell: ({ row }) => {
          const activeOrgID = useSelector(selectActiveOrgID);
          const { data: dataProfile } = useGetProfileByIdQuery({
            orgId: activeOrgID ?? '',
            profileId: row.original.profile.id
          });
          return (
            <Box pl={compact ? `${row.depth * 30}px` : 0} display="flex" alignItems="center" gap={2}>
              {compact &&
                (dataProfile?.resourcetype === PROFILE_TYPE.INDIVIDUAL ? (
                  <BiSolidUser color="#E0E0E0" />
                ) : dataProfile?.resourcetype === PROFILE_TYPE.ENTITY ? (
                  <BiSolidBusiness color="#E0E0E0" />
                ) : null)}
              <Flex alignItems="center" position="relative">
                {dataProfile?.fullLegalName}
                {dataProfile?.totalUnresolvedHits != null && dataProfile.totalUnresolvedHits > 0 && (
                  <Link to={`/dashboard/profiles/${dataProfile?.id}/screenings`}>
                    <Box
                      position="absolute"
                      top="-8px"
                      right="-20px"
                      bg="#D72638"
                      color="white"
                      borderRadius="full"
                      w="16px"
                      h="16px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      fontSize="10px"
                      fontWeight="bold"
                    >
                      {dataProfile.totalUnresolvedHits}
                    </Box>
                  </Link>
                )}
              </Flex>
            </Box>
          );
        }
      },
      ...(!compact
        ? [
            {
              id: 'resourcetype',
              header: 'Type',
              accessorKey: 'profile.resourcetype',
              Cell: ({ row }) => {
                const activeOrgID = useSelector(selectActiveOrgID);
                const { data: dataProfile } = useGetProfileByIdQuery({
                  orgId: activeOrgID ?? '',
                  profileId: row.original.profile.id
                });
                return <Box>{dataProfile?.resourcetype === PROFILE_TYPE.INDIVIDUAL ? 'Individual' : 'Entity'}</Box>;
              }
            }
          ]
        : []),
      {
        id: 'relationship',
        header: 'Relationship to primary Party',
        accessorKey: 'relationType',
        Cell: ({ row }) => {
          const [isEditing, setIsEditing] = useState(false);
          const [relationships, setRelationships] = useState(row.original.relationTypes);

          useEffect(() => {
            setRelationships(row.original.relationTypes);
          }, [row.original.relationTypes, row.id]);

          const orgId = useSelector(selectActiveOrgID);
          const profileId = getProfileId();
          const [updateRelatedProfile] = useUpdateRelatedProfileMutation();
          const toast = useToast();

          const handleSaveRelationships = async (): Promise<void> => {
            try {
              if (orgId == null) {
                throw new Error('Organization ID is null');
              }

              // Get the correct profile ID based on row depth
              let effectiveProfileId: string | null = null;
              if (row.depth === 0) {
                // For top-level rows, use the URL profile ID
                effectiveProfileId = profileId;
              } else {
                // For nested rows, use the parent's profile ID
                const parentRow = table.getRowModel().rowsById[row.id].getParentRow();
                effectiveProfileId = parentRow?.original.profile.id ?? null;
              }

              if (effectiveProfileId == null) {
                throw new Error('Could not determine profile ID');
              }

              await updateRelatedProfile({
                profileId: effectiveProfileId,
                orgId,
                relatedProfileId: row.original.id,
                relationTypes: relationships.map((r) => r.value)
              });
              setIsEditing(false);
            } catch (error) {
              toast({
                title: 'Failed to update relationships',
                status: 'error',
                description: error instanceof Error ? error.message : 'Unknown error occurred'
              });
            }
          };

          if (!Array.isArray(relationships)) return null;

          if (!isEditing) {
            return (
              <Flex alignItems="center" gap={4}>
                <Stack direction="column" spacing={2}>
                  {relationships.map((rel) => (
                    <Box key={rel.name}>{rel.name}</Box>
                  ))}
                </Stack>
                <IconButton
                  icon={<EditIcon />}
                  aria-label="Edit relationship"
                  onClick={() => {
                    setIsEditing(true);
                  }}
                />
              </Flex>
            );
          }
          return (
            <Box p={4} borderWidth="1px" borderRadius="lg" bg="white" shadow="sm">
              <Wrap spacing={2} mb={4}>
                {relationships.map((rel) => (
                  <WrapItem key={rel.value}>
                    <Tag size="md" borderRadius="full" variant="solid" colorScheme="blue">
                      <TagLabel>{RELATION_TYPE_OPTIONS[rel.value]}</TagLabel>
                      <TagCloseButton
                        onClick={() => {
                          setRelationships(relationships.filter((r) => r !== rel));
                        }}
                      />
                    </Tag>
                  </WrapItem>
                ))}
              </Wrap>
              <Flex gap={3}>
                <MaterialSelect
                  fullWidth
                  value=""
                  onChange={(e) => {
                    if (e.target.value !== '') {
                      setRelationships([
                        ...relationships,
                        { name: RELATION_TYPE_OPTIONS[e.target.value], value: e.target.value }
                      ]);
                    }
                  }}
                >
                  {Object.entries(RELATION_TYPE_OPTIONS).map(
                    ([value, label]) =>
                      !relationships.some((r) => r.value === value) && (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      )
                  )}
                </MaterialSelect>
                <MaterialButton variant="contained" color="primary" size="small" onClick={handleSaveRelationships}>
                  Save
                </MaterialButton>
                <MaterialButton
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setIsEditing(false);
                  }}
                >
                  Cancel
                </MaterialButton>
              </Flex>
            </Box>
          );
        }
      },
      {
        id: 'status',
        accessorKey: 'status',
        header: 'Status',
        enableHiding: true,
        size: 100,
        Cell: ({ row }) => {
          const activeOrgID = useSelector(selectActiveOrgID);
          const { data: dataProfile } = useGetProfileByIdQuery({
            orgId: activeOrgID ?? '',
            profileId: row.original.profile.id
          });

          return <StatusBadge status={dataProfile?.status ?? ''} />;
        },
        muiTableHeadCellProps: {
          sx: {
            display: {
              xs: 'none',
              sm: 'table-cell'
            }
          }
        },
        muiTableBodyCellProps: {
          sx: {
            display: {
              xs: 'none',
              sm: 'table-cell'
            }
          }
        }
      }
    ],
    [navigate]
  );

  const { table } = useCustomMaterialTable<RelatedProfile>({
    columns,
    data: relatedProfiles ?? [],
    rowCount: relatedProfiles?.length ?? 0,
    isLoading: isLoadingRelatedProfiles,
    isFetching: false,
    pagination,
    setPagination,
    enableExpanding: true,
    paginationSizes: [25, 50, 100],
    getSubRows: (row) => row?.profile?.relatedProfiles ?? []
  });

  return <MaterialReactTable table={table} />;
};

const RelatedProfileList: React.FC<RelatedProfileListProps> = (props) => {
  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <Paper
        elevation={0}
        style={{
          maxWidth: '100%',
          overflowX: 'auto',
          borderRadius: '12px'
        }}
      >
        <RelatedProfileTable {...props} />
      </Paper>
    </ThemeProvider>
  );
};

export default RelatedProfileList;
