import { Cookies } from 'react-cookie-consent';

import analyticsInstance from '@features/analytics/analytics';

interface ConsentDetails {
  consented: boolean;
  consentType?: string;
  consentProps?: {
    functionalCookies: boolean;
    performanceCookies: boolean;
    targetingCookies: boolean;
  };
}

export const COOKIE_NAME = 'user_consent';

export const handleAccept = (consentType: 'full' | 'granular', consentProps?: any): void => {
  if (consentType === 'granular' && consentProps == null) {
    throw new Error('Consent props are required for granular consent');
  }
  const consentDetails = {
    consented: true,
    consentType,
    consentProps
  };
  if (consentType === 'full') {
    consentDetails.consentProps = {
      functionalCookies: true,
      performanceCookies: true,
      targetingCookies: true
    };
  }

  Cookies.set(COOKIE_NAME, JSON.stringify(consentDetails), { expires: 365, path: '/' });
  analyticsInstance.initializeMixpanel();
};

export const handleReject = (): void => {
  Cookies.set(COOKIE_NAME, JSON.stringify({ consented: false }), { expires: 365, path: '/' });
};

export const loadCookiePreferences = (): ConsentDetails => {
  const cookiePreferences = Cookies.get(COOKIE_NAME);
  if (cookiePreferences == null) {
    return { consented: false };
  }
  const consent = JSON.parse(cookiePreferences) as ConsentDetails;
  return consent;
};
