import { Heading, Text, VStack, Divider, Box } from '@chakra-ui/react';

import { formatDate, StatusSelector } from '@features/profile-overview';
import EditableField from '@features/profile-overview/components/EditableField/';
import { PROFILE_TYPE, type IProfile } from '@models/profileTypes';

import ProfileGroupOverview from '../ProfileGroupOverview';

const Overview: React.FC<{
  profile: IProfile;
  profileGroups: any[];
  profileRiskRatings: any[];
  handleSectionHover: (section: string) => void;
  handleFieldChange: (field: string) => (value: string | number | Date | Array<string | number>) => void;
}> = ({ profile, profileGroups, profileRiskRatings, handleSectionHover, handleFieldChange }) => {
  return (
    <VStack
      spacing={4}
      align="stretch"
      w="100%"
      onMouseEnter={() => {
        handleSectionHover('Overview');
      }}
      layerStyle="profileOverview.subContainer"
    >
      <Heading as="h1" size="md" textAlign="left" mb={-4}>
        Overview
      </Heading>
      <Text fontSize="sm" color="gray.500" fontStyle="italic">
        {profile != null && (
          <>
            Created at {formatDate(profile.createdAt)} | Updated at: {formatDate(profile.updatedAt)}
          </>
        )}
      </Text>
      <VStack spacing={4} width="100%" alignItems="stretch" display="flex" flexDirection="column">
        <EditableField label="Name" value={profile?.name} onConfirmChange={handleFieldChange('name')} type="text" />
        <Divider />
        <Box display="flex" alignItems="center" flex={1} justifyContent="space-between">
          <Text mr={4} fontWeight={600}>
            Status
          </Text>
          <StatusSelector profileId={profile.id} status={profile.status} hasOpenItems={profile.hasOpenItems} />
        </Box>
        <Divider />
        <EditableField
          label="Type"
          value={profile?.resourcetype === PROFILE_TYPE.INDIVIDUAL ? 'Individual' : 'Entity'}
          isDisabled={true}
          onConfirmChange={() => {}}
          type="text"
        />
        <Divider />
        <Box position="relative" pr="40px">
          <EditableField
            label="Profile group"
            value={profile?.profileGroup.id}
            type="select"
            options={profileGroups?.map((group) => ({
              value: group.id,
              name: group.name
            }))}
            onConfirmChange={handleFieldChange('profile_group')}
          />
          <Box position="absolute" right="0" top="50%" transform="translateY(-50%)">
            <ProfileGroupOverview profileGroupId={profile?.profileGroup.id} />
          </Box>
        </Box>
        <Divider />
        <EditableField
          label="Profile risk"
          value={profile?.risk}
          type="select"
          options={profileRiskRatings}
          onConfirmChange={handleFieldChange('risk')}
          allowNull={false}
        />
        <Divider />
        <EditableField
          label="Project Name"
          value={profile?.projectName}
          onConfirmChange={handleFieldChange('projectName')}
          type="text"
        />
        <Divider />
        <EditableField
          label="Organization Contact"
          value={profile?.organizationContact}
          onConfirmChange={handleFieldChange('organizationContact')}
          type="text"
        />
      </VStack>
    </VStack>
  );
};

export default Overview;
