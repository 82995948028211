import { Container, Box, Flex, Button, Tooltip as ChakraTooltip, Icon, Heading, Text, VStack } from '@chakra-ui/react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useMemo } from 'react';
import { FaRegWindowClose } from 'react-icons/fa';
import { GrUpgrade } from 'react-icons/gr';
import { IoArrowBack } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import AlertBody from '@features/panels/alertsPanel/components/AlertBody.component';
import AlertHeader from '@features/panels/alertsPanel/components/AlertHeader.component';
import { AlertNotes } from '@features/panels/alertsPanel/components/AlertNotes.component';
import { ALERT_STATUS } from '@features/panels/alertsPanel/constants';
import { useAlertActions } from '@features/panels/alertsPanel/hooks/useAlertActions';
import { useProfileInquiryActions } from '@features/panels/alertsPanel/hooks/useProfileInquiryActions';
import { Loading } from '@features/shared/components';
import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { useGetAlertByIDQuery } from '@services/canaria.services';

ChartJS.register(ArcElement, Tooltip, Legend);

const ProfileDetail = ({ label, value, isLast = false }): JSX.Element | null => {
  if (value == null) return null;

  return (
    <Flex
      direction="row"
      gap={2}
      pb={3}
      borderBottom={isLast ? 'none' : '1px solid'}
      borderColor="gray.200"
      justifyContent="space-between"
    >
      <Text>{label}:</Text>
      <Text fontWeight="medium">{value}</Text>
    </Flex>
  );
};

const AlertProfileDetails = ({ profile }): JSX.Element => {
  const details = [
    { label: 'Entity Type', value: profile?.entityType },
    { label: 'Full Name', value: profile?.fullLegalName },
    { label: 'Date of Birth', value: profile?.dateOfBirth },
    { label: 'DBA Name', value: profile?.dbaTradeName },
    { label: 'Citizenship', value: profile?.citizenship?.englishName },
    { label: 'Country of Establishment', value: profile?.placeOfEstablishment?.englishName },
    { label: 'Place of Birth', value: profile?.placeOfBirth?.englishName },
    { label: 'ID Type', value: profile?.idType?.name },
    { label: 'ID Number', value: profile?.idNumber },
    { label: 'ID Issuer', value: profile?.idIssuer?.englishName },
    { label: 'Unique Identification Number', value: profile?.uniqueIdentificationNumber }
  ];

  const validDetails = details.filter((detail) => detail.value != null);

  return (
    <Box layerStyle="profileOverview.subContainer" m={0}>
      <Text fontWeight={800} mb={4}>
        Profile Information
      </Text>
      {validDetails.map((detail, index) => (
        <ProfileDetail
          key={detail.label}
          label={detail.label}
          value={detail.value}
          isLast={index === validDetails.length - 1}
        />
      ))}
    </Box>
  );
};

const AlertDetail: React.FC = () => {
  const navigate = useNavigate();
  const activeOrgID = useSelector(selectActiveOrgID);
  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }
  const { alertID } = useParams();
  if (alertID == null) {
    throw new Error('alertID must be provided');
  }
  const { data, /* isFetching, isLoading, */ refetch } = useGetAlertByIDQuery({
    orgId: activeOrgID,
    alertId: alertID
  });
  const { handleAlertStatusUpdate, onReassign, updateAlertNotes, updateAlertIsLoading } = useAlertActions(
    Number(alertID),
    Number(activeOrgID),
    data?.notes,
    data?.status
  );

  const { onResolveMatch } = useProfileInquiryActions(activeOrgID, data, refetch, handleAlertStatusUpdate);

  const allMatchesResolved: boolean = useMemo(() => {
    return data?.profile_inquiries?.every((inquiry) => inquiry.resolution !== 'NO_RESOLUTION') ?? true;
  }, [data?.profile_inquiries]);

  const allMatchesFalsePositive: boolean = useMemo(() => {
    return data?.profile_inquiries?.every((inquiry) => inquiry.resolution === 'FALSE_POSITIVE') ?? true;
  }, [data?.profile_inquiries]);

  const canCloseAlert: boolean =
    allMatchesResolved && (allMatchesFalsePositive || data?.status === ALERT_STATUS.ESCALATED);

  const getTooltipMessage = (): string => {
    if (!allMatchesResolved) {
      return 'Please resolve every match before closing the alert';
    }
    if (!allMatchesFalsePositive && data?.status !== ALERT_STATUS.ESCALATED) {
      return 'This alert contains true matches. Please escalate the alert before closing.';
    }
    return '';
  };

  const handleSaveNotes = async (newNotes: string): Promise<void> => {
    await updateAlertNotes(newNotes);
  };

  if (data == null) {
    return <Loading message="Loading Alert details" />;
  }

  return (
    <Container maxW="full">
      <Flex ml={10} gap={4} alignItems="center" mb={4} justifyContent="space-between">
        <Flex gap={4} alignItems="center">
          <Button
            bg="button.secondary"
            border="1px solid"
            borderColor="button.secondaryBorder"
            px={0}
            onClick={() => {
              navigate(-1);
            }}
          >
            <Icon as={IoArrowBack} />
          </Button>
          <Heading as="h3" size="md" fontWeight="normal">
            Alert (ID: {alertID})
          </Heading>
        </Flex>
        <Flex gap={2}>
          {![ALERT_STATUS.CLOSED, ALERT_STATUS.ESCALATED].includes(data?.status) && (
            <Button
              fontSize="sm"
              isDisabled={updateAlertIsLoading}
              onClick={async () => {
                await handleAlertStatusUpdate(ALERT_STATUS.ESCALATED);
              }}
              leftIcon={<Icon as={GrUpgrade} />}
              bg="button.secondary"
              border="1px solid"
              borderColor="button.secondaryBorder"
            >
              Escalate Alert
            </Button>
          )}
          <ChakraTooltip
            label={getTooltipMessage()}
            isDisabled={canCloseAlert || data?.status === ALERT_STATUS.CLOSED}
            hasArrow
            placement="bottom"
            bg="gray.700"
            color="white"
          >
            <Button
              fontSize="sm"
              size="md"
              isDisabled={updateAlertIsLoading || (data?.status !== ALERT_STATUS.CLOSED && !canCloseAlert)}
              onClick={async () => {
                await handleAlertStatusUpdate(
                  data?.status === ALERT_STATUS.CLOSED ? ALERT_STATUS.OPEN : ALERT_STATUS.CLOSED
                );
              }}
              bg="button.primary"
              leftIcon={<Icon as={FaRegWindowClose} />}
            >
              {data?.status === ALERT_STATUS.CLOSED ? 'Open Alert' : 'Close Alert'}
            </Button>
          </ChakraTooltip>
        </Flex>
      </Flex>

      <>
        <AlertHeader alert={data} onReassign={onReassign} />
        <Flex gap={4} display="flex" width="100%">
          <Box mb={4} flex="3.5">
            <VStack pt={6} padding={2} align="stretch" bg="white" layerStyle="profileOverview.container">
              {data.resourcetype === 'WatchlistAlert' && <AlertProfileDetails profile={data.profile} />}
              <AlertNotes initialNotes={data?.notes ?? ''} onSave={handleSaveNotes} isLoading={updateAlertIsLoading} />
            </VStack>
          </Box>
          <Box flex="6.5" overflowX="auto">
            <AlertBody alert={data} onResolveMatch={onResolveMatch} />
          </Box>
        </Flex>
      </>
    </Container>
  );
};

export default AlertDetail;
