import { Flex, Icon, Link, Text, useDisclosure } from '@chakra-ui/react';
import { FaLinkedinIn, FaInstagram } from 'react-icons/fa';

import CookiePreferences from '@features/shared/components/CookiePreferences';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex
      as="footer"
      justify="space-between"
      align="center"
      padding="1.5rem"
      mt="auto"
      bg="footer.background"
      color="footer.text"
    >
      <Text fontSize="sm" color="footer.text">
        © 2023-{currentYear} Canaria Consulting. All rights reserved
      </Text>
      <Flex>
        <Link href="#" fontSize="sm" color="gray.500" marginRight="1rem" onClick={onOpen}>
          Cookie settings
        </Link>
        <Link href="/privacy-policy" fontSize="sm" color="gray.500" marginRight="1rem">
          Privacy policy
        </Link>
        <Link href="https://www.linkedin.com/company/canaria-consulting/" isExternal>
          <Icon as={FaLinkedinIn} boxSize="24px" color="footer.text" marginRight="1rem" cursor="pointer" />
        </Link>
        <Link href="https://www.instagram.com/canariaco/" isExternal>
          <Icon as={FaInstagram} boxSize="24px" color="footer.text" marginRight="1rem" cursor="pointer" />
        </Link>
      </Flex>
      <CookiePreferences isOpen={isOpen} onClose={onClose} />
    </Flex>
  );
};

export default Footer;
