import { InfoIcon } from '@chakra-ui/icons';
import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Spinner,
  Text,
  Tooltip as ChakraTooltip,
  Button,
  Icon
} from '@chakra-ui/react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useState, useEffect } from 'react';
import { IoArrowBack } from 'react-icons/io5';
import { SlExclamation } from 'react-icons/sl';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import { selectActiveOrgID } from '@features/user-settings/userSlice';
import AddressIdentificationSection from '@features/wallets/AddressIdentificationSection.component';
import ExposureData from '@features/wallets/ExposureData.component';
import NameDisplay from '@features/wallets/NameDisplay.component';
import NotesDisplay from '@features/wallets/NotesDisplay.component';
import OverviewSection from '@features/wallets/OverviewSection.component';
import ScreeningInfoDisplay from '@features/wallets/ScreeningInfoDisplay.component';
import TriggersSection from '@features/wallets/TriggersSection.component';
import {
  useGetWalletByIDQuery,
  useUpdateWalletMutation,
  useNewWalletInquiryMutation
} from '@services/canaria.services';
import { RISK_BG_COLORS, RISK_BORDER_COLORS } from '@utils/consts';
import { getRiskLevel } from '@utils/wallet';

ChartJS.register(ArcElement, Tooltip, Legend);

export const getExposureInformation = (
  exposures: Array<{ value: number; category: string; exposureType: 'direct' | 'indirect' }>
): {
  sum: number;
  exposureRisk: Map<string, string>;
  directRisks: any;
  indirectRisks: any;
} => {
  const sum = exposures.reduce((acc, exp) => acc + exp.value, 0);
  const exposureRisk = new Map();

  const directExposures = exposures.filter((exp) => exp.exposureType === 'direct');
  const indirectExposures = exposures.filter((exp) => exp.exposureType === 'indirect');

  const calculateRiskValues = (
    exps: Array<{ value: number; category: string; exposureType: 'direct' | 'indirect' }>
  ): {
    severe: number;
    high: number;
    medium: number;
    low: number;
  } => {
    let severe = 0;
    let high = 0;
    let medium = 0;
    let low = 0;

    exps.forEach((exposure) => {
      const exposurePercent = (exposure.value / sum) * 100.0;
      const riskLevel = getRiskLevel(exposure.category, exposurePercent);
      exposureRisk.set(exposure.category, riskLevel);

      switch (riskLevel) {
        case 'Severe':
          severe += exposure.value;
          break;
        case 'High':
          high += exposure.value;
          break;
        case 'Medium':
          medium += exposure.value;
          break;
        case 'Low':
          low += exposure.value;
          break;
      }
    });

    return {
      severe: parseFloat(((severe / sum) * 100).toFixed(2)),
      high: parseFloat(((high / sum) * 100).toFixed(2)),
      medium: parseFloat(((medium / sum) * 100).toFixed(2)),
      low: parseFloat(((low / sum) * 100).toFixed(2))
    };
  };

  const directRisks = calculateRiskValues(directExposures);
  const indirectRisks = calculateRiskValues(indirectExposures);

  return {
    sum,
    exposureRisk,
    directRisks,
    indirectRisks
  };
};

// https://docs.chainalysis.com/api/address-screening/#get-an-audit-trail-of-the-risk-of-screened-addresses

export const InquiryResult: React.FC<{
  data: any;
  error: any;
  isLoading: boolean;
  orgId: string;
  walletID: string;
  state: any;
  showExposure: boolean;
  updateWallet: any;
  updateWalletIsLoading: boolean;
  newWalletInquiry: any;
  newWalletInquiryLoading: boolean;
}> = ({
  data,
  error,
  isLoading,
  orgId,
  walletID,
  state,
  showExposure,
  updateWallet,
  updateWalletIsLoading,
  newWalletInquiry,
  newWalletInquiryLoading
}) => {
  if (isLoading) {
    return <Spinner />;
  }

  if (error != null) {
    return <Text>Error found loading data</Text>;
  }

  if (data == null) {
    return null;
  }

  const { sum, exposureRisk, directRisks, indirectRisks } = getExposureInformation(data.last_inquiry.result.exposures);

  const createDataset = (type: 'Direct' | 'Indirect' | 'Spacer', risks?: any, weight: number = 1): any => {
    if (type === 'Spacer') {
      return {
        label: 'Spacer',
        data: [25, 25, 25, 25],
        backgroundColor: Array(4).fill('transparent'),
        borderColor: Array(4).fill('transparent'),
        borderWidth: 0,
        spacing: 0,
        weight: weight
      };
    }

    return {
      label: type,
      data: [risks.severe, risks.high, risks.medium, risks.low],
      backgroundColor: [RISK_BG_COLORS.SEVERE, RISK_BG_COLORS.HIGH, RISK_BG_COLORS.MEDIUM, RISK_BG_COLORS.LOW],
      borderColor: [
        RISK_BORDER_COLORS.SEVERE,
        RISK_BORDER_COLORS.HIGH,
        RISK_BORDER_COLORS.MEDIUM,
        RISK_BORDER_COLORS.LOW
      ],
      borderWidth: 1,
      borderRadius: 2,
      spacing: 4,
      weight: weight
    };
  };

  const chartData = {
    labels: ['Severe', 'High', 'Medium', 'Low'],
    datasets: [
      createDataset('Direct', directRisks, 1),
      createDataset('Spacer', null, 0.1),
      createDataset('Spacer', null, 0.1),
      createDataset('Spacer', null, 0.1),
      createDataset('Indirect', indirectRisks, 0.8)
    ]
  };

  return (
    <Box mb={4}>
      <Flex ml={10} gap={4} alignItems="center">
        <Button
          bg="button.secondary"
          border="1px solid"
          borderColor="button.secondaryBorder"
          px={0}
          width="fit-content"
          onClick={() => {
            if (state?.profileId != null) {
              window.location.href = `/dashboard/profiles/${state.profileId}`;
            } else {
              window.location.href = '/dashboard/wallets/';
            }
          }}
        >
          <Icon as={IoArrowBack} />
        </Button>
        <Box>
          <Heading as="h3" size="md">
            {data.name ?? 'Unnamed Wallet'}
          </Heading>
          <Text color="gray.600" fontSize="sm">
            {data.address}
          </Text>
        </Box>
      </Flex>

      <Box display="flex" flexDirection="column" gap={4} mt={4}>
        <Flex flexDirection="row" flexWrap="wrap" gap={3} alignItems="stretch">
          <Box flexGrow={1} flexBasis={500} minW="300px" display="flex" flexDirection="column" h="100%">
            <Box flex="1">
              <NameDisplay
                name={data.name}
                address={data.address}
                walletId={walletID}
                orgId={orgId}
                updateWallet={updateWallet}
                updateWalletIsLoading={updateWalletIsLoading}
              />
              <OverviewSection
                address={data.address}
                risk={data?.last_inquiry?.result?.risk}
                cluster={data?.last_inquiry?.result?.cluster}
              />
            </Box>
          </Box>

          <Box flexGrow={2} minW="300px" display="flex" flexDirection="column" h="100%">
            <Box flex="1">
              <NotesDisplay
                notes={data.notes}
                walletId={walletID}
                orgId={orgId}
                updateWallet={updateWallet}
                updateWalletIsLoading={updateWalletIsLoading}
              />
              <ScreeningInfoDisplay
                createdAt={data.created_at}
                lastInquiryCreatedAt={data.last_inquiry.created_at}
                frequency={data.schedule.frequency}
                walletId={walletID}
                orgId={orgId}
                walletName={data.name}
                walletAddress={data.address}
                updateWallet={updateWallet}
                updateWalletIsLoading={updateWalletIsLoading}
                newWalletInquiry={newWalletInquiry}
                newWalletInquiryLoading={newWalletInquiryLoading}
              />
            </Box>
          </Box>
        </Flex>

        <Box px={5} py={4} bg="white" layerStyle="profileOverview.container">
          <Heading size="sm">
            Exposure{' '}
            <ChakraTooltip label="Exposure refers to the relationship between the screened address and other entities that is created by transfers to/from the address. Exposure is determined by the last identified source of funds and the first identified destination of funds for all transfers that have been received to, or sent from, the screened address.">
              <InfoIcon />
            </ChakraTooltip>
          </Heading>
          {!showExposure ? (
            <Grid templateColumns="auto" justifyItems={'center'} pt={4} pb={4}>
              <GridItem pb={4}>
                <SlExclamation size={30} />
              </GridItem>
              <GridItem>
                <Text>
                  If an address is nested at a VASP, or categorized as an exchange, merchant services, or hosted wallet,
                  it will not trigger exposure risk.
                </Text>
              </GridItem>
            </Grid>
          ) : (
            <ExposureData
              exposures={[...data.last_inquiry.result.exposures]}
              sum={sum}
              exposureRisk={exposureRisk}
              chartData={chartData}
            />
          )}
        </Box>

        <Box mt={4}>
          <AddressIdentificationSection addressIdentifications={data?.last_inquiry?.result?.addressIdentifications} />
        </Box>

        <Box mt={4}>
          <TriggersSection triggers={data?.last_inquiry?.result?.triggers} />
        </Box>
      </Box>
    </Box>
  );
};

const Wallet: React.FC = () => {
  const { state } = useLocation();
  const { walletID } = useParams();
  const orgId = useSelector(selectActiveOrgID);
  if (orgId == null) {
    throw new Error('orgId is null');
  }
  const [showExposure, setShowExposure] = useState(false);
  if (walletID == null) {
    throw new Error('walletID is null');
  }
  const { data, error, isLoading } = useGetWalletByIDQuery({
    orgId,
    walletId: walletID
  });

  useEffect(() => {
    if (data != null) {
      setShowExposure(
        !['exchange', 'hosted wallet', 'merchant services'].includes(data.last_inquiry.result?.cluster?.category)
      );
    }
  }, [data]);

  const [updateWallet, { isLoading: updateWalletIsLoading }] = useUpdateWalletMutation();

  const [newWalletInquiry, { isLoading: newWalletInquiryLoading }] = useNewWalletInquiryMutation();

  return (
    <Container maxW="8xl">
      <InquiryResult
        data={data}
        error={error}
        isLoading={isLoading}
        orgId={orgId}
        walletID={walletID}
        state={state}
        showExposure={showExposure}
        updateWallet={updateWallet}
        updateWalletIsLoading={updateWalletIsLoading}
        newWalletInquiry={newWalletInquiry}
        newWalletInquiryLoading={newWalletInquiryLoading}
      />
    </Container>
  );
};

export default Wallet;
