import { Container, Heading, Flex, Button, Icon } from '@chakra-ui/react';
import { Box, ThemeProvider, Paper, Button as MuiButton } from '@mui/material';
import dayjs from 'dayjs';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { useState } from 'react';
import { BiCommentEdit } from 'react-icons/bi';
import { IoArrowBack } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { useCustomMaterialTable } from '@features/shared/hooks/useCustomMaterialTable';
import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { useGetAlertsQuery } from '@services/canaria.services';
import { RISK_BG_COLORS, defaultMaterialTheme } from '@utils/consts';

const STATUS_COLORS = {
  O: {
    bg: '#00A676',
    text: '#FFFFFF'
  },
  C: {
    bg: '#6C757D',
    text: '#FFFFFF'
  },
  R: {
    bg: '#FF9F1C',
    text: '#000000'
  },
  E: {
    bg: '#E71D36',
    text: '#FFFFFF'
  }
};

const columns: Array<MRT_ColumnDef<any>> = [
  {
    header: 'ID',
    accessorKey: 'id',
    size: 4,
    Cell: ({ cell: { getValue } }: any) => <Link to={`/dashboard/alerts/${getValue()}`}>{getValue()}</Link>
  },
  {
    header: 'Status',
    accessorKey: 'status',
    Cell: ({ cell: { getValue } }) => {
      const status = getValue() as keyof typeof STATUS_COLORS;
      const statusColor = STATUS_COLORS[status] ?? { bg: '#E2E8F0', text: '#1A202C' };

      return (
        <Box
          sx={{
            backgroundColor: statusColor.bg,
            color: statusColor.text,
            padding: '2px 8px',
            borderRadius: '6px',
            display: 'inline-block',
            fontSize: '0.875rem',
            fontWeight: '500',
            border: '1px solid rgba(0, 0, 0, 0.1)',
            textTransform: 'uppercase'
          }}
        >
          {(() => {
            switch (status) {
              case 'O':
                return 'Open';
              case 'C':
                return 'Closed';
              case 'R':
                return 'Under Review';
              case 'E':
                return 'Escalated';
              default:
                return 'Unknown';
            }
          })()}
        </Box>
      );
    },
    size: 4
  },
  {
    header: 'Name',
    accessorFn(originalRow) {
      if (originalRow.resourcetype === 'WatchlistAlert') {
        return originalRow.name;
      } else if (originalRow.resourcetype === 'WalletAlert') {
        return originalRow.wallet?.name;
      }
      return '-';
    },
    Cell: ({ cell: { getValue } }: any) => <>{getValue()}</>,
    size: 8
  },
  {
    header: 'Source',
    id: 'resourcetype',
    accessorFn(originalRow) {
      if (originalRow.resourcetype === 'WatchlistAlert') {
        return 'Watchlist Screening';
      } else if (originalRow.resourcetype === 'WalletAlert') {
        return 'Wallet Screening';
      } else if (originalRow.resourcetype === 'NegativeNewsAlert') {
        return 'Negative News';
      }
      return '-';
    },
    Cell: ({ cell: { getValue } }: any) => {
      return <>{getValue()}</>;
    },
    size: 8
  },
  {
    header: 'Entity',
    accessorFn(originalRow) {
      if (originalRow.resourcetype === 'WatchlistAlert') {
        return originalRow.profile.name;
      } else if (originalRow.resourcetype === 'WalletAlert') {
        return originalRow.wallet?.address;
      }
      return '-';
    },
    enableClickToCopy: true,
    size: 6
  },
  {
    header: 'Risk Level',
    accessorKey: 'current_inquiry_result.result.risk',
    size: 5,
    Cell: ({ cell }: any) => {
      const risk = cell.getValue();
      return (
        <Box
          sx={(theme) => ({
            backgroundColor: RISK_BG_COLORS[risk?.toUpperCase()] ?? 'white',
            borderRadius: '0.25rem',
            color: '#fff',
            minWidth: '6ch',
            maxWidth: '8ch',
            p: '0.4rem',
            border: '1px solid rgba(0, 0, 0, 0.1)'
          })}
        >
          <center>{risk}</center>
        </Box>
      );
    }
  },

  {
    header: 'Created at',
    accessorKey: 'created_at',
    Cell: ({ cell: { getValue } }: any) => dayjs(getValue()).format('YYYY-MM-DD hh:mm:ss A'),
    size: 6
  },
  {
    id: 'review',
    header: 'Review',
    accessorKey: 'id',
    Cell: ({ cell: { getValue } }: any) => (
      <Link to={`/dashboard/alerts/${getValue()}`}>
        <MuiButton
          variant="contained"
          size="small"
          startIcon={<BiCommentEdit />}
          sx={{
            backgroundColor: '#BED903',
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: '#a8c103'
            },
            textTransform: 'none',
            color: '#000000'
          }}
        >
          Review Alert
        </MuiButton>
      </Link>
    ),
    size: 6
  }
];

const TableContent: React.FC<{ orgId: string }> = ({ orgId }) => {
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [sorting, setSorting] = useState<Array<{ id: string; desc: boolean }>>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25
  });

  const { data, isFetching, isLoading } = useGetAlertsQuery({
    orgId,
    query: {
      page_size: pagination.pageSize,
      page: pagination.pageIndex + 1,
      ordering: sorting
        .map((s) => `${s.desc ? '-' : ''}${s.id}`)
        .join(',')
        .replaceAll('.', '__'),
      search: globalFilter
    }
  });

  const { table } = useCustomMaterialTable({
    columns,
    data: data?.results ?? [],
    rowCount: data?.count ?? 0,
    isLoading,
    isFetching,
    pagination,
    setPagination,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    paginationSizes: [25, 50, 100, 500]
  });

  return <MaterialReactTable table={table} />;
};

const AlertsPanel: React.FC = () => {
  const activeOrgID = useSelector(selectActiveOrgID);
  const navigate = useNavigate();

  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }

  return (
    <Container maxW="8xl">
      <Flex ml={10} gap={4} alignItems="center" mb={4}>
        <Button
          bg="button.secondary"
          border="1px solid"
          borderColor="button.secondaryBorder"
          px={0}
          onClick={() => {
            navigate(-1);
          }}
        >
          <Icon as={IoArrowBack} />
        </Button>
        <Heading as="h3" size="md">
          Alerts
        </Heading>
      </Flex>

      <ThemeProvider theme={defaultMaterialTheme}>
        <Paper
          elevation={2}
          style={{
            maxWidth: '100%',
            overflowX: 'auto',
            padding: '1em',
            borderRadius: '12px'
          }}
        >
          <TableContent orgId={activeOrgID} />
        </Paper>
      </ThemeProvider>
    </Container>
  );
};

export default AlertsPanel;
