import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  Text,
  VStack
} from '@chakra-ui/react';

interface WalletConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const WalletConfirmationModal: React.FC<WalletConfirmationModalProps> = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="500px">
        <ModalHeader borderBottom="1px solid" borderColor="gray.200" py={4}>
          Existing Wallet Found
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody py={6}>
          <VStack align="stretch" spacing={6}>
            <Text>
              The wallet address provided already exists in relation to another profile. Do you want to link the
              existing wallet to this profile?
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter borderTop="1px solid" borderColor="gray.200" gap={4}>
          <Button
            bg="button.secondary"
            border="1px solid"
            borderColor="button.secondaryBorder"
            onClick={onClose}
            size="md"
          >
            No
          </Button>
          <Button bg="button.primary" onClick={onConfirm} size="md">
            Yes, Link Wallet
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
