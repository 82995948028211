import { Text, Link, Select, Button, useToast } from '@chakra-ui/react';
import * as Sentry from '@sentry/browser';
import { useState } from 'react';

import { cadenceToFriendlyDisplay } from '@features/wallets/utils';

const CadenceDisplay: React.FC<{
  frequency: string;
  walletId: string;
  orgId: string;
  updateWallet?: any;
  updateWalletIsLoading?: boolean;
}> = ({ frequency, walletId, orgId, updateWallet, updateWalletIsLoading }) => {
  const [cadenceValue, setCadenceValue] = useState(frequency);
  const [isEditingCadence, setIsEditingCadence] = useState(false);
  const toast = useToast();

  const handleCadenceChange = (event): void => {
    setCadenceValue(event.target.value);
  };

  if (!isEditingCadence) {
    return (
      <Text display="flex" alignItems="center" size="sm" mb={0}>
        <Text as="span" fontWeight="medium">
          {cadenceToFriendlyDisplay(frequency)}
        </Text>
        {typeof updateWallet === 'function' && (
          <Link
            size="sm"
            pl={1}
            onClick={() => {
              setCadenceValue(frequency);
              setIsEditingCadence(true);
            }}
          >
            (edit)
          </Link>
        )}
      </Text>
    );
  }

  return (
    <Text display="flex" flexDirection="column" gap={2}>
      <Select size="sm" onChange={handleCadenceChange} maxWidth="20ch" defaultValue={frequency}>
        <option value="N">None</option>
        <option value="D">Daily</option>
        <option value="W">Weekly</option>
        <option value="M">Monthly</option>
      </Select>
      <Text display="flex" gap={2}>
        <Button
          size="xs"
          isDisabled={updateWalletIsLoading}
          bg="red"
          colorScheme="red"
          onClick={() => {
            setIsEditingCadence(false);
          }}
        >
          Cancel
        </Button>
        <Button
          size="xs"
          isLoading={updateWalletIsLoading}
          bg="green.400"
          colorScheme="green"
          onClick={async () => {
            try {
              await updateWallet({
                orgId,
                walletId,
                frequency: cadenceValue
              }).unwrap();
              setIsEditingCadence(false);
            } catch (err) {
              toast({
                status: 'error',
                title: 'Error',
                description: 'Oh no, there was an error!',
                isClosable: true
              });
              Sentry.captureException(err);
            }
          }}
        >
          Save
        </Button>
      </Text>
    </Text>
  );
};

export default CadenceDisplay;
