import { CheckIcon } from '@chakra-ui/icons';
import { Input, Textarea, Box, Flex, IconButton, Text, Tooltip, VStack, Divider } from '@chakra-ui/react';
import { memo, useCallback } from 'react';

import { StyledSelect } from '@components/common/StyledSelect';
import { FormField } from '@components/FormField';

interface WalletFormFieldsProps {
  layout: 'horizontal' | 'vertical';
  networksByLayer: {
    layer1: any[];
    layer2: any[];
    other: any[];
  };
  validationMessage: string;
  messageColor: string;
  isValidationLoading: boolean;
  isBlockchainNetworksLoading: boolean;
  handleFieldChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  handleValidateAddress: (values: any) => Promise<void>;
  values: any;
}

export const WalletFormFields: React.FC<WalletFormFieldsProps> = memo(
  ({
    layout,
    networksByLayer,
    validationMessage,
    messageColor,
    isValidationLoading,
    isBlockchainNetworksLoading,
    handleValidateAddress,
    handleFieldChange,
    values
  }) => {
    const isHorizontal = layout === 'horizontal';
    const canValidate = values.blockchain != null && values.address != null;

    const onValidateAddress = useCallback(() => {
      void handleValidateAddress({
        ...values,
        blockchain: values.blockchain?.value
      });
    }, [handleValidateAddress, values]);

    return (
      <VStack spacing={4} align="stretch" p={4} w="100%">
        <FormField
          name="address"
          label="Wallet Address"
          direction={isHorizontal ? 'horizontal' : 'vertical'}
          validate={['required']}
        >
          {({ input }) => {
            return (
              <Flex gap={2}>
                <Input
                  {...input}
                  placeholder="Enter wallet address"
                  onChange={(e) => {
                    handleFieldChange(e);
                    input.onChange(e);
                  }}
                />
                <Tooltip label={canValidate ? 'Validate' : 'Fill in all fields to enable validation'}>
                  <IconButton
                    aria-label="Validate address"
                    icon={<CheckIcon />}
                    isDisabled={!canValidate}
                    onClick={onValidateAddress}
                    colorScheme={canValidate ? 'blue' : 'gray'}
                    isLoading={isValidationLoading}
                  />
                </Tooltip>
              </Flex>
            );
          }}
        </FormField>

        <FormField
          name="blockchain"
          label="Blockchain"
          direction={isHorizontal ? 'horizontal' : 'vertical'}
          validate={['required']}
        >
          {({ input }) => {
            const options = [
              {
                label: 'Layer 1',
                options: networksByLayer.layer1.map((network) => ({ label: network.name, value: network.value }))
              },
              {
                label: 'Layer 2',
                options: networksByLayer.layer2.map((network) => ({ label: network.name, value: network.value }))
              },
              {
                label: 'Other',
                options: networksByLayer.other.map((network) => ({ label: network.name, value: network.value }))
              }
            ];
            return (
              <StyledSelect
                {...input}
                onChange={(e: any) => {
                  handleFieldChange(e as React.ChangeEvent<HTMLSelectElement>);
                  input.onChange(e);
                }}
                placeholder="Select blockchain"
                options={options}
                isLoading={isBlockchainNetworksLoading}
              />
            );
          }}
        </FormField>

        {validationMessage !== '' && (
          <Flex justify={isHorizontal ? 'flex-end' : 'stretch'} maxW={isHorizontal ? '1000px' : 'full'}>
            {isHorizontal && <Box flex="1" />}
            <Box
              flex={isHorizontal ? '2' : '1'}
              maxW={isHorizontal ? '530px' : 'full'}
              p={3}
              borderRadius="md"
              bg={messageColor === 'red.500' ? 'red.50' : 'green.50'}
              border="1px solid"
              borderColor={messageColor === 'red.500' ? 'red.200' : 'green.200'}
            >
              <Text color={messageColor} fontSize="sm" as="pre" style={{ whiteSpace: 'pre-wrap' }}>
                {validationMessage}
              </Text>
            </Box>
          </Flex>
        )}

        <Divider />

        <FormField
          name="name"
          label="Wallet Name"
          direction={isHorizontal ? 'horizontal' : 'vertical'}
          validate={['required']}
        >
          <Input placeholder="Enter wallet name" />
        </FormField>

        <FormField name="notes" label="Notes" direction={isHorizontal ? 'horizontal' : 'vertical'}>
          <Textarea placeholder="Enter notes" />
        </FormField>
      </VStack>
    );
  }
);

WalletFormFields.displayName = 'WalletFormFields';
