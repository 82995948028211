import { PROFILE_TYPE } from '@models/profileTypes';
import { useCreateProfileMutation, useUpdateProfileMutation } from '@services/canaria.services';

interface UseProfileFormOptions {
  orgId: string;
  onBefore?: (values: any) => Promise<any>;
  onSuccess?: (profileId: string, values: any) => Promise<void> | void;
  onError?: (error: any) => void;
}

export const useProfileForm = ({
  orgId,
  onBefore,
  onSuccess,
  onError
}: UseProfileFormOptions): {
  handleSubmit: (values: any, profileId?: string) => Promise<any>;
  isLoading: boolean;
} => {
  const [createProfile, { isLoading: isCreateLoading }] = useCreateProfileMutation();
  const [updateProfile, { isLoading: isUpdateLoading }] = useUpdateProfileMutation();

  const handleSubmit = async (values: any, profileId?: string): Promise<any> => {
    let processedValues = values;

    if (onBefore != null) {
      processedValues = await onBefore(values);
    }

    if (profileId == null) {
      // Create new profile
      const profileCreated = await createProfile({
        orgId,
        name: processedValues.fullLegalName,
        profileGroup: processedValues.profileGroup,
        resourcetype: processedValues.profileType?.value ?? processedValues.profileType,
        isPrimary: processedValues.isPrimary ?? true
      }).unwrap();

      profileId = profileCreated.id;
    }

    // Update profile with all details
    if ((processedValues.profileType?.value ?? processedValues.profileType) === PROFILE_TYPE.INDIVIDUAL) {
      await updateProfile({
        orgId,
        profileId: profileId ?? '',
        address: processedValues.address,
        citizenship: processedValues.citizenship?.value ?? processedValues.citizenship,
        city: processedValues.city,
        postalCode: processedValues.postalCode,
        countryOfResidence: processedValues.countryOfResidence?.value ?? processedValues.countryOfResidence,
        dateOfBirth: processedValues.dateOfBirth,
        email: processedValues.email,
        fullLegalName: processedValues.fullLegalName,
        gender: processedValues.gender?.value ?? processedValues.gender,
        idIssuer: processedValues.idIssuer?.value ?? processedValues.idIssuer,
        idNumber: processedValues.idNumber,
        idType: processedValues.idType?.value ?? processedValues.idType,
        natureOfEmployment: processedValues.natureOfEmployment?.value ?? processedValues.natureOfEmployment,
        placeOfBirth: processedValues.placeOfBirth?.value ?? processedValues.placeOfBirth,
        state: processedValues.state?.value ?? processedValues.state
      }).unwrap();
    } else {
      await updateProfile({
        orgId,
        profileId: profileId ?? '',
        fullLegalName: processedValues.fullLegalName,
        dbaTradeName: processedValues.dbaTradeName,
        entityFormationType: processedValues.entityFormationType?.value ?? processedValues.entityFormationType,
        placeOfEstablishment: processedValues.placeOfEstablishment?.value ?? processedValues.placeOfEstablishment,
        dateOfEstablishment: processedValues.dateOfEstablishment,
        uniqueIdentificationNumber: processedValues.uniqueIdentificationNumber,
        registeredBusinessAddress: processedValues.registeredBusinessAddress,
        mailingAddress: processedValues.mailingAddress,
        physicalAddress: processedValues.physicalAddress,
        companyWebsite: processedValues.companyWebsite
      }).unwrap();
    }

    await onSuccess?.(profileId ?? '', processedValues);
  };

  return {
    handleSubmit,
    isLoading: isCreateLoading || isUpdateLoading
  };
};
