import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Center,
  VStack,
  Box,
  Divider
} from '@chakra-ui/react';
import { ThemeProvider } from '@mui/material';
import dayjs from 'dayjs';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { useState, useEffect, useMemo, type SetStateAction, type Dispatch } from 'react';
import { useSelector } from 'react-redux';

import { useCustomMaterialTable } from '@features/shared/hooks/useCustomMaterialTable';
import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { useGetProfileAuditLogsQuery } from '@services/canaria.services';
import { defaultMaterialTheme } from '@utils/consts';

interface Attachment {
  id: number;
  name?: string; // Optional
}

interface Screening {
  newHits: Record<string, number>;
}

interface Profiles {
  type: string;
  objects: string[];
  operation: string;
}

interface Inquiry {
  id: number;
  resolution: string;
  notes: string;
}

interface Note {
  id: number;
  content: string;
}

enum AuditLogAction {
  CREATE = 0,
  UPDATE = 1,
  DELETE = 2,
  ACCESS = 3
}

interface AuditLogEntry {
  id: number;
  timestamp: string;
  actor: {
    firstName: string;
    lastName: string;
  } | null;
  action: AuditLogAction;
  changes: Record<string, [string, string] | Attachment | Screening | Profiles | Inquiry | Note>;
  objectRepr: string;
  contentType: string;
}

// because they're logged manually or we already have them in the logentry
const KEYS_TO_IGNORE = ['lastRunScreening', 'updatedBy', 'lastInquiry', 'updatedAt'];

const formatChanges = (log: AuditLogEntry): JSX.Element => {
  const changes = log.changes ?? [];
  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      p={3}
      bg="gray.50"
      boxShadow="sm"
      _hover={{ boxShadow: 'md' }}
      transition="all 0.2s"
    >
      <VStack align="start" spacing={2}>
        {Object.entries(changes).map(([key, value]) => {
          if (key === 'profiles') {
            const profileChange = value as Profiles;
            const profileName = log.contentType === 'wallet' ? log.objectRepr : profileChange.objects[0];
            const action = profileChange.operation === 'add' ? 'added to' : 'removed from';
            return (
              <Text key={key} fontSize="sm">
                <strong>Wallet:</strong> {profileName} was {action} profile
              </Text>
            );
          } else if (key === 'screening') {
            const screeningChange = value as Screening;
            return (
              <Box key={key} bg="blue.50" p={2} borderRadius="md" width="100%">
                <Text fontSize="sm" fontWeight="bold" mb={1}>
                  Watchlist Ongoing Screening Complete
                </Text>
                {Object.entries(screeningChange.newHits).map(([matchType, count]) => (
                  <Text key={matchType} fontSize="sm" ml={2}>
                    • {count} New {matchType} Matches identified
                  </Text>
                ))}
              </Box>
            );
          } else if (['note', 'attachment', 'inquiry'].includes(key)) {
            const item = value as Note | Attachment | Inquiry;
            const itemType = key.charAt(0).toUpperCase() + key.slice(1);
            const actionText =
              log.action === AuditLogAction.CREATE
                ? 'added'
                : log.action === AuditLogAction.UPDATE
                  ? 'updated'
                  : 'deleted';
            return (
              <Text key={key} fontSize="sm">
                <strong>{`${itemType.toLowerCase() === 'inquiry' ? 'An' : 'A'} ${itemType.toLowerCase()} was ${actionText}`}</strong>
                {
                  <>
                    <br />
                    {key === 'note' && 'content' in item && (
                      <span>
                        <strong>{log.action === AuditLogAction.UPDATE ? 'New ' : ''}content:</strong> {item.content}
                      </span>
                    )}
                    {key === 'inquiry' && 'resolution' in item && (
                      <span>
                        <strong>Resolved to:</strong> {item.resolution}
                      </span>
                    )}
                  </>
                }
              </Text>
            );
          } else if (KEYS_TO_IGNORE.includes(key)) {
            return null;
          } else if (log.contentType === 'wallet' && key === 'lastInquiry') {
            return null;
          } else if (log.action === AuditLogAction.CREATE) {
            return null;
          }
          const entity = log.contentType === 'wallet' ? `Wallet ${log.objectRepr}` : 'Profile';
          if (typeof value === 'object' && 'type' in value && value.type === 'm2m') {
            const oldObjects = value.objects[0] ?? [];
            const newObjects = value.objects[1] ?? [];
            return (
              <Text key={key} fontSize="sm">
                <strong>{key}</strong> in {entity} changed from{' '}
                <Box as="span" bg="red.100" px={1} borderRadius="sm" mr={1}>
                  {Array.isArray(oldObjects) ? oldObjects.join(', ') : oldObjects}
                </Box>{' '}
                to{' '}
                <Box as="span" bg="green.100" px={1} borderRadius="sm">
                  {Array.isArray(newObjects) ? newObjects.join(', ') : newObjects}
                </Box>
              </Text>
            );
          }
          return (
            <Text key={key} fontSize="sm">
              <strong>{key}</strong> in {entity} changed {value[0] === '' ? 'to ' : 'from '}
              {value[0] !== '' && (
                <Box as="span" bg="red.100" px={1} borderRadius="sm" mr={1}>
                  {value[0]}
                </Box>
              )}
              {value[0] !== '' && 'to '}
              <Box as="span" bg="green.100" px={1} borderRadius="sm">
                {value[1]}
              </Box>
            </Text>
          );
        })}
      </VStack>
    </Box>
  );
};

interface AuditLogRow {
  id: number;
  timestamp: string;
  actor: string;
  changes: JSX.Element;
}

const AuditLogTable: React.FC<{
  filteredLogs: AuditLogEntry[];
  data: any;
  isLoading: boolean;
  pagination: { pageIndex: number; pageSize: number };
  setPagination: Dispatch<SetStateAction<{ pageIndex: number; pageSize: number }>>;
}> = ({ filteredLogs, data, isLoading, pagination, setPagination }) => {
  const columns = useMemo<Array<MRT_ColumnDef<AuditLogRow>>>(
    () => [
      {
        header: 'Date & Time',
        accessorKey: 'timestamp',
        Cell: ({ cell: { getValue } }) => dayjs(getValue() as string).format('ddd, MMM D, YYYY [at] h:mm A'),
        size: 200
      },
      {
        header: 'Username',
        accessorKey: 'actor',
        size: 150
      },
      {
        header: 'Change Details',
        accessorKey: 'changes',
        Cell: ({ cell: { getValue } }) => getValue() as JSX.Element,
        size: 400
      }
    ],
    []
  );

  const tableData: AuditLogRow[] = useMemo(
    () =>
      filteredLogs.map((log) => ({
        id: log.id,
        timestamp: log.timestamp,
        actor: log.actor !== null ? `${log.actor.firstName} ${log.actor.lastName}` : 'System',
        changes: formatChanges(log)
      })),
    [filteredLogs]
  );

  const { table } = useCustomMaterialTable<AuditLogRow>({
    columns,
    data: tableData,
    rowCount: data?.count ?? 0,
    isLoading,
    isFetching: isLoading,
    pagination,
    setPagination,
    paginationSizes: [10, 25, 50]
  });

  if (isLoading) {
    return (
      <Center height="200px">
        <Spinner size="xl" color="blue.500" thickness="4px" />
      </Center>
    );
  }

  if (filteredLogs.length === 0) {
    return (
      <Center height="200px">
        <Text fontSize="lg" fontWeight="medium" color="gray.500">
          {pagination.pageIndex === 0 ? 'No logs to show' : 'No logs to show on this page'}
        </Text>
      </Center>
    );
  }

  return <MaterialReactTable table={table} />;
};

const AuditLogModal: React.FC<{ isOpen: boolean; onClose: () => void; profileId: string }> = ({
  isOpen,
  onClose,
  profileId
}) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25
  });

  const activeOrgID = useSelector(selectActiveOrgID);
  if (activeOrgID == null) {
    throw new Error('No active org ID found');
  }

  const { data, isLoading, refetch } = useGetProfileAuditLogsQuery(
    {
      orgId: activeOrgID,
      profileId,
      query: {
        page: pagination.pageIndex + 1,
        page_size: pagination.pageSize
      }
    },
    { skip: !isOpen }
  );

  useEffect(() => {
    if (isOpen) {
      void refetch();
    }
  }, [isOpen, refetch]);

  const auditLogs: AuditLogEntry[] = data?.results ?? [];
  const filteredLogs = auditLogs.filter((log) => {
    const currentKeys = Object.keys(log.changes);
    const hasRelevantChanges = currentKeys.some((key) => {
      return ['profiles', 'screening', 'note', 'attachment'].includes(key);
    });

    const toSkip = currentKeys.every((key) => KEYS_TO_IGNORE.includes(key));

    return !(
      toSkip ||
      (!hasRelevantChanges && log.action === 0) ||
      (log.contentType === 'wallet' &&
        currentKeys.length === 2 &&
        currentKeys.includes('lastInquiry') &&
        currentKeys.includes('updatedBy'))
    );
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxWidth="90vw" maxHeight="90vh" display="flex" flexDirection="column">
        <ModalHeader>Profile Audit Log</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto" flex="1" px={0}>
          <Divider mb={4} />
          <ThemeProvider theme={defaultMaterialTheme}>
            <AuditLogTable
              filteredLogs={filteredLogs}
              data={data}
              isLoading={isLoading}
              pagination={pagination}
              setPagination={setPagination}
            />
          </ThemeProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AuditLogModal;
